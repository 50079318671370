import { } from '@ant-design/icons'
import {
    Breadcrumb,
    message,
    Skeleton
} from 'antd'
import { PageHeader } from '@ant-design/pro-components';
import moment from 'moment'
import React, { useEffect, useMemo, useState, useContext } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { STATES, STATES_FULLNAME } from '../../constants'
import {
    getAccountDetails,
    getRolesByUsername,
    getUser,
    isAccountExist,
    addBranchUser,
    create1MIDAccount,
    updateBranchUser,
    getTempUser,
} from '../../services/api'
import {
    getCachedStates,
    removeCachedStates,
    getCachedAddStaff,
    removeCachedAddStaff,
    getUserRole,
    getCompanyId,
} from '../../services/local'
import AuthorizeBranchUser from './authorizeBranchUser'
import { STEPS } from './steps'
import { SessionContext } from "../../App";
import { getState } from '../../util'
import {skipAuth, decodeForSagem, decryptUrlVerify} from '../../components/Utils'

export default function BranchUserFormPage() {

    const { user } = useContext(SessionContext);

    const location = useLocation()
    const history = useHistory()
    const params = useParams()
    const [isEdit, setIsEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [branchUser, setBranchUser] = useState({
        address1: '',
        address2: '',
        address3: '',
        dob: '',
        birthplace: '',
        citizenship: '',
        city: "",
        confirmEmail: "",
        email: '',
        gender: '',
        id: 0,
        mobileNo: '',
        personName: '',
        nricNo: '',
        identificationNo: '',
        phoneNo: '',
        postcode: '',
        race: '',
        religion: '',
        state: '',
        roles: [],
        username: ''
    })
    /**
     * Explanation:
     * useMemo essentially memorises the state of whatever that is in return {} bracket. 
     * [] denotes the dependency for WHEN a new object should be built and memorised. (just like dependency for useEffect)
     * In this case: any update to mobileNo/email/identificationNo should return a new branchUser object and memorise it
     */
    const branchUserMemo = useMemo(() => {
        return { ...branchUser };
    }, [branchUser.mobileNo, branchUser.email, branchUser.identificationNo, branchUser.username]);

    const COMPONENTS = [
        {
            title: 'Authorize BranchUser',
            label: STEPS.AUTHORIZE_BRANCH_USER.label,
            component: <AuthorizeBranchUser
                isEdit={isEdit}
                branchUser={branchUser}
                onChange={_branchUser => {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        branchUser: _branchUser
                    })
                    setBranchUser(_branchUser)
                }}
                setBranchUser={setBranchUser}
            />
        }
    ]

    /**
     * 27/3/2022 daniel.kwok
     * Receiving and transforming data from Sagem
     */
    useEffect(async () => {
        const urlParams = new URLSearchParams(location.search);
        // const cached = JSON.parse(getCachedStates())
        // removeCachedStates()

        // const decodedValues = decodeURIComponent(urlParams.get('values') || '{}')
        // const values = JSON.parse(decodedValues)
        // const decodedForKey = decodeURIComponent(urlParams.get('forKey') || '{}')

        if (urlParams.get('forKey') != null && urlParams.get('forKey') != 'undefined') {

            let values;
            let decodedValues;
            const decodedForKey = decodeURIComponent(urlParams.get('forKey'))
            const cached = JSON.parse(getCachedStates())
            const isSkipAuth = skipAuth(false, null, null, urlParams, user?.nric)
            if (isSkipAuth) {
                values = isSkipAuth
            }
            else {
                if (urlParams.get('tk') != null && urlParams.get('dt') != null) {
                    decodedValues = await decodeForSagem(
                        decodeURIComponent(urlParams.get('tk')), 
                        decodeURIComponent(urlParams.get('dt')),
                        urlParams.get('forKey'),
                        history,
                        STEPS,
                        "ADDSTAFF")
                    if (decodedValues === undefined) {
                        message.error("Please try again.")
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.AUTHORIZE_BRANCH_USER.label,
                            subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC
                        })
                        return
                    }
                }
                else {
                    message.error("Please try again.")
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.AUTHORIZE_BRANCH_USER.label,
                        subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC
                    })
                    return
                }

                removeCachedStates()

                // const decodedValues = decodeURIComponent(urlParams.get('values') || '{}')
                values = JSON.parse(decodedValues)

                const decryptResponse = await decryptUrlVerify(values,
                    urlParams.get('tk'),
                    decodedForKey,
                    getCompanyId(),
                    "SAGEM",
                    user.nric.replace(/[\s*]/g, ""), null, false);
                if(decryptResponse === false) {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.AUTHORIZE_BRANCH_USER.label,
                        subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC
                    })
                    return
                }
            }

            if (values['ThumbSucess'] === 'true') {

                let errorMessages = [];

                if (!values['Name']) {
                    errorMessages.push("Failed to read the Name from MyKad. The Name read as '" + values['Name'] + "'. Please contact the eTukar Admin for more information.");
                }

                if (!values['City']) {
                    errorMessages.push("Failed to read the District from MyKad. The District read as '" + values['City'] + "'. Please contact the eTukar Admin for more information.");
                }

                if (!values['Ic No.'] || !/^[+]?\d+([.]\d+)?[*]?$/.test(values['Ic No.'])) {
                    errorMessages.push("Failed to read the IC No from MyKad. The IC No. read as '" + values['Ic No.'] + "'. Please contact the eTukar Admin for more information.");
                }

                if (errorMessages.length > 0) {
                    for (let errorMessage of errorMessages) {
                        message.error(errorMessage);
                    }
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.AUTHORIZE_BRANCH_USER.label,
                        subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC
                    })
                    return;
                }

                let stateKey, state, formatted, dob, photo, formattedState
                switch (decodedForKey) {
                    case 'branchUser':

                        formattedState = values['State']?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                        state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                        if (!state) {
                            state = getState(values['Ic No.'])
                        }

                        if (user.nric == values['Ic No.']) {

                            message.error('You are not allowed to add yourself as a staff member.')

                            setTimeout(() => {
                                history.push('/usermanagement')
                            }, 1000)

                        } else {

                            if (moment(values['Date of Birth'], 'DD/M/YYYY HH').isValid()) {

                                dob = moment(values['Date of Birth'], 'DD/M/YYYY HH').format('YYYY-MM-DD')

                            } else {

                                let ic = values['Ic No.']

                                let Year = ic.substring(0, 2)
                                let Month = ic.substring(2, 4)
                                let Day = ic.substring(4, 6)

                                let cutoff = (new Date()).getFullYear() - 2000

                                let fulldob = Day + '/' + Month + '/' + (Year > cutoff ? '19' : '20') + Year

                                if (moment(fulldob, 'DD/M/YYYY HH').isValid()) {
                                    dob = moment(fulldob, 'DD/M/YYYY HH').format('YYYY-MM-DD')
                                } else {
                                    dob = ''
                                }
                            }

                            if (dob == 'Invalid date') dob = ''

                            formatted = {
                                address1: values['Address 1'],
                                address2: values['Address 2'],
                                address3: values['Address 3'],
                                dob: dob,
                                birthplace: values['Place of Birth'],
                                citizenship: values['Warganegara'],
                                city: values['City'].replace(/[^a-zA-Z\s]/g, ''),
                                gender: values['Gender'],
                                mobileNo: '',
                                personName: values['Name'].replace(/\*/g, ''),
                                // 27/3/2022 daniel.kwok the model for user is really confusing, especially for nric.
                                // multiple keys for "Ic No." is used here as fall back values
                                nricNo: values['Ic No.'].replace(/[\s*]/g, ""),
                                identificationNo: values['Ic No.'].replace(/[\s*]/g, ""),
                                phoneNo: '',
                                postcode: values['PostCode'],
                                race: values['Race'],
                                religion: values['Religion'],
                                state: state?.code,
                                email: '',
                                photo: values['Photo']
                            }

                            setBranchUser(formatted)

                            history.push(history.location.pathname, {
                                ...cached,
                                branchUser: formatted,
                            })

                        }

                        break
                    case 'ownerUser':

                        let staffInfo = JSON.parse(getCachedAddStaff())
                        removeCachedAddStaff()

                        if (staffInfo.isEdit == true) {

                            updateBranchUser(
                                staffInfo.branchId,
                                staffInfo.companyId,
                                staffInfo.mobileno,
                                staffInfo.email,
                                staffInfo.nric,
                                staffInfo.id,
                                staffInfo.personName,
                                staffInfo.roles
                            )
                                .then((res) => {

                                    if (!res.status) throw (!res.details || res.details.length === 0) ? res.message : res.details.join(', ');

                                    message.success(res.message)
                                    history.push("/usermanagement")

                                })
                                .catch((err) => {

                                    err && message.error(err)

                                    history.push("/usermanagement")

                                })
                                .finally(() => { })

                            return

                        }

                        if (user.nric == staffInfo.newUserNric) {

                            message.error('You are not allowed to add yourself as a staff member.')

                            setTimeout(() => {
                                history.push('/usermanagement')
                            }, 1000)

                        }

                        if (user.nric != values['Ic No.']) {

                            message.error('The authorised user nric does not correspond to the logged-in user nric. ')

                            return
                        } else {

                            getUserRole()
                                .then(loggedInUserRole => {

                                    if (loggedInUserRole != 'OWNER') {

                                        message.error('A level 5 user can only be added by the user with the role of "Owner." ')

                                        return
                                    }


                                    if (user.nric == values['Ic No.'] && loggedInUserRole == 'OWNER') {

                                        isAccountExist(staffInfo.newUserNric)
                                            .then((res) => {

                                                if (res.status === false) {

                                                    return create1MIDAccount(//create 1mid account with dummy
                                                        'dummyAddres1',
                                                        '',
                                                        '',
                                                        staffInfo.localFormattedDob,
                                                        '',
                                                        '',
                                                        'dummyCity',
                                                        staffInfo.newUserEmail,
                                                        staffInfo.newUserEmail,
                                                        staffInfo.newUserGender,
                                                        staffInfo.newUserPhoneNo,
                                                        staffInfo.newUserName,
                                                        staffInfo.newUserNric,
                                                        staffInfo.otherBranchAddUser,
                                                        staffInfo.newUserPhoneNo,
                                                        '99999',
                                                        '',
                                                        '',
                                                        '12')

                                                } else if (res.status === true) {

                                                    return {
                                                        status: true
                                                    }

                                                } else {
                                                    throw res.message
                                                }

                                            })
                                            .then(res => {

                                                if (!res.status) {

                                                    let msg = res.message

                                                    if ('Sorry, an account already exists with email address - ' === res.message) {
                                                        msg += staffInfo.newUserEmail
                                                    }

                                                    throw msg
                                                }

                                                return getAccountDetails(staffInfo.newUserNric)//get 1mid account details

                                            })
                                            .then(res => {

                                                const { nric, name } = res

                                                return addBranchUser(//create new etukar user
                                                    staffInfo.newUserBranchId,
                                                    staffInfo.companyId,
                                                    staffInfo.newUserPhoneNo,
                                                    staffInfo.newUserEmail,
                                                    nric.replace(/[\s*]/g, ""),
                                                    name,
                                                    staffInfo.roles,
                                                    true)

                                            })
                                            .then(res => {
                                                if (!res.status) throw (!res.details || res.details.length === 0) ? res.message : res.details.join(', ');
                                                message.success(res.message);
                                                history.push("/usermanagement");
                                            })
                                            .catch((err) => {

                                                err && message.error(err)

                                                // if (staffInfo.otherBranchAddUser == true) {

                                                //     history.push(history.location.pathname, {
                                                //         ...history.location.state,
                                                //         step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                                //         subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.OTHER_BRANCH_FORM
                                                //     })

                                                // } else {

                                                //     history.push(history.location.pathname, {
                                                //         ...history.location.state,
                                                //         step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                                //         subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.SUMMARY
                                                //     })

                                                // }

                                            })
                                            .finally(() => {

                                            })

                                    }

                                })
                                .catch(err => {
                                    err && message.err(err)
                                })

                        }

                        break
                    default:
                        break
                }

            } else if (values['ThumbSucess'] !== 'true' && decodedForKey != '{}') {
                message.error('Thumbprint is not sucess')

                history.push(history.location.pathname, {
                    ...history.location.state,
                    step: STEPS.AUTHORIZE_BRANCH_USER.label,
                    subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINT_FAILED
                })
                return
            }
        }
    }, [])

    const component = COMPONENTS.find(step => step.label === (history.location.state && history.location.state.step)) || COMPONENTS[0]

    // NOTE: This useEffect is only applicable when clicking "EDIT" button of a User from User Management page
    useEffect(() => {
        /**
         * Detect if it's an edit operation
         */
        if (history?.location?.state?.user?.id) {
            setIsLoading(true)
            console.log("history?.location?.state?.user",history?.location?.state?.user)
            if (history?.location?.state?.user?.tempAuthPersonnel) {
                getTempUser(history?.location?.state?.user?.id)
                    .then(async res => {
                        if (res.status !== '00') throw res.message
    
                        /* const roles = await getRolesByUsername(res.userDetails.nricNo)
                            .then(res => {
                                const roles = res.roles?.map(r => r.name) || []
                                return roles
                            })
                            .catch(err => console.log("Error getting roles by username", err)) */
                        const roles = res.roles?.map(r => r.name) || [];
    
                        const accountDetails = await getAccountDetails(res.userDetails.nricNo).then(res => {
                            return res
                        }).catch(err => err && message.error(err))
    
                        let isEditable = true
                        if (res.dealerRoleLevel <= res.userRoleLevel) {
                            isEditable = false
                        }
    
                        setBranchUser({
                            ...res.userDetails,
                            username: accountDetails.username,
                            identificationNo: res.userDetails?.nricNo,
                            email: res.userDetails?.email,
                            // mobileno: res.userDetails?.contactNumber,
                            mobileno: accountDetails.mobileno,
                            roles: roles,
                            address1: res.address?.line1,
                            address2: res.address?.line2,
                            address3: res.address?.line3,
                            postcode: res.address?.postcode,
                            city: res.address?.city,
                            state: res.address?.state,
                            district: res.address?.district,
                            companyId: res.userDetails?.companyId,
                            branchId: res.userDetails?.branchId,
                            isEditable: isEditable
                        })
    
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.AUTHORIZE_BRANCH_USER.label,
                            subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.FORM
                        })
    
                    })
                    .catch(err => err && message.error(err))
                    .finally(() => setIsLoading(false))
            }
            else {
                getUser(history?.location?.state?.user?.id)
                    .then(async res => {
                        if (res.status !== '00') throw res.message
    
                        const roles = await getRolesByUsername(res.userDetails.nricNo)
                            .then(res => {
                                const roles = res.roles?.map(r => r.name) || []
                                return roles
                            })
                            .catch(err => console.log("Error getting roles by username", err))

                    const accountDetails = await getAccountDetails(res.userDetails.nricNo).then(res => {
                        return res
                    }).catch(err => err && message.error(err))

                    let isEditable = true
                    if (res.dealerRoleLevel <= res.userRoleLevel) {
                        isEditable = false
                    }

                    setBranchUser({
                        ...res.userDetails,
                        username: accountDetails.username,
                        identificationNo: res.userDetails?.nricNo,
                        email: res.userDetails?.email,
                        // mobileno: res.userDetails?.contactNumber,
                        mobileno: accountDetails.mobileno,
                        roles: roles,
                        address1: res.address?.line1,
                        address2: res.address?.line2,
                        address3: res.address?.line3,
                        postcode: res.address?.postcode,
                        city: res.address?.city,
                        state: res.address?.state,
                        district: res.address?.district,
                        companyId: res.userDetails?.companyId,
                        branchId: res.userDetails?.branchId,
                        isEditable: isEditable,
                        accountStatus: accountDetails?.accountStatus,
                    })
    
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.AUTHORIZE_BRANCH_USER.label,
                            subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.FORM
                        })
    
                    })
                    .catch(err => err && message.error(err))
                    .finally(() => setIsLoading(false))
            }
        }
    }, [history?.location?.state?.user])

    useEffect(() => {
        if (history?.location?.state?.user?.id) {
            setIsEdit(true)
        }

    }, [history?.location?.state?.user])

    /**
     * Get email and phone number, when provided identificationNo
     * only triggers when there are update to branchUserMemo
     */
    useEffect(() => {
        if (branchUser.identificationNo) {
            isAccountExist(branchUser.identificationNo)
                .then((res) => {
                    if (res.status == true) {
                        getAccountDetails(branchUser.identificationNo)
                            .then((res) => {
                                setBranchUser({
                                    ...branchUser,
                                    mobileNo: res.mobileno,
                                    email: res.email,
                                    username: res.username,
                                    accountStatus: res.accountStatus,
                                })
                            })
                            .catch((err) => err && message.error(err));
                    }
                })
                .catch((err) => err && message.error(err));
        }
    }, [branchUserMemo]);

    return (
        <div className='page-content'>
            <Breadcrumb separator=">" style={{ marginTop: isEdit ? "60px" : "0px", visibility: isEdit ? "visible" : "hidden" }}>
                <Breadcrumb.Item href='/'>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href='/usermanagement'>User management</Breadcrumb.Item>
                <Breadcrumb.Item >Edit user details</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
                className="site-page-header"
                style={{
                    color: 'white',
                    paddingLeft: "0px"
                }}
                title={isEdit ? 'Edit User' : 'Add Staff'}
            />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >

                {
                    isLoading ? <Skeleton active /> : component?.component
                }

            </div>
        </div>
    )
}