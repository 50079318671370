import React, { useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { Button, Form, Input, message } from "antd";

import CryptoJS from "crypto-js";
import { iv } from "../../../../constants";

import { HideEyeIcon, ShowEyeIcon } from "../../../CustomIcons";

import { verifyUserAuditTrail } from "../../../../services/api";

import "./index.css"; // Import your custom CSS file

export default function VerifyUserAuditTrailComponent({
    verifyBody,
    onSuccessVerify,
}) {
    const [verifyForm] = useForm();

    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState("");

    const { nric, action, currentValue, newValue, remarks } = verifyBody;

    const onSubmit = async () => {
        if (password.trim() === "") {
            setError("Password cannot be empty");
            return;
        }
        setError("");
        let passwordCiphertext = CryptoJS.AES.encrypt(password, iv).toString();

        setIsLoading(true);

        try {
            const verifyResponse = await verifyUserAuditTrail({
                nric,
                password: passwordCiphertext,
                action,
                currentValue,
                newValue,
                remarks,
            });

            if (verifyResponse && verifyResponse?.status) {
                message.success(verifyResponse.message);

                //Trigger login on success verify
                onSuccessVerify();
            } else {
                message.error(`${verifyResponse.message} Try again`);
            }
        } catch (error) {
            message.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <h2
                style={{
                    fontWeight: "bold",
                    textAlign: "center",
                }}
            >
                To Proceed, Please Verify Its You
            </h2>
            <Form
                layout="vertical"
                style={{ padding: "0px 20px 0px 20px" }}
                width={200}
                requiredMark={false}
                form={verifyForm}
                className="custom-verify-user-form"
            >
                {/* Hidden dummy fields for hiding auto populate*/}
                <div style={{ display: "none" }}>
                    <input type="text" autoComplete="username" />
                    <input type="password" autoComplete="current-password" />
                </div>

                <p style={{ fontStyle: "italic", marginBottom: "5px" }}>
                    Mykad number
                </p>
                <Input
                    disabled={true}
                    type={"text"}
                    className="form-input-field"
                    defaultValue={nric}
                    style={{ marginBottom: "10px" }}
                />

                <Form.Item
                    label="Password"
                    placeholder={"Password"}
                    name="password"
                    style={{
                        font: "italic normal normal 16px/22px Open Sans",
                        color: "#333333",
                    }}
                >
                    <Input.Password
                        iconRender={(visible) =>
                            visible ? ShowEyeIcon() : HideEyeIcon()
                        }
                        value={password}
                        placeholder={"Enter password"}
                        onChange={(e) => setPassword(e.target.value)}
                        onPressEnter={onSubmit}
                        style={{
                            border: "1px solid #2B61C4",
                            borderRadius: "4px",
                            font: "normal normal 600 15px/20px Open Sans",
                        }}
                    />
                </Form.Item>
                {error && <p style={{ color: "red" }}>{error}</p>}
                <Form.Item>
                    <Button
                        className="add-new-user-button custom-disabled-button"
                        type="primary"
                        style={{ width: "100%" }}
                        htmlType="submit"
                        loading={isLoading}
                        disabled={false}
                        onClick={onSubmit}
                    >
                        Verify
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
