import React, { useEffect, useState, useContext } from 'react'
import { SessionContext } from '../../App'
import {
    Form,
    Input,
    Button,
    Card,
    Row,
    Col,
    Select,
    DatePicker,
    Checkbox,
    message,
    Space,
    Image,
    Modal,
    Typography,
    Tooltip,
    Table
} from 'antd'
import './index.less'
import { useHistory, useParams } from 'react-router-dom'
import {
    SaveOutlined,
} from '@ant-design/icons'
import arrow_back from '../../assets/arrow_back.svg'
import propTypes from 'prop-types'
import moment from 'moment'
import {
    STEPS
} from './steps'
import { TRANSACTION_RESPONSE_CODES, SEARCH_QUERY_TOOLTIP } from '../../constants'
import {
    getOutVehicleTypes,
    saveOutSeller,
    isAccountExist,
    create1MIDAccount,
    outUpdateVehicleDetails,
    getDistrictList,
    getVehicleDetailsVix
} from '../../services/api'
import { links } from '../../config'
import { disabledYear, breakdownIc } from '../../util'

function VehicleInformation(props) {

    const { user, company, branch } = useContext(SessionContext)
    const history = useHistory()
    const [vehicleTypes, setVehicleTypes] = useState([])
    const [form] = Form.useForm()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isOnsaveModalVisible, setIsOnsaveModalVisible] = useState(false);
    const [saveSellerLoading, setSaveSellerLoading] = useState(false)
    const [vehicleLoading, setVehicleLoading] = useState(false)
    const params = useParams()

    const [sellerDistricts, setSellerDistricts] = useState([])
    const [sellerCorrespondenceDistricts, setSellerCorrespondenceDistricts] = useState([])
    const [buyerDistricts, setBuyerDistricts] = useState([])
    const [buyerCorrespondenceDistricts, setBuyerCorrespondenceDistricts] = useState([])
    const [vehicleDetailsFound, setVehicleDetailsFound] = useState(false)
	const [searchTerm, setSearchTerm] = useState("");

    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {

        /* if (props?.seller?.state) {

            getDistrictList(props?.seller?.state)
                .then(res => {
                    setSellerDistricts(res)
                })
        }

        if (props?.seller?.correspondentState) {

            getDistrictList(props?.seller?.correspondentState)
                .then(res => {
                    setSellerCorrespondenceDistricts(res)
                })
        } */

        if (props?.buyer?.state) {

            getDistrictList(props?.buyer?.state)
                .then(res => {
                    setBuyerDistricts(res)
                })
        }

        if (props?.buyer?.correspondentState) {

            getDistrictList(props?.buyer?.correspondentState)
                .then(res => {
                    setBuyerCorrespondenceDistricts(res)
                })
        }

    }, [props])

    const saveSeller = async (vehicle) => {

        try {

            let seller = {}
            if (props.transactionType !== 2 && !props.transactionType !== 3) {

               /*  if (!sellerDistricts || !props?.seller?.district) {
                    message.error(!sellerDistricts ? 'Seller district list is empty.' : 'Seller district is missing.');
                    return;
                }

                const sellerDistrictCode = sellerDistricts.find(district =>
                    district?.districtName.toUpperCase() === props?.seller?.district?.toUpperCase()
                )?.districtCode; */

                // if (!sellerCorrespondenceDistricts || !props?.seller?.correspondentDistrict) {
                //     message.error(!sellerCorrespondenceDistricts ? 'Seller correspondent district list is empty.' : 'Seller correspondent district is missing.');
                //     return;
                // }

                /* const sellerCorrespondentDistrictCode = sellerCorrespondenceDistricts.find(district =>
                    district?.districtName.toUpperCase() === props?.seller?.correspondentDistrict?.toUpperCase()
                )?.districtCode; */

                seller = {
                    address1: props?.seller?.address1,
                    address2: props?.seller?.address2,
                    address3: props?.seller?.address3,
                    correspondentAddress1: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentAddress1 : props?.seller?.address1,
                    correspondentAddress2: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentAddress2 : props?.seller?.address2,
                    correspondentAddress3: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentAddress3 : props?.seller?.address3,
                    correspondentDistrict: '0000',
                    // correspondentDistrict: props?.seller?.isAddressDifferent === true ? sellerCorrespondentDistrictCode : sellerDistrictCode,
                    correspondentEmail: props?.seller?.correspondentEmail,
                    correspondentMobileNo: props?.seller?.correspondentMobileNo,
                    correspondentPostcode: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentPostcode : props?.seller?.postcode,
                    correspondentState: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentState : props?.seller?.state,
                    dateOfBirth: props?.seller?.dateOfBirth,
                    city: props?.seller?.city,
                    district: '0000',
                    // district: sellerDistrictCode,
                    email: props?.seller?.email,
                    gender: props?.seller?.gender,
                    identificationNo: props?.seller?.identificationNo,
                    name: props?.seller?.name,
                    phoneNo: props?.seller?.phoneNo,
                    mobileNo: props?.seller?.mobileNo,
                    postcode: props?.seller?.postcode,
                    state: props?.seller?.state,
                    sellerType: props?.seller?.sellerType,
                    citizenship: props?.seller?.citizenship?.replace(/\0.*$/, "").toUpperCase()
                }

                let isAccountExistRes = await isAccountExist(props.seller?.identificationNo)

                if (isAccountExistRes.status === false) {

                    const { formattedDob, isMale } = breakdownIc(seller?.identificationNo)
                    let localFormattedDob = moment(new Date('' + formattedDob)).format("DD-MM-YYYY")

                    let create1MIDAccountRes = await create1MIDAccount(
                        seller?.correspondentAddress1,
                        seller?.correspondentAddress2,
                        seller?.correspondentAddress3,
                        localFormattedDob,
                        '',
                        '',
                        // seller?.correspondentDistrict,
                        seller?.city,
                        seller?.correspondentEmail,
                        seller?.correspondentEmail,
                        isMale ? "MALE" : "FEMALE",
                        seller?.correspondentMobileNo,
                        seller?.name,
                        seller?.identificationNo,
                        false,
                        seller?.correspondentMobileNo,
                        seller?.correspondentPostcode,
                        '',
                        '',
                        seller?.correspondentState
                    )

                    if (!create1MIDAccountRes.status) {

                        let msg = create1MIDAccountRes.message

                        if ('Sorry, an account already exists with email address - ' === create1MIDAccountRes.message) {
                            msg += seller?.correspondentEmail
                        }

                        throw msg
                    }

                }

            }

            let saveOutSellerRes = await saveOutSeller(
                branch.id,
                user.username,
                company.coRegNo,
                false,
                seller,
                props.transactionType,
                vehicle
            )

            if (saveOutSellerRes.saveSellerStatus !== '00') throw saveOutSellerRes.saveSellerMSG ?? saveOutSellerRes.message

            if (props.transactionType === 2 || props.transactionType === 3) {

                message.success('Vehicle information saved.')
            } else {

                message.success('Owner information saved.')
            }

            return saveOutSellerRes.transferId

        } catch (error) {
            error && message.error(error?.toString())
        }
    }

    const onSavePressed = async () => {

        setSaveSellerLoading(true)

        const vehicleInfo = {
            // 'carMake': form.getFieldValue('carMake').toUpperCase(),
            // 'carModel': form.getFieldValue('carModel').toUpperCase(),
            'chassisNo': form.getFieldValue('chassisNo').toUpperCase(),
            'engineNo': form.getFieldValue('engineNo').toUpperCase(),
            // 'tacNumber': form.getFieldValue('tacNumber'),
            // 'manufactureYear': form.getFieldValue('manufactureYear')?.format("YYYY"),
            'vehicleRegistrationNo': form.getFieldValue('vehicleRegistrationNo').replaceAll(' ', '').toUpperCase(),
            // 'vehicleType': form.getFieldValue('vehicleType'),
            'ownerIdCat': props?.seller?.sellerType ?? ''
        }

        const vehicle = {
            ...props.vehicle,
            ...vehicleInfo
        }

        let transferId = await saveSeller(vehicle)

        if (!transferId) return

        setSaveSellerLoading(false)
        setIsOnsaveModalVisible(false);

        message.loading('Redirect to homepage...')

        setTimeout(() => {
            window.location.pathname = '/'
        }, 1 * 1000);

    }

    useEffect(() => {

        form.setFieldsValue({
            // carMake: props?.vehicle?.carMake,
            // carModel: props?.vehicle?.carModel,
            chassisNo: props?.vehicle?.chassisNo,
            engineNo: props?.vehicle?.engineNo,
            // manufactureYear: moment(props.vehicle?.manufactureYear || undefined),
            vehicleRegistrationNo: props?.vehicle?.vehicleRegistrationNo,
            // vehicleType: props?.vehicle?.vehicleType,
            // tacNumber: props?.vehicle?.tacNumber
        })

    }, [props])

    useEffect(() => {

        form.setFieldsValue({
            vehicleRegistrationNo: props.vehicle?.vehicleRegistrationNo
        })

    }, [props.vehicle])

    const handleCheckRegistrationNo = () => {

        let vehicleNumber = form.getFieldValue('vehicleRegistrationNo')
        setSearchTerm(vehicleNumber)
        if (!vehicleNumber) {
            message.warning('Vehicle Registration Number cannot be empty.')
            return
        }

        setVehicleLoading(true)

        getVehicleDetailsVix(vehicleNumber, props?.seller?.identificationNo ?? "0")
            .then(res => {

                // if (!res.vehicleRegNo && !res.make && !res.model && !res.chassisNo && !res.engineNo && !res.manufacturerYear) {
                if (!res.vehicleRegNo && !res.chassisNo && !res.engineNo ) {

                    setVehicleDetailsFound(false)

                    form.setFieldsValue({
                        vehicleRegistrationNo: vehicleNumber,
                        // carMake: '',
                        // carModel: '',
                        chassisNo: '',
                        engineNo: '',
                        // manufactureYear: '',
                        // vehicleType: ''
                    })

                    const newMessage = (
                        <div>
                            No vehicle details found for {vehicleNumber}.
                            <br />
                            Please fill in the details manually for further checking.
                        </div>
                    );
                    message.info(newMessage);
                    return;
                }

                let vehicle = {
                    // carMake: res.make,
                    // carModel: res.model,
                    chassisNo: res.chassisNo,
                    engineNo: res.engineNo,
                    // manufactureYear: res.manufacturerYear,
                    vehicleRegistrationNo: res.vehicleRegNo,
                    // vehicleType: res.vehicleType,
                }

                form.setFieldsValue({
                    ...vehicle,
                    // manufactureYear: moment(vehicle?.manufactureYear || undefined)
                })

                setVehicleDetailsFound(true)

            })
            .catch((err) => {
                err && message.error(err)
                setVehicleDetailsFound(false)
                form.setFieldsValue({
                    vehicleRegistrationNo: vehicleNumber,
                    // carMake: '',
                    // carModel: '',
                    chassisNo: '',
                    engineNo: '',
                    // manufactureYear: '',
                    // vehicleType: ''
                })
            })
            .finally(() => { setVehicleLoading(false) })
    }

    const COMPONENT_STEPS = {
        [STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO]: {
            component: (
                <div>
                    <Modal className='i2i-buyer-info-modal' open={isModalVisible} onCancel={() => { setIsModalVisible(false) }} footer={null} width={330}>
                        <Space direction='vertical' style={{ textAlign: 'center', marginTop: 24 }}>
                            <h1 style={{ font: "normal normal 600 24px Raleway" }}>Proceed to get buyer's information?</h1>
                            <Button
                                size='large'
                                style={{

                                    background: " #2B61C4 0% 0% no-repeat padding-box",
                                    boxShadow: "0px 4px 0px #1E4489",
                                    borderRadius: "8px",
                                    color: 'white',
                                    borderColor: 'transparent'
                                }}
                                type='primary'
                                disabled={isDisabled}
                                onClick={async () => {
                                    setIsDisabled(true);
                                    const vehicleInfo = {
                                        // 'carMake': form.getFieldValue('carMake').toUpperCase(),
                                        // 'carModel': form.getFieldValue('carModel').toUpperCase(),
                                        'chassisNo': form.getFieldValue('chassisNo').toUpperCase(),
                                        'engineNo': form.getFieldValue('engineNo').toUpperCase(),
                                        // 'tacNumber': form.getFieldValue('tacNumber'),
                                        // 'manufactureYear': form.getFieldValue('manufactureYear')?.format("YYYY"),
                                        'vehicleRegistrationNo': form.getFieldValue('vehicleRegistrationNo').replaceAll(' ', '').toUpperCase(),
                                        // 'vehicleType': form.getFieldValue('vehicleType')
                                    }

                                    const vehicle = {
                                        ...props.vehicle,
                                        ...vehicleInfo
                                    }

                                    let transferId = await saveSeller(vehicle)

                                    if (!transferId) {
                                        setIsDisabled(false);
                                        return
                                    }

                                    if (props.transactionType === 3 || props.transactionType === 4) {
                                        setIsDisabled(false)
                                        history.push(history.location.pathname, {
                                            ...history.location.state,
                                            step: STEPS.AUTHORIZE_BUYER.label,
                                            subStep: STEPS.AUTHORIZE_BUYER.subSteps.B_ROC_SEARCH_PAGE,
                                            transferId: transferId
                                        })

                                    } else {
                                        setIsDisabled(false)
                                        history.push(history.location.pathname, {
                                            ...history.location.state,
                                            step: STEPS.AUTHORIZE_BUYER.label,
                                            subStep: STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC,
                                            transferId: transferId
                                        })

                                    }



                                }}
                            >
                                Get buyer's info
                            </Button>
                            <Button type='link' onClick={() => { setIsModalVisible(false) }} style={{ font: 'normal normal 600 14px Open Sans', color: "#2B61C4", paddingTop: 16 }}>
                                CANCEL
                            </Button>
                        </Space>
                    </Modal>
                    <Modal className='i2i-buyer-info-modal' open={isOnsaveModalVisible} onCancel={() => { setIsOnsaveModalVisible(false) }} footer={null} width={330}>
                        <Space direction='vertical' style={{ textAlign: 'center', marginTop: 24 }}>
                            <h1 style={{ font: "normal normal 600 24px Raleway" }}>Save the current information?</h1>
                            <p style={{ font: "normal normal 400 18px Raleway" }}>The current information will be save and you will be redirect to homepage when it's completed.</p>
                            <Button
                                size='large'
                                style={{

                                    background: " #2B61C4 0% 0% no-repeat padding-box",
                                    boxShadow: "0px 4px 0px #1E4489",
                                    borderRadius: "8px",
                                    color: 'white',
                                    borderColor: 'transparent'
                                }}
                                type='primary'
                                onClick={() => onSavePressed()}
                                loading={saveSellerLoading}
                            >
                                Save and back to homepage
                            </Button>
                            <Button type='link' onClick={() => { setIsOnsaveModalVisible(false) }} style={{ font: 'normal normal 600 14px Open Sans', color: "#2B61C4", paddingTop: 16 }}>
                                CANCEL
                            </Button>
                        </Space>
                    </Modal>


                    <Form
                        layout={'vertical'}
                        style={{
                            width: '100%',

                        }}
                        form={form}
                        onFinish={values => {

                            // values.manufactureYear = values.manufactureYear?.format("YYYY")
                            values.vehicleRegistrationNo = values.vehicleRegistrationNo.replaceAll(' ', '').toUpperCase()
                            // values.carMake = values.carMake.trim().toUpperCase()
                            // values.carModel = values.carModel.trim().toUpperCase()
                            values.chassisNo = values.chassisNo.trim().toUpperCase()
                            values.engineNo = values.engineNo.trim().toUpperCase()

                            props.onChange({
                                ...props.vehicle,
                                ...values,
                                ownerIdCat: props?.seller?.sellerType ?? ''
                            })
                            
                            setIsModalVisible(true)

                        }}
                        initialValues={{
                            ...props.vehicle,
                            // manufactureYear: moment(props.vehicle?.manufactureYear || undefined)
                        }}
                    >
                        <div style={{
                            padding: '24px',
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "4px",
                            width: '100%',

                        }}>
                            <h2 style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 24 }}>Vehicle Information</h2>
                            <Row gutter={16} style={{ width: '100%', }}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Vehicle Registration Number</p>}
                                        name='vehicleRegistrationNo'
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp('^[a-zA-Z0-9]{2,20}$'),
                                                // pattern: new RegExp('^[a-zA-Z0-9\\-]{2,20}$'),
                                                message: (
                                                    <ol>
                                                        <li>{'Must be between 2 - 20 characters'}</li>
                                                        <li>{'Allow for (a-z), (A-Z), (0-9)'}</li>
                                                    </ol>
                                                ),
                                            },
                                        ]}
                                    >
                                        <Tooltip title={SEARCH_QUERY_TOOLTIP} placement="bottom">
                                            <Input
                                                placeholder='Enter vehicle registration number'
                                                className='textbox-form-style'
                                                style={{ width: '100%' }}
                                                value={searchTerm}
                                                onChange={(e) => {
                                                    setSearchTerm(e.target.value);
                                                    // props.onChange({
                                                    //     ...props.vehicle,
                                                    //     vehicleRegistrationNo: e.target.value.toUpperCase()
                                                    // })
                                                    const newValue = e.target.value.toUpperCase().trim().replace(/\s/g, "");
                                                    form.setFieldsValue({
                                                        vehicleRegistrationNo: newValue
                                                    });

                                                    if (newValue === '') {
                                                        setVehicleDetailsFound(false)
                                                    }
                                                }}

                                            />
                                        </Tooltip>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <Button
                                        loading={vehicleLoading}
                                        type="primary"
                                        style={{ width: '100%' }}
                                        onClick={handleCheckRegistrationNo}
                                    >
                                        Check Registration Number
                                    </Button>
                                </Col>
                            </Row>

                            {vehicleDetailsFound && (
                                <Typography.Text style={{ color: 'red', fontStyle: 'italic', textAlign: 'left', display: 'block' }}>
                                    The engine and chassis numbers generated by the system may not always align with the records maintained by the JPJ system. Alternatively, adjustments can be made to comply with the GRANT / VOC. If the system displays an error stating 'No Vehicle Was Found' or 'Incorrect Engine / Chassis Number', appropriate modifications can be applied accordingly.
                                </Typography.Text>
                            )}


                            <hr style={{ width: '100%', borderTop: '1px solid #D8D8D8' }} />

                            {/* <Row gutter={16} style={{ width: '100%', }}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Make</p>}
                                        name='carMake'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Make is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            className='textbox-form-style'
                                            style={{ width: '100%', }}
                                            placeholder='Enter vehicle make'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Model</p>}
                                        name='carModel'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Model is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            className='textbox-form-style'
                                            style={{ width: '100%', }}
                                            placeholder='Enter vehicle model'
                                        />
                                    </Form.Item>

                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Manufacture Year</p>}
                                        name='manufactureYear'
                                        rules={[
                                            {
                                                required: true,
                                                message: `Manufacture Year is required`
                                            },
                                        ]}
                                    >
                                        <DatePicker.YearPicker
                                            disabledDate={disabledYear}
                                            className='textbox-form-style'
                                            style={{ width: '100%', height: 40 }}
                                            placeholder='Select year'
                                        />
                                    </Form.Item>

                                </Col>
                            </Row> */}


                            <Row gutter={16} style={{ width: '100%', }}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Chassis Number</p>}
                                        name='chassisNo'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Chassis Number is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            className='textbox-form-style'
                                            style={{ width: '100%', }}
                                            placeholder='Enter chassis number'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Engine Number</p>}
                                        name='engineNo'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Engine Number is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            className='textbox-form-style'
                                            style={{ width: '100%', }}
                                            placeholder='Enter engine number'
                                        />
                                    </Form.Item>

                                </Col>
                                {/* <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Vehicle Type</p>}
                                        name='vehicleType'
                                        rules={[
                                            {
                                                required: true,
                                                message: `Vehicle Type is required`
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select a vehicle type"
                                            optionFilterProp="children"
                                            className='custom-select'
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{ width: '100%', height: 40 }}
                                            loading={vehicleLoading}
                                        >
                                            {
                                                vehicleTypes.map(vt => {
                                                    return <Select.Option value={vt.id} key={vt.id}>
                                                        {vt.name}
                                                    </Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>

                                </Col> */}
                            </Row>
                            {/* {
                                props.transactionType == 2 || props.transactionType == 3 ?
                                    <Col className="gutter-row" span={8}>
                                        <Form.Item
                                            label={<p style={styles.label}>Tac Number</p>}
                                            name='tacNumber'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Tac Number is required`
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Enter tac number'
                                                className='textbox-form-style'
                                                style={{ width: '100%' }}

                                            />
                                        </Form.Item>
                                    </Col>
                                    : null
                            } */}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                marginBlock: 24
                            }}
                        >
                            <Form.Item
                                name='tnc'
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value
                                                ? Promise.resolve()
                                                : Promise.reject(),
                                        message: (
                                            <span>To proceed, you need to agree with the terms & conditions</span>
                                        )
                                    }
                                ]}
                            >
                                <Checkbox>
                                    <p style={{ textAlign: 'left' }}>
                                        I hereby agree to transfer the ownership of this vehicle and shall take full responsibility on this transfer upon the agreed
                                        <a
                                            target='_blank'
                                            rel="noreferrer"
                                            href={links.termsAndCondition}
                                        >
                                            &nbsp; Terms & Conditions.
                                        </a>
                                    </p>
                                </Checkbox>
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <Row justify='space-between'>
                                <Col>
                                    <Button
                                        className='back-button buttons'
                                        shape='round'
                                        onClick={() => {

                                            props.onChange({
                                                ...props.vehicle,
                                                // carMake: '',
                                                // carModel: '',
                                                chassisNo: '',
                                                engineNo: '',
                                                // manufactureYear: '',
                                                vehicleRegistrationNo: '',
                                                // vehicleType: '',
                                                ownerIdCat: ''
                                            })

                                            if (props.transactionType == 2 || props.transactionType == 3) {
                                                history.push(history.location.pathname, {
                                                    ...history.location.state,
                                                    step: STEPS.TRANSACTION_TYPE.label,
                                                    subStep: STEPS.AUTHORIZE_SELLER.subSteps.TRANSACTION_TYPE
                                                })
                                            } else {
                                                history.push(history.location.pathname, {
                                                    ...history.location.state,
                                                    step: STEPS.AUTHORIZE_SELLER.label,
                                                    subStep: STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY
                                                })
                                            }

                                        }}
                                    >
                                        <Image src={arrow_back} preview={false} />
                                        Back
                                    </Button>
                                </Col>
                                <Space style={{ alignItems: 'self-start' }}>
                                    <Button

                                        className='back-button buttons'
                                        icon={<SaveOutlined />}
                                        shape='round'
                                        onClick={() => {
                                            setSearchTerm(form.getFieldValue('vehicleRegistrationNo'))
                                            form
                                                .validateFields()
                                                .then(() => {
                                                    setIsOnsaveModalVisible(true);
                                                });
                                        }}
                                    >

                                        Save
                                    </Button>
                                    <Button
                                        size='large'
                                        style={{
                                            width: 200,
                                            background: " #2B61C4 0% 0% no-repeat padding-box",
                                            boxShadow: "0px 4px 0px #1E4489",
                                            borderRadius: "8px",
                                            color: 'white',
                                            borderColor: 'transparent'
                                        }}
                                        htmlType='submit'
                                        type='primary'
                                        onClick={() => {
                                            setSearchTerm(form.getFieldValue('vehicleRegistrationNo'))
                                        }}
                                    >
                                        Continue
                                    </Button>

                                </Space>
                            </Row>


                        </Form.Item>
                    </Form>
                </div>
            )
        },
        [STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO]: {
            component: (
                <div>

                    <Form
                        layout={'vertical'}
                        style={{
                            width: '100%',

                        }}
                        form={form}
                        onFinish={values => {

                            setVehicleLoading(true)

                            outUpdateVehicleDetails(
                                // values.carMake.trim().toUpperCase(),
                                // values.carModel.trim().toUpperCase(),
                                values.chassisNo.trim().toUpperCase(),
                                company.coRegNo,
                                values.engineNo.trim().toUpperCase(),
                                // values.manufactureYear?.format("YYYY"),
                                // values.tacNumber ? values.tacNumber.trim() : "",
                                params.transferId ?? history?.location?.state?.transferId,
                                // values.vehicleType
                            ).then(res => {

                                if (res.updateStatus != '00') {

                                    if (res.updateMSG) throw res.updateMSG

                                    throw res.message
                                }

                                message.success(res.updateMSG)

                                if (history?.location?.state?.fromAdminPage == true)
                                    history.push('/adminSearchVehicleInfo')
                                else
                                    history.push('/reporting/transferreport/i2i')

                            }).catch(err => { err && message.error(err) }).finally(() => setVehicleLoading(false))

                        }}
                        initialValues={{
                            ...props?.vehicle,
                            manufactureYear: moment(props.vehicle?.manufactureYear || undefined)
                        }}
                    >
                        <div style={{
                            padding: '24px',
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "4px",
                            width: '100%',

                        }}>
                            <h2 style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 24 }}>Vehicle Information</h2>
                            <Row gutter={16} style={{ width: '100%', }}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Vehicle Registration Number</p>}
                                        name='vehicleRegistrationNo'
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp('^[a-zA-Z0-9]{2,20}$'),
                                                message: (
                                                    <ol>
                                                        <li>{'Must be between 2 - 20 characters'}</li>
                                                        <li>{'Allow for (a-z), (A-Z), (0-9)'}</li>
                                                    </ol>
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled
                                            placeholder='Enter vehicle registration number'
                                            className='textbox-form-style'
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                props.onChange({
                                                    vehicleRegistrationNo: e.target.value.toUpperCase()
                                                })
                                            }}

                                        />
                                    </Form.Item>
                                </Col>

                            </Row>
                            {/* <Row gutter={16} style={{ width: '100%', }}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Make</p>}
                                        name='carMake'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Make is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={!props?.vehicle?.carMake || history?.location?.state?.fromAdminPage == true ? false : true}
                                            className='textbox-form-style'
                                            style={{ width: '100%', }}
                                            placeholder='Enter vehicle make'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Model</p>}
                                        name='carModel'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Model is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={!props?.vehicle?.carModel || history?.location?.state?.fromAdminPage == true ? false : true}
                                            className='textbox-form-style'
                                            style={{ width: '100%', }}
                                            placeholder='Enter vehicle model'
                                        />
                                    </Form.Item>

                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Manufacture Year</p>}
                                        name='manufactureYear'
                                        rules={[
                                            {
                                                required: true,
                                                message: `Manufacture Year is required`
                                            },
                                        ]}
                                    >
                                        <DatePicker.YearPicker
                                            disabled={!props?.vehicle?.manufactureYear || history?.location?.state?.fromAdminPage == true ? false : true}
                                            disabledDate={disabledYear}
                                            className='textbox-form-style'
                                            style={{ width: '100%', height: 40 }}
                                            placeholder='Select year'
                                        />
                                    </Form.Item>

                                </Col>
                            </Row> */}


                            <Row gutter={16} style={{ width: '100%', }}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Chassis Number</p>}
                                        name='chassisNo'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Chassis Number is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            className='textbox-form-style'
                                            style={{ width: '100%', }}
                                            placeholder='Enter chassis number'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Engine Number</p>}
                                        name='engineNo'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Engine Number is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            className='textbox-form-style'
                                            style={{ width: '100%', }}
                                            placeholder='Enter engine number'
                                        />
                                    </Form.Item>

                                </Col>
                               {/*  <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Vehicle Type</p>}
                                        name='vehicleType'
                                        rules={[
                                            {
                                                required: true,
                                                message: `Vehicle Type is required`
                                            },
                                        ]}
                                    >
                                        <Select
                                            disabled={history?.location?.state?.fromAdminPage == true ? false : true}
                                            showSearch
                                            placeholder="Select a vehicle type"
                                            optionFilterProp="children"
                                            className='custom-select'
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{ width: '100%', height: 40 }}
                                            loading={vehicleLoading}
                                        >
                                            {
                                                vehicleTypes.map(vt => {
                                                    return <Select.Option value={vt.id} key={vt.id}>
                                                        {vt.name}
                                                    </Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>

                                </Col> */}
                            </Row>
                            {/* {
                                props.transactionType == 2 || props.transactionType == 3 ?
                                    <Col className="gutter-row" span={8}>
                                        <Form.Item
                                            label={<p style={styles.label}>Tac Number</p>}
                                            name='tacNumber'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Tac Number is required`
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Enter tac number'
                                                className='textbox-form-style'
                                                style={{ width: '100%' }}

                                            />
                                        </Form.Item>
                                    </Col>
                                    : null
                            } */}
                        </div>

                        <Form.Item>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBlock: 24
                                }}
                            >
                                <Button
                                    className='back-button buttons'
                                    shape='round'
                                    onClick={() => {
                                        if (history?.location?.state?.fromAdminPage == true) history.push('/adminSearchVehicleInfo')
                                        else
                                            history.push('/reporting/transferreport/i2i')
                                    }}
                                >
                                    <Image src={arrow_back} preview={false} />
                                    Back
                                </Button>

                                <Button
                                    size='large'
                                    style={{
                                        width: 200,
                                        background: " #2B61C4 0% 0% no-repeat padding-box",
                                        boxShadow: "0px 4px 0px #1E4489",
                                        borderRadius: "8px",
                                        color: 'white',
                                        borderColor: 'transparent'
                                    }}
                                    htmlType='submit'
                                    type='primary'
                                >
                                    Update
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            )
        }
    }

    const [currentSubStep, setCurrentSubStep] = useState(STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO)
    const currentStep = COMPONENT_STEPS[currentSubStep]

    useEffect(() => {
        // if (!history.location.state) return null
        // if (!history.location.state.subStep) return null
        setCurrentSubStep(history.location.state?.subStep)
    }, [history.location.state])

    useEffect(() => {
        setVehicleLoading(true)
        getOutVehicleTypes()
            .then(res => {
                setVehicleLoading(false)
                if (!Array.isArray(res)) throw res.message || `Unable to get vehicle types`
                setVehicleTypes(res)
            })
            .catch(err => err && message.error(err))
    }, [])

    return (

        <Card
            style={{
                height: '50%',
                width: '100%',
                background: 'transparent',
                border: 0
            }}
        >
            {
                currentStep && currentStep.component
            }
            {
                !params.transferId || !history?.location?.state?.transferId
                && !props.vehicle.vehicleRegistrationNo
                && currentSubStep != STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO
                && currentSubStep != STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO
                && currentSubStep
                &&
                <Card
                    style={{
                        height: '50%',
                        width: '100%',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: 4
                    }}
                >
                    <h1>Unknown transfer</h1>
                    <a
                        onClick={() => history.push('/reporting/transferreport/i2i')}
                    >Go back pending list</a>
                </Card>
            }
            {
                !params.transferId || !history?.location?.state?.transferId && !props.vehicle.vehicleRegistrationNo &&
                currentSubStep != STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO &&
                <Card
                    style={{
                        height: '50%',
                        width: '100%',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: 4
                    }}
                >
                    <Table loading={true}/>
                </Card>
            }
        </Card>
    )
}

VehicleInformation.propTypes = {
    vehicle: propTypes.object,
    dealer: propTypes.object,
    onChange: propTypes.func,
}

const styles = {
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0
    },
    value: {
        fontWeight: 'bold',
    },
    ownershipTypeCard: {
        cursor: 'pointer',
        margin: 5,
    },
    ownershipTypeCardDisabled: {
        opacity: 0.5,
        margin: 5
    },
    ownershipTypeCardSelected: {
        boxShadow: `0 0 20px rgba(9, 8, 8, 0.393)`,
        transition: `box-shadow .1s`,

    }
}

export default VehicleInformation