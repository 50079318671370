import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import NotFoundPage from "../../pages/NotFoundPage";
import LoginPage from "../../pages/LoginPage";
import ForgotPasswordPage from "../../pages/ForgotPasswordPage";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import background from "../../assets/layered-waves-haikei.svg";
import ActivateAccountPage from "../../pages/ActivateAccountPage";
import Reginterest from "../../pages/Reginterest";
import RemoteUserActivationPage from "../../pages/RemoteUserActivationPage";
import ContactUsPage from "../../pages/ContactUsPage";

export default function UnauthenticatedRoutes(props) {
    const { language } = props;

    return (
        <Switch>
            <Route exact path="/login" component={LoginPage} />

            <Route
                exact
                path="/forgot-password"
                component={ForgotPasswordPage}
            />

            <Route
                exact
                path="/reginterest"
                render={() => <Reginterest language={language} />}
            />

            <Route
                exact
                path="/contact-us"
                component={ContactUsPage}
            />

            {/* 
            dev parameter is to force token to be valid. Should only be used locally
            */}
            <Route
                exact
                path="/forgot-password/:token/:dev?"
                component={ResetPasswordPage}
            />
            <Route
                exact
                path="/activate-account/:token/:dev?"
                component={ActivateAccountPage}
            />
            <Route
                exact
                path="/activate-remote-user/:token?"
                component={RemoteUserActivationPage}
            />
            <Route
                exact
                path="/activate-remote-user/:token/:isTesting"   //TODO - remove
                component={RemoteUserActivationPage}
            />
            <Route path="*" component={NotFoundPage} />
        </Switch>
    );
}
