import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { message, Form, Modal, Spin, Button, Row, Col } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  inUpdateVehicleDetails,
  checkBulkTransactionDetails,
} from "../../services/api";
import { SessionContext } from "../../App";
import { PropsFromChildren } from ".";
import { VehicleResponse, CompanyResponse, CompanyData } from "./types";
import InventoryListingV2Page from "../../components/Inventory/InventoryV2";
import { CompanyCardComponent } from "../../components/component/Layout/CardComponent";
import {
  VehicleUpdateTableComponent,
  VehicleSelectedTableComponent,
} from "../../components/component/Layout/TableComponent";
import { TermAndConditionFormItem } from "../../components/component/Layout/FormComponent";
import {
  UpdateButton,
  BackButton,
  JPJSubmissionButton,
  SaveAndContinueButton,
  BackToPrevButton,
} from "../../components/component/UI/Button";
import { TermAndConditionCheckbox } from "../../components/component/UI/Checkbox";
import {
  saveBulkM2mBuyer,
  saveBulkM2mProceed,
  saveM2mBuyer,
  saveM2mProceed,
} from "./function";
import { BUTTON_JPJ_SUBMISSION } from "../../constants";
// import { useShortcut } from "../../components/Utils";
import Icon from '@ant-design/icons';

type VehicleInformationProps = {
  // isApprover: boolean;
  company: CompanyResponse;
  vehicle: VehicleResponse;
  transferId: number;
  pendingType: string;
  isBulk: boolean;
  isReport: boolean;
  isVehicleEdit: boolean;
  isFromAdminPage: boolean;
  isJpjSubmitted: boolean;
  next: (data?: PropsFromChildren) => void;
  prev: () => void;
};

type VehicleData = {
  no: number;
  key: number;
  vehicleNo: string;
  chassisNo: string;
  engineNo: string;
  branchId: number;
};

type BulkDetails = {
  currentVehicle: string;
  totalVehicle: number;
  remainingVehicle: number;
  inquirySuccess: number;
  inquiryFailed: number;
};

export default function VehicleInformation(props: VehicleInformationProps) {
  const { company, branchs, branch, user } = useContext(SessionContext);
  const [isVehicleSearched, setIsVehicleSearched] = useState(false);
  const [vehicleDataList, setVehicleDataList] = useState<VehicleData[]>([]);
  const history = useHistory();
  const [vehicleInfoForm] = useForm();
  const [sellerCompanyData, setSellerCompanyData] = useState<CompanyData>();
  const [buyerCompanyData, setBuyerCompanyData] = useState<CompanyData>();
  const [bulkTransferId, setBulkTransferId] = useState(0);
  const [transferId, setTransferId] = useState(0);
  const [bulkDetails, setBulkDetails] = useState<BulkDetails>({
    currentVehicle: "",
    totalVehicle: 0,
    remainingVehicle: 0,
    inquirySuccess: 0,
    inquiryFailed: 0,
  });
  const [isM2MProceedProcess, setIsM2mProceedProcess] = useState(false);
  const [isM2mBulkCompleted, setIsM2mBulkCompleted] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isOneVehicle = vehicleDataList.length === 1;
  const [isDisable, setIsDisabled] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(true);

  const intervalId = useRef<NodeJS.Timer | null>(null);
  const MODULE_TYPE = {
    SUBMISSION: "SUBMISSION",
    APPROVAL: "APPROVAL",
    ENTRY_APPROVAL: "ENTRY_APPROVAL",
  };
  {
    // useShortcut(setIsModalVisible);
  }

  useEffect(() => {
    if (props.vehicle && props.vehicle.inventory) {
      const inventory: VehicleData = {
        no: 1,
        key: 1,
        vehicleNo: props.vehicle.inventory.vehicleNo,
        chassisNo: props.vehicle.inventory.chassisNo,
        engineNo: props.vehicle.inventory.engineNo,
        branchId: parseInt(props.vehicle.inventory.branchId) ?? 0,
      };
      setVehicleDataList([{ ...inventory, key: 1 }]);
      vehicleInfoForm.setFieldsValue({
        ...vehicleInfoForm,
        chassisNo: props.vehicle.inventory.chassisNo,
        engineNo: props.vehicle.inventory.engineNo,
      });
      if (props.isJpjSubmitted && props.transferId && props.vehicle) {
        props.next({
          vehicleProps: props.vehicle,
          transferId: props.transferId,
        });
      }
    }
  }, [props.vehicle]);

  useEffect(() => {
    const sellerCompany: CompanyData = {
      id: company.id,
      coName: company.coName,
      coRegNo: company.coRegNo,
      email: company.email,
      contactNo: company.contactNo,
      ucdCode: company.ucdCode,
    };
    setSellerCompanyData(sellerCompany);

    const buyerCompany: CompanyData = {
      id: props.company?.companyDetails?.id,
      coName: props.company?.companyDetails?.coName,
      coRegNo: props.company?.companyDetails?.coRegNo,
      email: props.company?.companyDetails?.email,
      contactNo: props.company?.companyDetails?.contactNo,
      ucdCode: props.company?.companyDetails?.ucdCode,
    };
    setBuyerCompanyData(buyerCompany);
  }, [props.company]);

  /* checkBulkTransaction */
  useEffect(() => {
    if (bulkTransferId !== 0 && isModalVisible && !isM2mBulkCompleted) {
      intervalId.current = setInterval(() => {
        checkBulkTransactionDetails(bulkTransferId).then((res) => {
          if (res.status) {
            setBulkDetails(res);
          } else {
            message.error(res.message);
            clearInterval(intervalId.current as any);
            // setBulkTransferId(0);
          }

          if (res.remainingVehicle === 0) {
            message.success("Process completed.");
            clearInterval(intervalId.current as any);
            setIsM2mBulkCompleted(true);
          }
        });
      }, 3000);
    }

    return () => clearInterval(intervalId.current as any);
  }, [bulkTransferId, isModalVisible]);

  const onUpdateVehicle = () => {
    inUpdateVehicleDetails(
      vehicleInfoForm.getFieldValue("chassisNo").trim().toUpperCase(),
      company.coRegNo,
      vehicleInfoForm.getFieldValue("engineNo").trim().toUpperCase(),
      props.transferId
    )
      .then((res) => {
        if (res.updateStatus != "00") {
          if (res.updateMSG) throw res.updateMSG;

          throw res.message;
        }

        message.success(res.updateMSG);

        backToPreviousScreen();
      })
      .catch((err) => {
        err && message.error(err);
      })
      .finally(() => {});
  };

  const backToPreviousScreen = () => {
    if (isVehicleSearched) {
      onBulkModalClear();
      setIsVehicleSearched(false);
    } else {
      if (props.isBulk) {
        // history.push("/reporting/transferreport/m2m/bulk");
        history.push(`/reporting/transferreport/Out`, { tabType: "3" });
      } else if (props.isFromAdminPage) {
        history.push("/adminSearchVehicleInfo");
      } else if (props.isReport) {
        history.push("/reporting/transferreport/");
      } else {
        history.push(`/reporting/transferreport/Out`, { tabType: "3" });
        // return props.prev
      }
    }
  };

  const onJpjSubmitButtonClick = () => {
    if (bulkTransferId !== 0) {
      setIsModalVisible(true);
    }
  };

  const onResetData = () => {
    /* TODO */
  };

  const onBulkModalClear = () => {
    setBulkTransferId(0);
    setIsModalVisible(false);
    setIsM2mBulkCompleted(false);
  };

  const submissionModal = () => {
    return (
      <>
        <Modal
          open={isModalVisible}
          // closable={isM2mBulkCompleted}
          footer={false}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          centered // This prop will center the modal
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }} // This will hide the cancel button
        >
          <div style={{ textAlign: "center" }}>
            <br />
            <p style={{ fontSize: "2.5em" }}>
              <b>
                {isM2mBulkCompleted ? (
                  <>{bulkDetails.totalVehicle} transactions processed</>
                ) : (
                  <>
                    {bulkDetails.totalVehicle != 0
                      ? bulkDetails.totalVehicle
                      : vehicleDataList.length}{" "}
                    transactions will submit for inquiry. This may take a
                    minute.
                  </>
                )}
              </b>
            </p>
            {isM2mBulkCompleted ? (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <div
                      style={{
                        background: "#def2d6",
                        padding: "20px",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontSize: "2.5em" }}>
                        <strong>{bulkDetails.inquirySuccess}</strong>
                      </span>
                      <br />
                      <span style={{ fontSize: "1.5em" }}>Successful</span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        background: "#ffefef",
                        padding: "20px",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontSize: "2.5em" }}>
                        <strong>{bulkDetails.inquiryFailed}</strong>
                      </span>
                      <br /> <span style={{ fontSize: "1.5em" }}>Failed</span>
                    </div>
                  </Col>
                </Row>
                <br />
                <p style={{ fontSize: "1.5em" }}>
                  Retry the {bulkDetails.inquiryFailed} failed transactions from
                  the <br />
                  transaction list.
                </p>
              </>
            ) : (
              <div style={{ textAlign: "center" }}>
                <Spin size="large" />
              </div>
            )}
            <br />
            <Button
              type="primary"
              disabled={!isM2mBulkCompleted}
              onClick={() => {
                props.next({ bulkTransferId: bulkTransferId });
              }}
            >
              {bulkDetails.inquirySuccess === 0 ? "OK" : "Approval"}
            </Button>
          </div>
          {/* )} */}
        </Modal>
      </>
    );
  };

  const onFormFinal = async () => {
    if (isM2mBulkCompleted) {
      return;
    }

    if (isOneVehicle) {
      let currentTransferId = transferId;
      if (transferId === 0) {
        setIsDisabled(true);
        await saveM2mBuyer(
          props.company?.companyDetails,
          vehicleDataList[0],
          company,
          branch,
          user
        ).then(async (resultId) => {
          if (resultId !== null) {
            setTransferId(resultId);
            currentTransferId = resultId;
          } else {
            setIsDisabled(false);
            return;
          }
        });
      }

      const response = await saveM2mProceed(
        currentTransferId,
        "SUBMISSION",
        onResetData
      );

      setIsM2mBulkCompleted(true);
      setIsDisabled(false);
      props.next({ transferId: currentTransferId });
    } else {
      if (isM2MProceedProcess) {
        return;
      }

      let currentBulkId = bulkTransferId;
      if (bulkTransferId === 0) {
        await saveBulkM2mBuyer(
          props.company?.companyDetails,
          vehicleDataList,
          company,
          branch,
          user
        ).then(async (resultId) => {
          if (resultId !== null) {
            setBulkTransferId(resultId);
            currentBulkId = resultId;
          } else {
            return;
          }
        });
      }

      const response = await saveBulkM2mProceed(
        setBulkTransferId,
        currentBulkId,
        [],
        company.id,
        onResetData,
        MODULE_TYPE.SUBMISSION
      );

      if (response) {
        setIsM2mProceedProcess(true);
        setIsModalVisible(true);
      }
    }
  };

  const onSaveAndContinue = async () => {
    if (isOneVehicle) {
      let currentTransferId = transferId;
      if (transferId === 0) {
        setIsDisabled(true);
        await saveM2mBuyer(
          props.company?.companyDetails,
          vehicleDataList[0],
          company,
          branch,
          user
        ).then(async (resultId) => {
          if (resultId !== null) {
            setTransferId(resultId);
            currentTransferId = resultId;
          } else {
            setIsDisabled(false);
            return;
          }
        });
        if (currentTransferId !== 0) redirectToPendingList();
      }
    } else {
      let currentBulkId = bulkTransferId;
      if (bulkTransferId === 0) {
        setIsDisabled(true);
        await saveBulkM2mBuyer(
          props.company?.companyDetails,
          vehicleDataList,
          company,
          branch,
          user
        ).then(async (resultId) => {
          if (resultId !== null) {
            setBulkTransferId(resultId);
            currentBulkId = resultId;
          } else {
            setIsDisabled(false);
            return;
          }
        });
        if (currentBulkId !== 0) redirectToPendingList();
      }
    }
  };

  const redirectToPendingList = () => {
    message.loading("Redirect to pending list...");

    setTimeout(() => {
      history.push(`/reporting/transferreport/Out`, { tabType: "3" });
    }, 1 * 1000);
  };

  const notificationIcon = () => (
    <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.5392 15.0898V11.497H32V15.0898H25.5392ZM27.4394 26.6667L22.2708 22.6747L24.323 19.7206L29.4917 23.7924L27.4394 26.6667ZM24.323 6.94611L22.2708 3.99202L27.4394 0L29.4917 2.87425L24.323 6.94611ZM4.40855 25.0699V18.523H3.26841C2.38163 18.523 1.6152 18.1836 0.969121 17.505C0.32304 16.8263 0 16.0213 0 15.0898V11.5768C0 10.6454 0.32304 9.84032 0.969121 9.16168C1.6152 8.48303 2.38163 8.14371 3.26841 8.14371H8.58907L16.6461 3.03393V23.6327L8.58907 18.523H7.82898V25.0699H4.40855ZM19.6865 18.9222V7.74451C20.4212 8.43646 21.0103 9.26148 21.4537 10.2196C21.8971 11.1776 22.1188 12.2156 22.1188 13.3333C22.1188 14.4511 21.8971 15.489 21.4537 16.4471C21.0103 17.4052 20.4212 18.2302 19.6865 18.9222Z" fill="#FFE331"/>
    </svg>
  )

  const closeWhiteIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.0908 7.63617L7.6364 19.0906C7.28492 19.4421 7.28492 20.0119 7.6364 20.3634C7.98787 20.7149 8.55772 20.7149 8.90919 20.3634L20.3636 8.90896C20.7151 8.55749 20.7151 7.98764 20.3636 7.63617C20.0121 7.2847 19.4423 7.2847 19.0908 7.63617Z" fill="white"/>
      <path d="M20.3638 19.0908L8.90935 7.6364C8.55787 7.28492 7.98803 7.28492 7.63655 7.6364C7.28508 7.98787 7.28508 8.55772 7.63655 8.90919L19.091 20.3636C19.4424 20.7151 20.0123 20.7151 20.3638 20.3636C20.7152 20.0121 20.7152 19.4423 20.3638 19.0908Z" fill="white"/>
    </svg>
  )

  // https://jira.myeg.com.my/browse/ETK-885
  const notificationModal = () => {
    return (
      <>
        <Modal
          open={openNotificationModal}
          onCancel={() => {
            setOpenNotificationModal(false);
          }}
          closeIcon={closeWhiteIcon()}
          width={"75vw"}
          footer={false}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }} // This will hide the cancel button
          style={{
            top: 20,
          }}
          maskClosable={false}
          className="custom-notification-modal"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div><Icon component={notificationIcon} style={{ padding: '8px 16px', width: '32px', height: '26.6px' }}/></div>
            
            <div>
              <div style={{
                  padding: '8px 16px 0px 0px',
                  color: '#FFFFFF',
                  fontWeight: '700',
                  fontSize: '16px',
                  lineHeight: '24px'
                }}>
                Vehicles registered for over six months are temporarily unsupported. 
              </div>

              <div style={{
                  padding: '0px 16px 8px 0px',
                  color: '#FFFFFF',
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '24px'
                }}>
                  We’re working with JPJ to resolve this for permanently registered dealer vehicles. Apologies for any inconvenience.
                </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  return (
    <>
      {props.isVehicleEdit ? (
        /* Vehicle Edit */
        <>
          {VehicleUpdateTableComponent(vehicleDataList, vehicleInfoForm)}
          <div className="section-bottom-button">
            {BackButton(backToPreviousScreen)}
            {UpdateButton(onUpdateVehicle)}
          </div>
        </>
      ) : !isVehicleSearched ? (
        /* Inventory Listing */
        <>
          <InventoryListingV2Page
            props={props}
            setIsVehicleSearched={setIsVehicleSearched}
            setVehicleSelectedData={setVehicleDataList}
          />
          <br />
          {BackToPrevButton(props)}
        </>
      ) : (
        /* JPJ Submission Confirmation */
        <>
          <Form onFinish={onFormFinal}>
            {CompanyCardComponent(sellerCompanyData, buyerCompanyData, true)}
            {VehicleSelectedTableComponent(
              vehicleDataList,
              branchs,
              true,
              false,
              branch.id === 0
            )}

            {/* Term and Condition */}
            {TermAndConditionFormItem(TermAndConditionCheckbox)}
            <br />
            <Row justify={"space-between"}>
              <Col style={{ display: "flex", alignItems: "center" }}>
                {BackButton(backToPreviousScreen)}&nbsp;
                {SaveAndContinueButton(
                  onSaveAndContinue,
                  transferId !== 0 || bulkTransferId !== 0 || isDisable,
                  isDisable
                )}
              </Col>
              <Col>
                <Form.Item>
                  {JPJSubmissionButton(
                    onJpjSubmitButtonClick,
                    isDisable,
                    BUTTON_JPJ_SUBMISSION
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
      {submissionModal()}

      {notificationModal()}

      <style>
        {`
        
          .custom-notification-modal {
            .ant-modal-content {
              background: #333333;
              padding: 0px;
              border-color: #333333;
              border-radius: 8px;
              box-shadow: 0 0 0 1px #333333;
            }
          }
        `}
      </style>
    </>
  );
}
