import { message } from "antd";
import {
  bulkM2mBuyer,
  m2mProceedBulk,
  m2mBuyer,
  m2mProceed,
  getInTransferAmountByTransferId,
} from "../../services/api";
import {
  Buyer,
  Vehicle,
  SaveBulkBuyerRequest,
  SaveBuyerRequest,
} from "./types";

export const saveBulkM2mBuyer = async (
  companyDetails,
  vehicleDataList,
  company,
  branch,
  user
) => {
  const { companyAddress: addr } = companyDetails;
  const buyer: Buyer = {
    name: companyDetails.coName,
    identificationNo: companyDetails.coRegNo,
    phoneNo: companyDetails.contactNo,
    // dateOfBirth: companyDetails.createdDate,
    gender: "",
    email: companyDetails.email,
    address1: addr.line1,
    address2: addr.line2,
    address3: addr.line3,
    state: addr.stateCode,
    postcode: addr.postcode,
    district: addr.district,
    correspondentAddress1: "",
    correspondentAddress2: "",
    correspondentAddress3: "",
    correspondentDistrict: "",
    correspondentEmail: "",
    correspondentMobileNo: "",
    correspondentPostcode: "",
    correspondentState: "",
  };

  const vehicleInfoList: Vehicle[] = [];
  for (const data of vehicleDataList) {
    const vehicleInfo: Vehicle = {
      chassisNo: data.chassisNo,
      engineNo: data.engineNo,
      isWhiteListVehicle: false, // hardcoded defaults
      lkmoption: 1, // hardcoded defaults
      ownerIdCat: "", // hardcoded
      vehicleRegistrationNo: data.vehicleNo,
      branchId: data.branchId ?? 0,
    };
    vehicleInfoList.push(vehicleInfo);
  }
  const saveBuyerPayload: SaveBulkBuyerRequest = {
    currentLoginUserName: user.username,
    dealerCompanyROC: company.coRegNo,
    branchId: branch.id ?? 0,
    buyerInfo: buyer,
    uId: "",
    vehicleInfoList: vehicleInfoList,
  };

  try {
    const response = await bulkM2mBuyer(saveBuyerPayload);
    if (response.saveBuyerStatus === "00") {
      message.success("Transaction saved successfully.");
      return response.bulkTransferId;
    } else {
      throw response.saveBuyerMSG;
    }
  } catch (error) {
    message.error(error);
    return null;
  }
};

export const saveBulkM2mProceed = async (
  setBulkTransferId,
  bulkTransferId,
  transferIdList,
  companyId,
  onResetData,
  moduleType
) => {
  const saveBuyerPayload = {
    transferIdList: bulkTransferId == null ? transferIdList : [],
    uId: "",
    bulkTransferId: bulkTransferId ?? null,
    companyId: companyId,
    moduleType: moduleType,
  };

  const m2mProceedRes = await m2mProceedBulk(saveBuyerPayload);
  if (m2mProceedRes.inquiryStatus !== "00") {
    message.error("Processing Failed. Please Try Again.");
  } else {
    setBulkTransferId(m2mProceedRes.bulkTransferId);
    if (moduleType !== "ENTRY_APPROVAL") {
      message.info("Transfer in Progress.");
    } else {
      message.success("Approved");
    }
    onResetData();
  }
  return m2mProceedRes;
};

export const saveM2mBuyer = async (
  companyDetails,
  vehicleData,
  company,
  branch,
  user
) => {
  const { companyAddress: addr } = companyDetails;
  const buyer: Buyer = {
    name: companyDetails.coName,
    identificationNo: companyDetails.coRegNo,
    phoneNo: companyDetails.contactNo,
    // dateOfBirth: companyDetails.createdDate,
    gender: "",
    email: companyDetails.email,
    address1: addr.line1,
    address2: addr.line2,
    address3: addr.line3,
    state: addr.stateCode,
    postcode: addr.postcode,
    district: addr.district,
    correspondentAddress1: "",
    correspondentAddress2: "",
    correspondentAddress3: "",
    correspondentDistrict: "",
    correspondentEmail: "",
    correspondentMobileNo: "",
    correspondentPostcode: "",
    correspondentState: "",
  };

  const vehicleInfo: Vehicle = {
    chassisNo: vehicleData.chassisNo,
    engineNo: vehicleData.engineNo,
    isWhiteListVehicle: false, // hardcoded defaults
    lkmoption: 1, // hardcoded defaults
    ownerIdCat: "", // hardcoded
    vehicleRegistrationNo: vehicleData.vehicleNo,
    branchId: vehicleData.branchId ?? 0,
  };

  const saveBuyerPayload: SaveBuyerRequest = {
    currentLoginUserName: user.username,
    dealerCompanyROC: company.coRegNo,
    branchId: branch.id ?? 0,
    buyerInfo: buyer,
    uId: "",
    vehicleInfo: vehicleInfo,
  };

  try {
    const response = await m2mBuyer(saveBuyerPayload);
    if (response.saveBuyerStatus === "00") {
      message.success("Transaction saved successfully.");
      return response.transferId;
    } else {
      throw response.saveBuyerMSG;
    }
  } catch (error) {
    message.error(error);
    return null;
  }
};

export const saveM2mProceed = async (transferId, moduleType, onResetData) => {
  const saveBuyerPayload = {
    transferId: transferId,
    uId: "",
    moduleType: moduleType !== null ? moduleType : "",
  };

  const m2mProceedRes = await m2mProceed(saveBuyerPayload);
  if (m2mProceedRes.inquiryStatus !== "00") {
    moduleType === "APPROVAL"
      ? message.error("Processing Failed. Please Try Again.")
      : message.error(m2mProceedRes.inquiryMSG);
  } else {
    moduleType === "APPROVAL"
      ? message.success("Approved")
      : message.success(m2mProceedRes.inquiryMSG);
    onResetData();
  }
  return m2mProceedRes;
};
