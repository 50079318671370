import {
    DownloadOutlined,
    PrinterOutlined,
} from '@ant-design/icons';
import {
    Breadcrumb,
    Button,
    Col,
    Form,
    Input,
    Row,
    Select,
    Table,
    Tag,
    Image,
} from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import React, { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../../App';
import moment from 'moment';
import ReactExport from 'react-export-excel';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './index.less';
import arrow_back from '../../../assets/arrow_back.svg'
import export_xls from "../../../assets/export_xls_filled.svg";
import {
    IN_TRANSACTION_STATUSES,
    IN_TRANSACTION_STATUSES_COLORS,
    IN_TRANSACTION_STATUSES_LABELS,
    OUT_TRANSACTION_STATUSES,
    OUT_TRANSACTION_STATUSES_COLORS,
    OUT_TRANSACTION_STATUSES_LABELS,
    OUT_TRANSACTION_TYPES, OUT_TRANSACTION_TYPES_IDS,
    APT_TRANSACTION_STATUSES,
    SEARCH_TYPES,
    NRIC_TYPES,
    SEARCH_QUERY_TOOLTIP,
    M2M_SEARCH_TYPES
} from '../../../constants'
import {
    searchInTransactions,
    searchI2iTransactions,
    searchOutTransactions,
    searchM2mTransactions,
} from '../../../services/api';
import CustomPaginationTable, {
    REPORT_TYPE_LABELS,
    TAB_TYPE,
    STATUS_TYPE,
    SEARCH,
} from "../../../components/CustomPagination/TransactionTable";
import { useHistory, useParams } from 'react-router-dom'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const PendingListPage = () => {
    const history = useHistory()
    const { company, branch } = useContext(SessionContext);
    const [reportType, setReportType] = useState('IN');
    const [category, setCategory] = useState('Pending_Submission');
    const [status, setStatus] = useState('Pending_Submission');
    const [sortBy, setSortBy] = useState('1D');
    const [searchTerm, setSearchTerm] = useState('');
    const [dataSource, setDataSource] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 30,
    });

    const getColumns = () => {
        const baseColumns = [
            {
                title: 'Created Date',
                dataIndex: 'createdDate',
                render: (date) => moment(date).format('DD/MM/YYYY hh:mm:ss'),
                sorter: true,
            },
            {
                title: 'Seller',
                dataIndex: 'sellerName',
            },
            {
                title: 'Vehicle Number',
                dataIndex: 'carRegistrationNo',
            },
            {
                title: 'Chasis Number / Engine Number',
                render: (record) => (
                    <div>
                        <div>Chasis: {record.chassisNo}</div>
                        <div>Engine: {record.engineNo}</div>
                    </div>
                ),
            },
            {
                title: "Status",
                width: 200,
                render: (rowData) => {
                    return (
                        <>
                            {/* Handle IN report type */}
                            {reportType === "IN" && (
                                <>
                                    {/* Pending for JPJ submission */}
                                    {category === "Pending_Submission" && rowData.failCode === "" && (
                                        <Tag color={IN_TRANSACTION_STATUSES_COLORS["0"]}>
                                            Pending for JPJ submission
                                        </Tag>
                                    )}

                                    {/* Pending for Payment and M2M */}
                                    {(category !== "Pending_Submission" && rowData.failCode === "") && rowData.paymentStatus !== "S" && (
                                        <Tag color={IN_TRANSACTION_STATUSES_COLORS[rowData.status]}>
                                            {IN_TRANSACTION_STATUSES_LABELS[rowData.status]}
                                        </Tag>
                                    )}

                                    {/* Default IN statuses */}
                                    {rowData.failCode !== "" && rowData.paymentStatus !== "S" && (
                                        <Tag color={IN_TRANSACTION_STATUSES_COLORS[rowData.status]}>
                                            {IN_TRANSACTION_STATUSES_LABELS[rowData.status]}
                                        </Tag>
                                    )}

                                    {/* Transaction success for IN */}
                                    {rowData.paymentStatus === "S" && (
                                        <Tag color={IN_TRANSACTION_STATUSES_COLORS[2]}>
                                            {IN_TRANSACTION_STATUSES_LABELS[2]}
                                        </Tag>
                                    )}
                                </>
                            )}

                            {/* Handle OUT report type */}
                            {reportType === "OUT" && (
                                <>
                                    {/* Handle M2M cases */}
                                    {rowData.isM2mTransaction && rowData.sellerInquirySuccess !== null ? (
                                        <Tag color={"blue"}>{"Pending Seller Approval"}</Tag>
                                    ) : rowData.isM2mTransaction && rowData.failCode !== "" ? (
                                        <Tag color={"red"}>{"Failed"}</Tag>
                                    ) : rowData.isM2mTransaction ? (
                                        <Tag color={"blue"}>{"Pending JPJ Submission"}</Tag>
                                    ) : (
                                        <Tag color={OUT_TRANSACTION_STATUSES_COLORS[rowData.status]}>
                                            {OUT_TRANSACTION_STATUSES_LABELS[rowData.status]}
                                        </Tag>
                                    )}
                                </>
                            )}

                            {/* Handle I2I report type */}
                            {reportType === "I2I" && (
                                <Tag color={OUT_TRANSACTION_STATUSES_COLORS[rowData.status]}>
                                    {OUT_TRANSACTION_STATUSES_LABELS[rowData.status]}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'JPJ Response Message',
                dataIndex: 'failCode',
                width: 300,
                // sorter: (a, b) => a.sellerName.localeCompare(b.sellerName),
                render: (text) => <span dangerouslySetInnerHTML={{ __html: text }}></span>,
            },
        ];

        // Add "Day Remaining" column for OUT or I2I transaction types, but exclude when payload status : "Pending_M2M"
        if ((reportType === 'OUT' || reportType === 'I2I') && category !== "Pending_M2M") {
            baseColumns.push({
                title: 'Day Remaining',
                dataIndex: 'dayRemaining',
            });
        }


        return baseColumns;
    };

    const fetchData = async (page = pagination.current, pageSize = pagination.pageSize) => {
        setIsLoading(true);
        try {
            const search = 'vehiclenumber'; // Initialize `search` (e.g., vehicle number or other search criteria)
            const searchParam = searchTerm; // Assign the search term as `searchParam`

            // Ensure `dealerCompanyROC` is properly initialized
            const dealerCompanyROC = company?.coRegNo || '';

            let response = [];

            // Fetch data based on the selected transaction type and status
            if (reportType === 'IN') {
                response = await searchInTransactions(
                    branch.id,
                    dealerCompanyROC,
                    pageSize,
                    page,
                    search,
                    searchParam,
                    sortBy,
                    category
                );
            } else if (reportType === 'I2I') {
                response = await searchI2iTransactions(
                    branch.id,
                    dealerCompanyROC,
                    pageSize,
                    page,
                    search,
                    searchParam,
                    sortBy,
                    category
                );
            } else if (reportType === 'OUT' && category === 'Pending_M2M') {
                // Use searchM2mTransactions if reportType is OUT and status is M2M
                response = await searchM2mTransactions(
                    branch.id,
                    dealerCompanyROC,
                    pageSize,
                    page,
                    search,
                    searchParam,
                    sortBy,
                    category
                );
            } else if (reportType === 'OUT') {
                // Use searchOutTransactions for other statuses when reportType is OUT
                response = await searchOutTransactions(
                    branch.id,
                    dealerCompanyROC,
                    pageSize,
                    page,
                    search,
                    searchParam,
                    sortBy,
                    category
                );
            }

            // Update table data and pagination
            setDataSource(response.data || []);
            setPagination((prev) => ({
                ...prev,
                current: page,
                pageSize,
                total: response.count || 0, // Total number of records from the API
            }));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };



    useEffect(() => {

        if (company?.coRegNo) {
            // Reset to the first page when reportType or status changes
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
            fetchData(1, pagination.pageSize);

        }
    }, [branch, company, category, searchTerm, sortBy, reportType]);

    // Handle table pagination change
    const handleTableChange = (newPagination) => {
        // Fetch data for the selected page
        fetchData(newPagination.current, newPagination.pageSize);

        // Update pagination state
        setPagination((prev) => ({
            ...prev,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        }));
    };

    const exportToPDF = () => {
        const doc = new jsPDF('landscape'); // Set orientation to landscape

        // Set title
        doc.text('Transaction Monitoring List', 14, 10);

        // Define headers and data
        const headers = getColumns().map((col) => col.title);
        const tableData = dataSource.map((row) =>
            getColumns().map((col) => {
                if (col.title === 'Created Date') {
                    return moment(row.createdDate).format('DD/MM/YYYY hh:mm:ss');
                } else if (col.title === 'Chasis Number / Engine Number') {
                    return `Chasis: ${row.chassisNo || ''},\n\nEngine: ${row.engineNo || ''}`;
                } else if (col.title === 'Status') {
                    if (reportType === 'IN') {
                        if (category === 'Pending_Submission' && row.failCode === '') {
                            return 'Pending for JPJ submission';
                        } else if (category !== 'Pending_Submission' && row.failCode === '' && row.paymentStatus !== 'S') {
                            return IN_TRANSACTION_STATUSES_LABELS[row.status] || '';
                        } else if (row.failCode !== '' && row.paymentStatus !== 'S') {
                            return IN_TRANSACTION_STATUSES_LABELS[row.status] || '';
                        } else if (row.paymentStatus === 'S') {
                            return IN_TRANSACTION_STATUSES_LABELS[2] || '';
                        }
                    } else if (reportType === 'OUT') {
                        if (row.isM2mTransaction && row.sellerInquirySuccess !== null) {
                            return 'Pending Seller Approval';
                        } else if (row.isM2mTransaction && row.failCode !== '') {
                            return 'Failed';
                        } else if (row.isM2mTransaction) {
                            return 'Pending JPJ Submission';
                        } else {
                            return OUT_TRANSACTION_STATUSES_LABELS[row.status] || '';
                        }
                    } else if (reportType === 'I2I') {
                        return OUT_TRANSACTION_STATUSES_LABELS[row.status] || '';
                    }
                } else if (col.title === 'JPJ Response Message') {
                    return (row.failCode || '').replace(/<br\s*\/?>/gi, '\n');
                } else {
                    return row[col.dataIndex] || ''; // Default fallback
                }
            })
        );

        const columnStyles = {
            0: { cellWidth: 30 }, // Created Date
            1: { cellWidth: 30 }, // Seller
            2: { cellWidth: 30 }, // Vehicle Number
            3: { cellWidth: 40 }, // Chasis/Engine Number
            4: { cellWidth: 40 }, // Status
            5: { cellWidth: 100 }, // JPJ Response Message
        };
        
        const columnStyles2 = {
            0: { cellWidth: 30 }, // Created Date
            1: { cellWidth: 30 }, // Seller
            2: { cellWidth: 30 }, // Vehicle Number
            3: { cellWidth: 40 }, // Chasis/Engine Number
            4: { cellWidth: 40 }, // Status
            5: { cellWidth: 70 },  // JPJ Response Message
            6: { cellWidth: 30 }, // Day Remaining
        };
        
        // Determine which column styles to use based on the condition
        const selectedColumnStyles =
            (reportType === 'OUT' || reportType === 'I2I') && category !== "Pending_M2M"
                ? columnStyles2
                : columnStyles;
        
        // Add autoTable to the PDF
        doc.autoTable({
            head: [headers],
            body: tableData,
            styles: { overflow: 'linebreak', fontSize: 8 },
            columnStyles: selectedColumnStyles, // Apply the selected column styles
            startY: 20,
        });

        // Save the PDF
        doc.save('TransactionMonitoringList.pdf');
    };




    return (
        <div className="page-content pending-list-page">
            <Breadcrumb separator=">" style={{ marginTop: '20px' }}>
                <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="/reporting">Reporting</Breadcrumb.Item>
                <Breadcrumb.Item href="/reporting/pendinglist">Transaction Monitoring List</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col span={12}>
                    <PageHeader
                        style={{ paddingLeft: "0px" }}
                        className="site-page-header"
                        title="Transaction Monitoring List"
                    />
                </Col>
            </Row>

            <Col gutter={16} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Row>
                    <Col className='column-wrapper'>
                        <Form.Item label="Transaction Type" className="dropdown-wrapper" style={{ width: '100%' }}>
                            <Select
                                value={reportType}
                                onChange={(value) => {
                                    setReportType(value); // Update the transaction type
                                    setCategory("Pending_Submission"); // Reset the status to "JPJ SUBMISSION"
                                }}
                                style={{ width: '80px' }}
                            >
                                {['IN', 'OUT', 'I2I'].map((type) => (
                                    <Select.Option key={type} value={type}>
                                        {type}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className='column-wrapper'>
                        <Form.Item label="Category" className="dropdown-wrapper" style={{ width: '100%' }}>
                            <Select
                                value={category}
                                onChange={(value) => setCategory(value)}
                                style={{ width: '150px' }}
                            >
                                {Object.entries(STATUS_TYPE)
                                    .filter(([key]) => {
                                        // Show "M2M" only if the transaction type is IN or OUT
                                        if (key === "M2M" && !(reportType === "IN" || reportType === "OUT")) {
                                            return false;
                                        }
                                        return true;
                                    })
                                    .map(([key, value]) => {
                                        return (
                                            <Select.Option key={key} value={value}>
                                                {key.replace('_', ' ')} {/* Format status label */}
                                            </Select.Option>
                                        );
                                    })}

                            </Select>


                        </Form.Item>
                    </Col>
                    {/* <Col className='column-wrapper'>
                        <Form.Item label="Status" className="dropdown-wrapper" style={{ width: '100%' }}>
                            <Select
                                value={status}
                                onChange={(value) => setStatus(value)}
                                style={{ width: '120px' }}
                            >
                                {['All', 'Pending JPJ', 'Completed', 'Failed'].map((stat) => (
                                    <Select.Option key={stat} value={stat}>
                                        {stat}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col> */}
                </Row>
                <Row>
                    <Form.Item label="Vehicle Number" className="search-wrapper" style={{ width: '100%' }}>
                        <Input.Search
                            placeholder="Search"
                            onSearch={(value) => setSearchTerm(value)}
                            allowClear
                        />
                    </Form.Item>
                </Row>
            </Col>
            <Row style={{ justifyContent: 'flex-end', marginBottom: '20px' }}>
                <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <ExcelFile
                        element={
                            <Button
                                shape="round"
                                className="add-new-user-button export-report"
                                type="primary"
                                disabled={dataSource.length === 0}
                            >
                                <Image
                                    src={export_xls}
                                    preview={false}
                                    width={30}
                                    height={30}
                                    style={{ paddingRight: 10 }}
                                />
                                Export to Excel
                            </Button>
                        }
                        filename="TransactionMonitoringList"
                    >
                        <ExcelSheet data={dataSource} name="Transaction Monitoring List">
                            {getColumns().map((col) => (
                                <ExcelColumn
                                    key={col.dataIndex || col.title}
                                    label={col.title}
                                    value={(row) => {
                                        if (col.title === 'Created Date') {
                                            return moment(row.createdDate).format('DD/MM/YYYY hh:mm:ss');
                                        } else if (col.title === 'Chasis Number / Engine Number') {
                                            // Handle Chassis and Engine rendering
                                            return `Chasis: ${row.chassisNo || ''},\n\nEngine: ${row.engineNo || ''}`;
                                        } else if (col.title === 'Status') {
                                            // Handle Status rendering logic
                                            if (reportType === 'IN') {
                                                if (category === 'Pending_Submission' && row.failCode === '') {
                                                    return 'Pending for JPJ submission';
                                                } else if (category !== 'Pending_Submission' && row.failCode === '' && row.paymentStatus !== 'S') {
                                                    return IN_TRANSACTION_STATUSES_LABELS[row.status] || '';
                                                } else if (row.failCode !== '' && row.paymentStatus !== 'S') {
                                                    return IN_TRANSACTION_STATUSES_LABELS[row.status] || '';
                                                } else if (row.paymentStatus === 'S') {
                                                    return IN_TRANSACTION_STATUSES_LABELS[2] || '';
                                                }
                                            } else if (reportType === 'OUT') {
                                                if (row.isM2mTransaction && row.sellerInquirySuccess !== null) {
                                                    return 'Pending Seller Approval';
                                                } else if (row.isM2mTransaction && row.failCode !== '') {
                                                    return 'Failed';
                                                } else if (row.isM2mTransaction) {
                                                    return 'Pending JPJ Submission';
                                                } else {
                                                    return OUT_TRANSACTION_STATUSES_LABELS[row.status] || '';
                                                }
                                            } else if (reportType === 'I2I') {
                                                return OUT_TRANSACTION_STATUSES_LABELS[row.status] || '';
                                            }
                                        } else if (col.title === 'JPJ Response Message') {
                                            // Handle JPJ Response Message rendering
                                            return (row.failCode || '').replace(/<br\s*\/?>/gi, '\n');
                                        } else {
                                            return row[col.dataIndex] || ''; // Default fallback
                                        }
                                    }}
                                />
                            ))}
                        </ExcelSheet>


                    </ExcelFile>
                    <Button
                        className="add-new-user-button export-report"
                        icon={<PrinterOutlined />}
                        onClick={exportToPDF}
                        type="primary"
                        disabled={dataSource.length === 0}
                    >
                        Export to PDF
                    </Button>
                </Col>
            </Row>

            <Table
                columns={getColumns()}
                dataSource={dataSource}
                loading={isLoading}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showSizeChanger: true, // Allow users to change page size
                }}
                onChange={handleTableChange} // Handle pagination changes
                rowKey="id" // Use a unique key for rows
            />
            <Button
                className="back-button buttons"
                shape="round"
                onClick={() => {
                    history.push('/reporting');
                }}
            >
                <Image src={arrow_back} preview={false} />
                Back
            </Button>

            <style>
                {`
                .dropdown-wrapper .ant-select-selector  {
                    max-width: 100% !important;
                    background-color: transparent !important;
                    border: 0 !important;
                }
                
                .ant-select > .ant-select-selector {
                    height: 32px !important;
                    display: flex !important;
                    align-items: center !important;
                }
                .footer-button {
                    border: 1px solid #2B61C4;
                    color: #2B61C4;
                    margin-right: 20px;
                    margin-left: 0px;
                }
            `}
            </style>
        </div>
    );
};

export default PendingListPage;
