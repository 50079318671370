import moment from 'moment'
import passportImage from '../assets/Passport.png'
import companyImage from '../assets/SSM.png'
// import memberImage from '../assets/myeg logo small.png'
import citizenOfMalaysiaImage from '../assets/MyKad.png'
import individualToIndividual from '../assets/i-i.png'
import individualToCompany from '../assets/i-c.png'
import companyToCompany from '../assets/c-c.png'
import companyToIndividual from '../assets/c-i.png'
import myPr from '../assets/illus-mypr-160.svg'
// import myKad from '../assets/illus-mykad-160.svg'
import myKad from '../assets/label/icon-mykad.svg'
import memberImage from '../assets/label/icon-m2m.svg'


import { config } from '../config'

export const IN_TRANSACTION_STATUSES = {
    PENDING_APPROVAL: 0,
    TRANSACTION_SUCCESS: 1,
    FAILED: 2,
}

export const M2M_TRANSACTION_STATUSES = {
    PENDING_APPROVAL: 0,
    TRANSACTION_SUCCESS: 1,
    FAILED: 2,
    // PENDING_M2M_APPROVAL: 3,
}

export const IN_TRANSACTION_STATUSES_LABELS = {
    [IN_TRANSACTION_STATUSES.PENDING_APPROVAL]: `Pending approval`,
    [IN_TRANSACTION_STATUSES.TRANSACTION_SUCCESS]: `Success`,
    [IN_TRANSACTION_STATUSES.FAILED]: `Failed`,
}

export const IN_TRANSACTION_STATUSES_COLORS = {
    [IN_TRANSACTION_STATUSES.PENDING_APPROVAL]: `blue`,
    [IN_TRANSACTION_STATUSES.TRANSACTION_SUCCESS]: `green`,
    [IN_TRANSACTION_STATUSES.FAILED]: `red`,
}

export const APT_TRANSACTION_STATUSES = {
    WAITING_FOR_JPJ_NOTIFICATION: 0,
    PENDING: 1,
    SUCCESS: 2,
    FAILED: 3,
}

export const APT_TRANSACTION_STATUSES_LABELS = {
    [APT_TRANSACTION_STATUSES.WAITING_FOR_JPJ_NOTIFICATION]: `Waiting for JPJ Notification`,
    [APT_TRANSACTION_STATUSES.PENDING]: `Pending`,
    [APT_TRANSACTION_STATUSES.SUCCESS]: `Success`,
    [APT_TRANSACTION_STATUSES.FAILED]: `Failed`,
}

export const APT_TRANSACTION_STATUSES_COLORS = {
    [APT_TRANSACTION_STATUSES.WAITING_FOR_JPJ_NOTIFICATION]: `blue`,
    [APT_TRANSACTION_STATUSES.PENDING]: `blue`,
    [APT_TRANSACTION_STATUSES.SUCCESS]: `green`,
    [APT_TRANSACTION_STATUSES.FAILED]: `red`,
}

export const OUT_TRANSACTION_STATUSES = {
    SELLER_INQUIRY_SUCCESS: 1,
    SELLER_INQUIRY_FAILED: 2,
    PENDING_PAYMENT: 3,
    SUCCESS: 4,
    PENDING_FOR_DEALER_APPROVAL: 5,
    BUYER_INQUIRY_FAILED: 6,
    UPDATE_TO_JPJ_FAILED: 7,
    PENDING_FOR_MYEG_OFFICER_APPROVAL: 8,
    PENDING_SELLER_INFO: 9,
    PENDING_BUYER_INFO: 10,
    PENDING_BUYER_MYSIKAP: 11,
    PENDING_SELLER_MYSIKAP: 12,
    PENDING_BUYER_AND_SELLER_MYSIKAP: 13,
    PENDING_FOR_APPROVAL_SUBMISSION: 14,
}

export const OUT_TRANSACTION_STATUSES_LABELS = {
    [OUT_TRANSACTION_STATUSES.SUCCESS]: 'Success',
    [OUT_TRANSACTION_STATUSES.PENDING_PAYMENT]: 'Pending payment',
    [OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED]: 'Owner inquiry failed',
    [OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS]: 'Owner inquiry success',
    [OUT_TRANSACTION_STATUSES.PENDING_FOR_DEALER_APPROVAL]: 'Pending for dealer approval',
    [OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED]: 'Buyer inquiry failed',
    [OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED]: 'Update to jpj failed',
    [OUT_TRANSACTION_STATUSES.PENDING_FOR_MYEG_OFFICER_APPROVAL]: 'Pending for MYEG officer aprpoval',
    [OUT_TRANSACTION_STATUSES.PENDING_SELLER_INFO]: 'Pending owner information',
    [OUT_TRANSACTION_STATUSES.PENDING_BUYER_INFO]: 'Pending buyer information',
    [OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP]: 'Pending buyer mysikap',
    [OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP]: 'Pending owner mysikap',
    [OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP]: 'Pending buyer & owner mysikap',
    [OUT_TRANSACTION_STATUSES.PENDING_FOR_APPROVAL_SUBMISSION]: 'Pending for approval submission',
}

export const OUT_TRANSACTION_STATUSES_COLORS = {
    [OUT_TRANSACTION_STATUSES.SUCCESS]: 'green',
    [OUT_TRANSACTION_STATUSES.PENDING_PAYMENT]: 'blue',
    [OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED]: 'red',
    [OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS]: 'blue',
    [OUT_TRANSACTION_STATUSES.PENDING_FOR_DEALER_APPROVAL]: 'blue',
    [OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED]: 'red',
    [OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED]: 'red',
    [OUT_TRANSACTION_STATUSES.PENDING_FOR_MYEG_OFFICER_APPROVAL]: 'blue',
    [OUT_TRANSACTION_STATUSES.PENDING_SELLER_INFO]: 'blue',
    [OUT_TRANSACTION_STATUSES.PENDING_BUYER_INFO]: 'blue',
    [OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP]: 'blue',
    [OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP]: 'blue',
    [OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP]: 'blue',
    [OUT_TRANSACTION_STATUSES.PENDING_FOR_APPROVAL_SUBMISSION]: 'blue',
}

export const USER_STATUSES = {
    ACTIVE: true,
    INACTIVE: false
}

export const USER_STATUSES_LABELS = {
    [USER_STATUSES.ACTIVE]: 'Active',
    [USER_STATUSES.INACTIVE]: 'Inactive'
}

export const BRANCH_STATUSES = {
    ACTIVE: 'active',
    INACTIVE: 'inactive'
}

export const BRANCH_STATUSES_LABELS = {
    [BRANCH_STATUSES.ACTIVE]: 'Active',
    [BRANCH_STATUSES.INACTIVE]: 'Inactive'
}

export const TRANSACTION_RESPONSE_CODES = {
    SUCCESS: '00'
}

export const TRANSACTION_OUT_RESPONSE_CODES = {
    SUCCESS: '00'
}

export const CUSTOMER_CATEGORY = {
    // mypr: {
    //     label: 'MyPR',
    //     code: 0
    // },
    mykad: {
        label: 'MyKad',
        code: 1
    },
}

export const STATES = {
    johor: {
        label: 'Johor',
        code: '01'
    },
    kedah: {
        label: 'Kedah',
        code: '02'
    },
    kelantan: {
        label: 'Kelantan',
        code: '03'
    },
    melaka: {
        label: 'Melaka',
        code: '04'
    },
    negeri_sembilan: {
        label: 'Negeri Sembilan',
        code: '05'
    },
    pahang: {
        label: 'Pahang',
        code: '06'
    },
    pulau_pinang: {
        label: 'Pulau Pinang',
        code: '07'
    },
    perak: {
        label: 'Perak',
        code: '08'
    },
    perlis: {
        label: 'Perlis',
        code: '09'
    },
    selangor: {
        label: 'Selangor',
        code: '10'
    },
    terengganu: {
        label: 'Terengganu',
        code: '11'
    },
    sabah: {
        label: 'Sabah',
        code: '12'
    },
    sarawak: {
        label: 'Sarawak',
        code: '13'
    },
    kl: {
        label: 'W. Persekutuan (KL)',
        code: '14'
    },
    labuan: {
        label: 'W. Persekutuan (Labuan)',
        code: '15'
    },
    putrajaya: {
        label: 'W. Persekutuan (Putrajaya)',
        code: '16'
    },
}

export const STATES_FULLNAME = {
    johor1: {
        label: 'Johor',
        code: '01'
    },
    johor2: {
        label: "Johor Darul Ta'zim'",
        code: '01'
    },
    kedah1: {
        label: 'Kedah',
        code: '02'
    },
    kedah2: {
        label: 'Kedah Darul Aman',
        code: '02'
    },
    kelantan1: {
        label: 'Kelantan',
        code: '03'
    },
    kelantan2: {
        label: 'Kelantan Darul Naim',
        code: '03'
    },
    melaka1: {
        label: 'Melaka',
        code: '04'
    },
    melaka2: {
        label: 'Melaka Darul Azim',
        code: '04'
    },
    negeri_sembilan1: {
        label: 'Negeri Sembilan',
        code: '05'
    },
    negeri_sembilan2: {
        label: 'Negeri Sembilan Darul Khusus',
        code: '05'
    },
    pahang1: {
        label: 'Pahang',
        code: '06'
    },
    pahang2: {
        label: 'Pahang Darul Makmur',
        code: '06'
    },
    pulau_pinang1: {
        label: 'Pulau Pinang',
        code: '07'
    },
    perak1: {
        label: 'Perak',
        code: '08'
    },
    perak2: {
        label: 'Perak Darul Ridzuan',
        code: '08'
    },
    perlis1: {
        label: 'Perlis',
        code: '09'
    },
    selangor1: {
        label: 'Selangor',
        code: '10'
    },
    selangor2: {
        label: 'Selangor Darul Ehsan',
        code: '10'
    },
    terengganu1: {
        label: 'Terengganu',
        code: '11'
    },
    terengganu2: {
        label: 'Terengganu Darul Iman',
        code: '11'
    },
    sabah1: {
        label: 'Sabah',
        code: '12'
    },
    sarawak1: {
        label: 'Sarawak',
        code: '13'
    },
    kl1: {
        label: 'W. Persekutuan (KL)',
        code: '14'
    },
    kl2: {
        label: 'Wilayah Persekutuan Kuala Lumpur',
        code: '14'
    },
    kl3: {
        label: 'Kuala Lumpur',
        code: '14'
    },
    labuan1: {
        label: 'Wilayah Persekutuan Labuan',
        code: '15'
    },
    labuan2: {
        label: 'W. Persekutuan (Labuan)',
        code: '15'
    },
    labuan3: {
        label: 'Labuan',
        code: '15'
    },
    putrajaya1: {
        label: 'W. Persekutuan (Putrajaya)',
        code: '16'
    },
    putrajaya2: {
        label: 'Wilayah Persekutuan Putrajaya',
        code: '16'
    },
    putrajaya3: {
        label: 'Putrajaya',
        code: '16'
    }
}

export const I2I_TRANSACTION_TYPES = [
    {
        id: 1,
        label: 'Owner to Buyer',
        image: individualToIndividual,
    },
    // {
    //     id: 4,
    //     label: 'Individual to Company',
    //     image: individualToCompany,
    //     // disabled: true,
    // },
    // {
    //     id: 3,
    //     label: 'Company to Company',
    //     image: companyToCompany,
    //     // disabled: true,
    // },
    // {
    //     id: 2,
    //     label: 'Company to Individual',
    //     image: companyToIndividual,
    // },

]

export const I2I_TRANSACTION_TYPES_2 = {
    1: {
        id: 1,
        label: "Owner to Buyer"
    },
    2: {
        id: 2,
        label: "Company to Individual"
    },
    3: {
        id: 3,
        label: "Company to Company"
    },
    4: {
        id: 4,
        label: "Individual to Company"
    },
}

export const OWNERSHIP_TYPES = [
    {
        id: 1,
        label: 'Malaysian',
        desc: 'MyKad',
        image: myKad,
        disabled: false,
    },
    // {
    //     id: 0,
    //     label: 'Permanent Resident',
    //     desc: 'MyPR',
    //     image: myPr,
    //     disabled: false,
    // },
    // {
    //     id: 4,
    //     label: 'Company',
    //     image: companyImage,
    //     disabled: true,
    //     /**
    //      * 23/3/2022 redirects user to a new, external page. If present, id doesn't matter.
    //      * TODO get overridingUrl from myeg
    //      */
    //     overridingUrl: config.legacyMyegUrl
    // },
    {
        id: 9,
        label: 'Member',
        image: memberImage,
        disabled: true,
    },
    // {
    //     id: '2',
    //     label: 'Police',
    //     image: PLACEHOLDER_IMAGE,
    //     disabled: true,
    // },
    // {
    //     id: '3',
    //     label: 'Military',
    //     image: PLACEHOLDER_IMAGE,
    //     disabled: true,
    // },
    // {
    //     id: '5',
    //     label: 'Government',
    //     image: PLACEHOLDER_IMAGE,
    //     disabled: true,
    // },
    // {
    //     id: "6",
    //     label: 'Statutory Body',
    //     image: PLACEHOLDER_IMAGE,
    //     disabled: true,
    // },
    // {
    //     id: '7',
    //     label: 'Malaysia\'s Birth Certificate',
    //     image: PLACEHOLDER_IMAGE,
    //     disabled: true,
    // },
    // {
    //     id: '8',
    //     label: 'Temporary MyKad Receipt',
    //     image: PLACEHOLDER_IMAGE,
    //     disabled: true,
    // },
]

/**
 * Used at OUT module
 */
export const OUT_TRANSACTION_TYPES_IDS = {
    I2I: 1,
    D2I: 2,
    D2C: 3,
    I2C: 4,
    M2M: 5,
    // M2MV2: 6,
}

export const OUT_TRANSACTION_TYPES = {
    [OUT_TRANSACTION_TYPES_IDS.I2I]: {
        id: OUT_TRANSACTION_TYPES_IDS.I2I,
        label: 'Owner to Buyer',

        disabled: false,
    },
    [OUT_TRANSACTION_TYPES_IDS.D2I]: {
        id: OUT_TRANSACTION_TYPES_IDS.D2I,
        label: 'Dealer to Individual',
        image: citizenOfMalaysiaImage,
        disabled: false,
    },
    [OUT_TRANSACTION_TYPES_IDS.D2C]: {
        id: OUT_TRANSACTION_TYPES_IDS.D2C,
        label: 'Dealer to Company',
        image: companyImage,
        disabled: false,
        overridingUrl: config.legacyMyegUrl
    },
    [OUT_TRANSACTION_TYPES_IDS.I2C]: {
        id: OUT_TRANSACTION_TYPES_IDS.I2C,
        label: 'Individual to Company',
        image: memberImage,
        disabled: false,

    },
    [OUT_TRANSACTION_TYPES_IDS.M2M]: {
        id: OUT_TRANSACTION_TYPES_IDS.M2M,
        label: 'Member to Member',
        image: memberImage,
        disabled: true,
    },
    // [OUT_TRANSACTION_TYPES_IDS.M2MV2]: {
    //     id: OUT_TRANSACTION_TYPES_IDS.M2MV2,
    //     label: 'Member to Member (BULK ENTRY)',
    //     image: memberImage,
    //     disabled: true,
    // },
}

export const TRANSFER_PAYMENT_STATUSES = {
    PENDING: 'P',
    SUCCESS: 'S',
    REFUNDED: 'R',
    FAIL: 'F',
}

/**
 * 22/1/2022 daniel.kwok
 * Hard coded HQ branch id to be 0
 * Ideally value should be from api
 */
export const HQ_BRANCH = {
    id: 0,
    is_hq: true,
    branchName: 'HQ branch'
}

export const PLACEHOLDER_IMAGE = 'https://image.shutterstock.com/image-vector/ui-image-placeholder-wireframes-apps-260nw-1037719204.jpg'
export const WALLET_MINIMUM_BALANCE = 50

export const JPJ_INQUIRY_STATUS = {
    BLACKLISTED: 'BLACKLISTED',
    OWNERSHIP_CLAIM: 'OWNERSHIP_CLAIM',
    CONDITION: 'CONDITION',
    USAGE: 'USAGE',
}

export const VEHICLE_TYPES = [
    {
        id: 1,
        vehicleType: "CAR",
        serviceName: "Temporary Transfer System",
    },
    {
        id: 2,
        vehicleType: "MOTOR",
        serviceName: "Temporary Transfer System",
    },
    {
        id: 3,
        vehicleType: "CAR",
        serviceName: "Permanent Transfer System",
    },
    {
        id: 4,
        vehicleType: "MOTOR",
        serviceName: "Permanent Transfer System",
    },
    {
        id: 5,
        vehicleType: "COMMERCIAL",
        serviceName: "Temporary Transfer System",
    },
    {
        id: 6,
        vehicleType: "MOTOR OKU",
        serviceName: "Temporary Transfer System",
    },
    {
        id: 7,
        vehicleType: "CAR OKU",
        serviceName: "Temporary Transfer System",
    },
]

export const OTHER_TRANSACTIONS_LINK = {
    INSURANCE: 'https://compare.myeg.com.my',
    ROADTAX: 'https://www.myeg.com.my/services/jpj',
}

export const APP_CONST = {
    USE_OTP: true
}

export const STATES_CODES = {
    '01': {
        label: 'Johor',
        code: '01'
    },
    '21': {
        label: 'Johor',
        code: '01'
    },
    '22': {
        label: 'Johor',
        code: '01'
    },
    '23': {
        label: 'Johor',
        code: '01'
    },
    '24': {
        label: 'Johor',
        code: '01'
    },
    '02': {
        label: 'Kedah',
        code: '02'
    },
    '25': {
        label: 'Kedah',
        code: '02'
    },
    '26': {
        label: 'Kedah',
        code: '02'
    },
    '27': {
        label: 'Kedah',
        code: '02'
    },
    '03': {
        label: 'Kelantan',
        code: '03'
    },
    '28': {
        label: 'Kelantan',
        code: '03'
    },
    '29': {
        label: 'Kelantan',
        code: '03'
    },
    '04': {
        label: 'Melaka',
        code: '04'
    },
    '30': {
        label: 'Melaka',
        code: '04'
    },
    '05': {
        label: 'Negeri Sembilan',
        code: '05'
    },
    '31': {
        label: 'Negeri Sembilan',
        code: '05'
    },
    '59': {
        label: 'Negeri Sembilan',
        code: '05'
    },
    '06': {
        label: 'Pahang',
        code: '06'
    },
    '32': {
        label: 'Pahang',
        code: '06'
    },
    '33': {
        label: 'Pahang',
        code: '06'
    },
    '07': {
        label: 'Pulau Pinang',
        code: '07'
    },
    '34': {
        label: 'Pulau Pinang',
        code: '07'
    },
    '35': {
        label: 'Pulau Pinang',
        code: '07'
    },
    '08': {
        label: 'Perak',
        code: '08'
    },
    '36': {
        label: 'Perak',
        code: '08'
    },
    '37': {
        label: 'Perak',
        code: '08'
    },
    '38': {
        label: 'Perak',
        code: '08'
    },
    '39': {
        label: 'Perak',
        code: '08'
    },
    '09': {
        label: 'Perlis',
        code: '09'
    },
    '40': {
        label: 'Perlis',
        code: '09'
    },
    '10': {
        label: 'Selangor',
        code: '10'
    },
    '41': {
        label: 'Selangor',
        code: '10'
    },
    '42': {
        label: 'Selangor',
        code: '10'
    },
    '43': {
        label: 'Selangor',
        code: '10'
    },
    '44': {
        label: 'Selangor',
        code: '10'
    },
    '11': {
        label: 'Terengganu',
        code: '11'
    },
    '45': {
        label: 'Terengganu',
        code: '11'
    },
    '46': {
        label: 'Terengganu',
        code: '11'
    },
    '12': {
        label: 'Sabah',
        code: '12'
    },
    '47': {
        label: 'Sabah',
        code: '12'
    },
    '48': {
        label: 'Sabah',
        code: '12'
    },
    '49': {
        label: 'Sabah',
        code: '12'
    },
    '13': {
        label: 'Sarawak',
        code: '13'
    },
    '50': {
        label: 'Sarawak',
        code: '13'
    },
    '51': {
        label: 'Sarawak',
        code: '13'
    },
    '52': {
        label: 'Sarawak',
        code: '13'
    },
    '53': {
        label: 'Sarawak',
        code: '13'
    },
    '14': {
        label: 'W. Persekutuan (KL)',
        code: '14'
    },
    '54': {
        label: 'W. Persekutuan (KL)',
        code: '14'
    },
    '55': {
        label: 'W. Persekutuan (KL)',
        code: '14'
    },
    '56': {
        label: 'W. Persekutuan (KL)',
        code: '14'
    },
    '57': {
        label: 'W. Persekutuan (KL)',
        code: '14'
    },
    '15': {
        label: 'W. Persekutuan (Labuan)',
        code: '15'
    },
    '58': {
        label: 'W. Persekutuan (Labuan)',
        code: '15'
    },
    '16': {
        label: 'W. Persekutuan (Putrajaya)',
        code: '16'
    },
}

export const TRANSFER_TYPES = [
    {
        id: 1,
        txn_type: OUT_TRANSACTION_TYPES_IDS.D2I,
        label: 'MyKad',
        desc: 'Dealer to Individual',
        image: myKad,
        disabled: false,
    },
    // {
    //     id: 0,
    //     txn_type: OUT_TRANSACTION_TYPES_IDS.D2I,
    //     label: 'MyPR',
    //     desc: 'Dealer to Individual',
    //     image: myPr,
    //     disabled: false,
    // },
    {
        id: 2,
        txn_type: OUT_TRANSACTION_TYPES_IDS.M2M,
        label: 'Member to Member',
        image: memberImage,
        disabled: true,
    },
    // {
    //     id: 3,
    //     txn_type: OUT_TRANSACTION_TYPES_IDS.M2MV2,
    //     label: 'Member to Member (BULK ENTRY)',
    //     image: memberImage,
    //     disabled: true,
    // },
]

export const SEARCH_TYPES = {
    VEHICLE_NUMBER: {
        label: 'Vehicle Number',
        key: 'vehiclenumber'
    },
    ROC_NUMBER: {
        label: 'ROC Number',
        key: 'rocnumber'
    },
    NRIC: {
        label: 'NRIC',
        key: 'nric'
    },
    TRANSACTION_DATE: {
        label: 'Transaction Date',
        key: 'createddate'
    },
    // TRANSACTION_STATUS: {
    //     label: 'Transaction Status',
    //     key: 'transactionstatus'
    // }
}

export const M2M_SEARCH_TYPES = {
    VEHICLE_NUMBER: {
        label: 'Vehicle Number',
        key: 'vehiclenumber'
    },
    BUYER_NAME: {
        label: 'Buyer Name',
        key: 'buyerName'
    },
    SELLER_NAME: {
        label: 'Seller Name',
        key: 'sellerName'
    },
    TRANSACTION_DATE: {
        label: 'Transaction Date',
        key: 'createddate'
    },
    // TRANSACTION_STATUS: {
    //     label: 'Transaction Status',
    //     key: 'transactionstatus'
    // }
}

export const NRIC_TYPES = {
    SELLER: {
        label: 'SELLER NRIC',
        key: 'sellernric'
    },
    BUYER: {
        label: 'BUYER NRIC',
        key: 'buyernric'
    }
}

export const iv = '31FEEE9D8281D6489EC295E0A7436FD8';

export const SEARCH_QUERY_TOOLTIP = 'Example: ABC1234 (no spacing)';
export const SEARCH_EMPTY_TOOLTIP = (searchTerm) => `<b>No results found</b><br/>No search results found for "${searchTerm}". Please try a different keyword."`
export const ERROR_MESSAGE_TIMEOUT = 'System taking too long to load. Please try again.';
export const ERROR_MESSAGE_AUDIT_SERVER_ERROR = 'Internal server error encountered while generating the audit log report. Please try again later or contact support for assistance.';
export const ERROR_MESSAGE_INTERNAL_SERVER_ERROR = 'Internal server error encountered. Please try again later or contact support for assistance.';
export const ERROR_MESSAGE_PENDING_LIST_VERSION = (version) => `Transaction Blocked: Pending transactions cannot cross versions. Please complete transactions in the corresponding version <${version}>.`;
export const ERROR_MESSAGE_M2M = (transferId) => `System error with transfer ID: ${transferId}. For more details, Please email twocar@myeg.com.my`

export const BUTTON_RETURN_PENDING_TRANSACTION = 'RETURN TO PENDING TRANSACTION';
export const ALERT_AUTHORITY_MESSAGE = '<b>Request</b> a user with <b>a higher authority level</b> to complete payment for the transaction.';
export const ALERT_AUTHORITY_APPROVAL_MESSAGE = '<b>Request</b> a user with <b>a higher authority level</b> to complete approval for the transaction.';
export const BUTTON_JPJ_SUBMISSION = 'JPJ Submission';
export const BUTTON_RESUBMIT = 'Resubmit';

export const ERROR_MESSAGE_LOGIN_NOT_ACTIVATED =  'Account not activated<br/>Your account is not activated. Please check your email for the activation link to complete your registration. If you did not receive the email, contact support at <a href="mailto:twocar@myeg.com.my">twocar@myeg.com.my</a>'