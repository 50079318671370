import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Breadcrumb, Button, Card, Col, Select, Row, Table, message, Image, Form } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { searchAptTransactionsReport } from "../../../services/api";
import { SessionContext } from "../../../App";
import arrow_back from "../../../assets/arrow_back.svg";
import export_xls from "../../../assets/export_xls_filled.svg";
import ReactExport from "react-export-excel";

const { Option } = Select;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function APTReportPage() {
	const history = useHistory();
	const { company, branch } = useContext(SessionContext);
	const [dataSource, setDataSource] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [status, setStatus] = useState("Pending_2_Weeks"); // Default selection
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		total: 0,
	});

	// // Save branch and company data to localStorage when available
	// useEffect(() => {
	// 	if (branch?.id && company?.coRegNo) {
	// 		localStorage.setItem("branchId", branch.id);
	// 		localStorage.setItem("companyCoRegNo", company.coRegNo);
	// 	}
	// }, [branch, company]);

	// // Retrieve branch and company data from localStorage on page load
	// const branchId = branch?.id || localStorage.getItem("branchId");
	// const companyCoRegNo = company?.coRegNo || localStorage.getItem("companyCoRegNo");

	useEffect(() => {
		if (company?.coRegNo) {
			fetchAPTReportData();
		}
	}, [branch, company, status, pagination.current]);


	const fetchAPTReportData = async (isFetchingAll = false) => {
		setIsLoading(true);

		let allData = [];

		try {
			while (true) {
				const response = await searchAptTransactionsReport(
					branch.id,
					company.coRegNo,
					pagination.pageSize,
					pagination.current,
					status
				);

				if (Array.isArray(response.data) && response.data.length > 0) {
					const formattedData = response.data.map((log, index) => ({
						...log,
						key: (pagination.current - 1) * pagination.pageSize + index + 1, // Calculate the correct row number
					}));
					allData = allData.concat(formattedData);

					// If fetching paginated data for the table, stop after the first batch
					if (!isFetchingAll) {
						setDataSource(formattedData);
						setPagination((prev) => ({
							...prev,
							total: response.count,
						}));
						break;
					}

					pagination.current++;
				} else {
					break; // Exit loop if no more data
				}
			}

			if (isFetchingAll) {
				return allData;
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			message.error("Error fetching data.");
			if (!isFetchingAll) setDataSource([]);
		} finally {
			setIsLoading(false);
		}
	};


	const handleStatusChange = (value) => {
		setStatus(value); // Update status state
		setPagination((prev) => ({ ...prev, current: 1 })); // Reset pagination to the first page
	};



	const exportXLSButton = () => {
		const handleExport = async () => {
			const allData = await fetchAPTReportData(true);

			if (allData.length > 0) {
				// Preprocess data to apply date formatting
				const processedData = allData.map((item) => ({
					...item,
					transferDate: moment(item.transferDate).format("DD/MMM/YYYY hh:mm:ss"),
					transactionExpiryDate: moment(item.transactionExpiryDate).format("DD/MMM/YYYY hh:mm:ss"),
				}));

				const columns = [
					{ title: "No", dataIndex: "key", key: "key" },
					{ title: "Vehicle Number", dataIndex: "carRegistrationNo" },
					{ title: "Transaction Date", dataIndex: "transferDate" },
					{ title: "Transaction Expiry Date", dataIndex: "transactionExpiryDate" },
					{ title: "Company Name", dataIndex: "companyName" },
					{ title: "Company Registration Number", dataIndex: "dealerCompanyROC" },
					{ title: "Approved User Name", dataIndex: "approvedByAccount" },
					...(status !== "Expired"
						? [
							{
								title: "Day Count",
								dataIndex: "expiryDateCountdown",
							},
						]
						: []),
				];

				const ExcelFileComponent = (
					<ExcelFile filename="APT_Listing_Report">
						<ExcelSheet data={processedData} name="APT Listing Report">
							{columns.map((col) => (
								<ExcelColumn key={col.title} label={col.title} value={col.dataIndex} />
							))}
						</ExcelSheet>
					</ExcelFile>
				);

				// Trigger Excel download
				const exportWrapper = document.createElement("div");
				document.body.appendChild(exportWrapper);
				ReactDOM.render(ExcelFileComponent, exportWrapper);
				exportWrapper.firstChild.click();
				document.body.removeChild(exportWrapper);
			}
		};

		return (
			<Button
				shape="round"
				className="add-new-user-button export-report"
				type="primary"
				style={{ marginBottom: 10 }}
				onClick={handleExport}
				disabled={dataSource.length === 0} // Disable the button if no data
			>
				<Image
					src={export_xls}
					preview={false}
					width={30}
					height={30}
					style={{ paddingRight: 10 }}
				/>
				Export report
			</Button>
		);
	};


	const columns = [
		{ title: "No", dataIndex: "key", key: "key" },
		{ title: "Vehicle Number", dataIndex: "carRegistrationNo" },
		{
			title: "Transaction Date",
			dataIndex: "transferDate",
			render: (transferDate) => moment(transferDate).format("DD/MMM/YYYY hh:mm:ss"),
		},
		{
			title: "Transaction Expiry Date",
			dataIndex: "transactionExpiryDate",
			render: (transactionExpiryDate) => moment(transactionExpiryDate).format("DD/MMM/YYYY hh:mm:ss"),
		},
		{ title: "Company Name", dataIndex: "companyName" },
		{ title: "Company Registration Number", dataIndex: "dealerCompanyROC" },
		{ title: "Approved User Name", dataIndex: "approvedByAccount" },
		...(status !== "Expired"
			? [
				{
					title: "Day Count",
					dataIndex: "expiryDateCountdown",
				},
			]
			: []),
	];

	return (
		<>
			<div className="page-content">
				<Breadcrumb separator=">" style={{ marginTop: "60px" }}>
					<Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item href="/reporting">Reporting</Breadcrumb.Item>
					<Breadcrumb.Item>APT Report Listing</Breadcrumb.Item>
				</Breadcrumb>

				<PageHeader title="APT Report Listing" />

				<Card style={{ borderRadius: 8 }}>
					<Row className="filter-form-row" gutter={[20, 0]} >
						<Col span={6}>
							<Form.Item className="apt-form-item" label="APT Type" colon={false}>
								<Select
									value={status}
									onChange={handleStatusChange}
									className="status-select"
									style={{ width: "100%", textAlign: "start" }}
								>
									<Option value="Pending_2_Weeks" title="Temporary Transfer and transactions need to proceed with APT in next 2 weeks">
										STMS Transaction Expiring Soon
									</Option>
									<Option value="Expired">
										Waiting for APT
									</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Card>


				<Row style={{ display: "flex", justifyContent: "end" }}>{exportXLSButton()}</Row>

				<Table
					className="report-table"
					dataSource={dataSource}
					columns={columns}
					loading={isLoading}
					pagination={{
						current: pagination.current,
						pageSize: pagination.pageSize,
						total: pagination.total,
						onChange: (page) => setPagination((prev) => ({ ...prev, current: page })),
						showSizeChanger: false, // Remove the page size dropdown
					}}
				/>

				<Row
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Button
						className="back-button buttons"
						shape="round"
						onClick={() => {
							history.goBack();
						}}
					>
						<Image src={arrow_back} preview={false} />
						BACK
					</Button>
					{exportXLSButton()}
				</Row>
			</div>

			<style>
				{`
					.site-page-header { 
						padding-left: 0px;
						padding-bottom: 15px;
					}
					.ant-select-arrow {
						color: #2B61C4;
					}
					.back-button {
						margin: 20px 0px;
					}
					.export-report.add-new-user-button {
						// background: #e7e7e7;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 127px !important;  
						padding: 0px 30px;
						box-shadow: none;
					}
					.ant-card { 
						margin-bottom: 25px;
					}
					.filter-form-row {
						display: flex; 
						align-items: start;
					}
					.status-select {  
						height: 50px;
					}
					.report-table { 
						margin-top: 20px;
					}
					.filter-form-row .ant-form-item-label{
						text-align: start;
					}
					.filter-form-row label { 
						font: italic 16px / 22px "Open Sans";
						margin-bottom: 10px;
					}
					.apt-form-item .ant-form-item-row{
						flex-direction: column;
					}
					
        `}
			</style>
		</>
	);
}
