import {
    Breadcrumb,
    Button,
    Col,
    DatePicker,
    Form,
    Image,
    message,
    Row,
    Select,
    Table,
    Input,
    Tooltip,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import arrow_back from "../../../assets/arrow_back.svg";
import export_xls from "../../../assets/export_xls.svg";
import ReactExport from "react-export-excel";
import { DownloadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
    getInPaymentReportCount,
    getInPaymentReportList,
    getOutPaymentReportCount,
    getOutPaymentReportList,
} from "../../../services/api";
import { SessionContext } from "../../../App";
import "./index.less";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { SEARCH_QUERY_TOOLTIP } from "../../../constants";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function PaymentReportPage() {
    const history = useHistory();

    const { company, branch } = useContext(SessionContext);

    const [filterForm] = useForm();
    const [typeForm] = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [initialDataSource, setInitialDataSource] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [showTotalResult, setShowTotalResult] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showPaymentDateSearch, setShowPaymentDateSearch] = useState(true);
    const [showTransactionDateSearch, setShowTransactionDatetSearch] =
        useState(false);

    // pagination
    // const [totalCount, setTotalCount] = useState(0);
    const [initialTotalCount, setInitialTotalCount] = useState(0);
    const [selectedType, setSelectedType] = useState("ALL");
    const [fromCreatedDate, setFromCreatedDate] = useState(null);
    const [toCreatedDate, setToCreatedDate] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    // const [isInitialRender, setIsInitialRender] = useState(true);

    const [transactionDate, setTransactionDate] = useState(null);

    const TRANSACTION_TYPE_LABEL = {
        ALL: "ALL",
        IN: "IN",
        OUT: "OUT",
        M2M: "M2M",
        I2I: "I2I",
        APT: "APT",
    };

    const columns = [
        {
            title: "Payment Date / Time",
            dataIndex: "createdDate",
            key: "createdDate",
            render: (createdDate) =>
                dayjs(createdDate).format("DD-MMM-YYYY hh:mm:ss A"),
            sorter: (a, b) => {
                let formattedA = dayjs(new Date(a.createdDate));
                let formattedB = dayjs(new Date(b.createdDate));

                return formattedA.diff(formattedB);
            },
            defaultSortOrder: "descend",
        },
        {
            title: "Vehicle Number",
            dataIndex: "carRegistrationNo",
            key: "carRegistrationNo",
            sorter: (a, b) =>
                a.carRegistrationNo.localeCompare(b.carRegistrationNo),
            // width: 450,
        },
        {
            title: (
                <>
                    Buyer Name &<br />
                    NRIC Number
                </>
            ),
            key: "name",
            sorter: (a, b) => {
                a.name = a.name ? a.name : "";
                b.name = b.name ? b.name : "";
                return a.name.localeCompare(b.name);
            },
            render: (result) => {
                return (
                    <>
                        {result.name && result.name !== "" ? result.name : "-"}{" "}
                        <br />
                        {result.identificationNo &&
                        result.identificationNo !== ""
                            ? result.identificationNo
                            : "-"}
                    </>
                );
            },
            width: 200,
        },
        {
            title: "Receipt Number",
            dataIndex: "receiptNo",
            key: "receiptNo",
            sorter: (a, b) => a.receiptNo.localeCompare(b.receiptNo),
        },
        {
            title: <>Transaction & Vehicle Type</>,
            key: "transactionType",
            sorter: (a, b) =>
                a.transactionType.localeCompare(b.transactionType), //WIP
            render: (result) => {
                return (
                    <>
                        {result.serviceType && result.serviceType !== ""
                            ? result.serviceType
                            : "-"}{" "}
                        <br />
                        {result.transactionType && result.transactionType !== ""
                            ? result.transactionType
                            : "-"}
                    </>
                );
            },
            // width: 450,
        },
        {
            title: "Login ID",
            dataIndex: "loginId",
            key: "loginId",
            sorter: (a, b) => a.loginId.localeCompare(b.loginId),
            render: (result) => {
                return result && result !== "" ? result : "-";
            },
            // width: 450,
        },
        {
            title: "Amount",
            dataIndex: "totalAmount",
            key: "totalAmount",
            sorter: (a, b) => {
                return a.totalAmount - b.totalAmount;
            },
            render: (result) => {
                let formattedAmount = result;
                if (result) {
                    // Ensure that the number has at least two decimal places
                    formattedAmount = Number(result).toFixed(2);
                }
                return formattedAmount;
            },
            // sorter: (a, b) => a.totalAmount.toString.localeCompare(b.totalAmount),
            // width: 450,
        },
        {
            title: "Payment Status",
            dataIndex: "paymentStatus",
            key: "paymentStatus",
            sorter: (a, b) => a.paymentStatus.localeCompare(b.paymentStatus),
        },
    ];

    const pdf_columns = [
        {
            title: "Payment Date / Time",
            dataIndex: "createdDate",
        },
        {
            title: "Vehicle Number",
            dataIndex: "carRegistrationNo",
        },
        {
            title: "Buyer Name & NRIC Number",
            dataIndex: ["name", "identificationNo"],
        },
        {
            title: "Receipt Number",
            dataIndex: "receiptNo",
        },
        {
            title: "Transaction & Vehicle Type",
            dataIndex: ["serviceType", "transactionType"],
        },
        {
            title: "Login ID",
            dataIndex: "loginId",
        },
        {
            title: "Amount",
            dataIndex: "totalAmount",
        },
        {
            title: "Payment Status",
            dataIndex: "paymentStatus",
        },
    ];

    const onChangeSearchBy = () => {
        handleFilterReset();

        const { filterBy } = filterForm.getFieldsValue();
        if (
            filterBy === "vehicleNumber" ||
            filterBy === "sellerNricOrCompany" ||
            filterBy === "buyerNricOrCompany"
        ) {
            setShowSearch(true);
            setShowPaymentDateSearch(false);
            setShowTransactionDatetSearch(false);
            // handleFilterReset()
            setIsLoading(false);
            return true;
        }

        if (filterBy === "paymentDate") {
            setShowPaymentDateSearch(true);

            setShowSearch(false);
            setShowTransactionDatetSearch(false);
            setDataSource(initialDataSource);
            // setTotalCount(initialTotalCount);
            return false;
        }

        if (filterBy === "transactiontDate") {
            setShowTransactionDatetSearch(true);

            setShowSearch(false);
            setShowPaymentDateSearch(false);
        }
    };

    const handleFilter = async () => {
        // Handling debounce input (after completing input)
        const { filterBy } = filterForm.getFieldsValue();

        if (filterBy === "vehicleNumber") {
            getPaymentReport(searchTerm, false);
        }
    };

    const handleFilterReset = async () => {
        filterForm.setFieldsValue({
            from: undefined,
            to: undefined,
            transactionDate: "",
        });

        typeForm.resetFields();

        setSelectedType("ALL");
        setFromCreatedDate(null);
        setToCreatedDate(null);
        setTransactionDate(null)
        setShowTotalResult(false);
        setSearchTerm("");
        setIsLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 500));
        setDataSource(initialDataSource);
        // setTotalCount(initialTotalCount);
        setIsLoading(false);
    };

    const paymentReportFooter = () => {
        return (
            <Row>
                <Button
                    className="delete-button buttons footer-button"
                    icon={<DownloadOutlined style={{ fontSize: 16 }} />}
                    shape="round"
                    onClick={handleDownloadPDF}
                >
                    Download
                </Button>
                <ExcelFile
                    element={
                        <Button
                            className="delete-button buttons footer-button"
                            shape="round"
                        >
                            <Image
                                src={export_xls}
                                preview={false}
                                width={30}
                                height={25}
                                style={{ paddingRight: 10 }}
                            />
                            Export
                        </Button>
                    }
                    filename="Payment_Report"
                >
                    <ExcelSheet
                        data={dataSource.filter((item) =>
                            selectedKeys.includes(item.key)
                        )}
                        name="Payment Report"
                    >
                        <ExcelColumn
                            label="Payment Date / Time"
                            value={(col) => {
                                return dayjs(col.createdDate).format(
                                    "DD-MMM-YYYY hh:mm:ss A"
                                );
                            }}
                        />
                        <ExcelColumn
                            label="Vehicle Number"
                            value="carRegistrationNo"
                        />
                        <ExcelColumn
                            label="⁠Buyer Name & NRIC Number"
                            value={(result) => {
                                let recordName = result.name ?? "";
                                let recordIdentificationNo =
                                    result.identificationNo ?? "";
                                return (
                                    recordName + " / " + recordIdentificationNo
                                );
                                // return result.name + " / " + result.identificationNo
                            }}
                        />
                        <ExcelColumn label="Receipt Number" value="receiptNo" />
                        <ExcelColumn
                            label="⁠Transaction & Vehicle Type"
                            value={(result) => {
                                return (
                                    result.serviceType +
                                    " / " +
                                    result.transactionType
                                );
                            }}
                        />
                        <ExcelColumn label="Login ID" value="loginId" />
                        <ExcelColumn
                            label="Amount"
                            value={(result) => {
                                let formattedAmount = result.totalAmount;
                                if (result.totalAmount) {
                                    formattedAmount = Number(
                                        result.totalAmount
                                    ).toFixed(2);
                                }
                                return formattedAmount;
                            }}
                        />
                        <ExcelColumn
                            label="Payment Status"
                            value="paymentStatus"
                        />
                        {/* likely will need to have a custom value rendering for status too */}
                        {/* <ExcelColumn label="Receipt Number" value="paymentStatus" />
						<ExcelColumn label="Payment ID" value="paymentId" /> */}
                    </ExcelSheet>
                </ExcelFile>
            </Row>
        );
    };

    const getSelectedType = (selectedType, type) => {
        if (selectedType === TRANSACTION_TYPE_LABEL.ALL) {
            return selectedType;
        } else if (
            (type === "OUT" &&
                selectedType !== TRANSACTION_TYPE_LABEL.I2I &&
                selectedType !== TRANSACTION_TYPE_LABEL.OUT) ||
            (type === "IN" &&
                selectedType !== TRANSACTION_TYPE_LABEL.IN &&
                selectedType !== TRANSACTION_TYPE_LABEL.M2M &&
                selectedType !== TRANSACTION_TYPE_LABEL.APT)
        ) {
            return "";
        } else {
            return selectedType;
        }
    };

    const getPaymentReport = (searchTerm, isInitialRender) => {
        setIsLoading(true);
        const formattedFromString = fromCreatedDate
            ? fromCreatedDate.format("YYYY-MM-DD")
            : "";
        const formattedToString = toCreatedDate
            ? toCreatedDate.format("YYYY-MM-DD")
            : "";

        const formattedTransactionDate = transactionDate
            ? transactionDate.format("YYYY-MM-DD")
            : "";

        const { filterBy } = filterForm.getFieldsValue();

        Promise.all([
            getOutPaymentReportCount(
                branch.id,
                company.coRegNo,
                formattedFromString,
                formattedToString,
                filterBy == "vehicleNumber" ? searchTerm : "", // carRegistrationNo
                getSelectedType(selectedType, "OUT"),
                filterBy == "buyerNricOrCompany" ? searchTerm : "", //buyerNric
                filterBy == "sellerNricOrCompany" ? searchTerm : "", //sellerNric
                formattedTransactionDate
            ),

            // geti2iPaymentReportCount(branch.id, company.coRegNo),
            // getAptPaymentReportCount(company.coRegNo),
            getInPaymentReportCount(
                company.coRegNo,
                formattedFromString,
                formattedToString,
                filterBy == "vehicleNumber" ? searchTerm : "", // carRegistrationNo
                getSelectedType(selectedType, "IN"),
                filterBy == "buyerNricOrCompany" ? searchTerm : "", //buyerNric
                filterBy == "sellerNricOrCompany" ? searchTerm : "", //sellerNric
                formattedTransactionDate
            ),
        ])
            .then((results) => {
                // let [outCount, i2iCount, aptCount, inCount] = results;
                // const resultCount = outCount.count + i2iCount.count + aptCount.count + inCount.count;
                let [outCount, inCount] = results;
                const resultCount = outCount.count + inCount.count;
                // setTotalCount(resultCount);
                isInitialRender && setInitialTotalCount(resultCount);

                Promise.all([
                    getOutPaymentReportList(
                        branch.id,
                        company.coRegNo,
                        outCount.count,
                        1,
                        formattedFromString,
                        formattedToString,
                        filterBy == "vehicleNumber" ? searchTerm : "", // carRegistrationNo
                        getSelectedType(selectedType, "OUT"),
                        filterBy == "buyerNricOrCompany" ? searchTerm : "", //buyerNric
                        filterBy == "sellerNricOrCompany" ? searchTerm : "", //sellerNric
                        formattedTransactionDate
                    ),
                    // geti2iPaymentReportList(branch.id, company.coRegNo, i2iCount.count, 1),
                    // getAptPaymentReportList(company.coRegNo, aptCount.count, 1),
                    getInPaymentReportList(
                        company.coRegNo,
                        inCount.count,
                        1,
                        formattedFromString,
                        formattedToString,
                        filterBy == "vehicleNumber" ? searchTerm : "", // carRegistrationNo
                        getSelectedType(selectedType, "IN"),
                        filterBy == "buyerNricOrCompany" ? searchTerm : "", //buyerNric
                        filterBy == "sellerNricOrCompany" ? searchTerm : "", //sellerNric
                        formattedTransactionDate
                    ),
                ])
                    .then((results) => {
                        // let [outs, i2is, apts, ins] = results;

                        // const mappedResult = [...outs, ...i2is, ...apts, ...ins].map((item, index) => {
                        // 	item.key = index + 1;
                        // 	return item;
                        // });

                        let [outs, ins] = results;

                        let mappedResult = [...outs, ...ins].map(
                            (item, index) => {
                                item.key = index + 1;
                                return item;
                            }
                        );

                        //Default sort on init b created date
                        mappedResult = mappedResult.sort((a, b) => {
                            return a?.createdDate < b?.createdDate ? 1 : -1;
                        });

                        setDataSource(mappedResult);
                        isInitialRender && setInitialDataSource(mappedResult);
                        setIsLoading(false);
                    })
                    .catch((err) => err && message.error(err));
            })
            .catch((err) => err && message.error(err))
            .finally(() => setSelectedKeys([]));
    };

    useEffect(() => {
        handleFilter();
        // setIsInitialRender(false);
    }, [selectedType]);

    useEffect(() => {
        if (!company?.id || parseInt(branch?.id) >= 0 == false) return null;
        getPaymentReport("", true);
    }, [company, branch]);

    useEffect(() => {
        if (transactionDate && transactionDate != "") {
            try {
                getPaymentReport("", false);
            } catch (error) {
                setDataSource(initialDataSource);
                // setTotalCount(initialDataSource.length);
            }
        }
    }, [transactionDate]);

    useEffect(async () => {
        const from = dayjs(fromCreatedDate);
        const to = toCreatedDate ? dayjs(toCreatedDate) : dayjs();

        if (fromCreatedDate && toCreatedDate && from && to) {            
            try {
                getPaymentReport()
            } catch (error) {
                setDataSource(initialDataSource);
                // setTotalCount(initialDataSource.length);
            }
        } 
    }, [fromCreatedDate, toCreatedDate]);

    function arraysEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false; // Different lengths, can't be equal
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false; // Elements differ
            }
        }
        return true; // ALL elements match
    }

    // Function to generate PDF and download
    const handleDownloadPDF = () => {
        const selectedData = dataSource.filter((record) =>
            selectedKeys.includes(record.key)
        );

        const doc = new jsPDF();
        doc.text("Payment Report", 10, 10);

        // Convert data to a 2D array
        const tableData = selectedData.map((record) =>
            pdf_columns.map((column) => {
                if (column.dataIndex === "createdDate") {
                    return dayjs(record[column.dataIndex]).format(
                        "DD-MMM-YYYY hh:mm:ss A"
                    );
                } else if (
                    arraysEqual(column.dataIndex, ["name", "identificationNo"])
                ) {
                    let recordName = record.name ?? "";
                    let recordIdentificationNo = record.identificationNo ?? "";
                    return recordName + "\n" + recordIdentificationNo;
                } else if (
                    arraysEqual(column.dataIndex, [
                        "serviceType",
                        "transactionType",
                    ])
                ) {
                    return record.serviceType + "\n" + record.transactionType;
                } else if (
                    column.dataIndex === "totalAmount" &&
                    record[column.dataIndex]
                ) {
                    return Number(record[column.dataIndex]).toFixed(2);
                }
                return record[column.dataIndex];
            })
        );

        // Add table to PDF
        doc.autoTable({
            head: [pdf_columns.map((column) => column.title)],
            body: tableData,
            columnStyles: {
                // Define column styles here
                0: { cellWidth: 24 },
                1: { cellWidth: 20 },
                2: { cellWidth: 27 },
                3: { cellWidth: 20 },
                4: { cellWidth: 23 },
                6: { cellWidth: 20 },
                7: { cellWidth: 18 },
            },
        });

        doc.save("Payment_Report.pdf");
    };

    const handleSearch = async () => {
        const { searchTerm } = filterForm.getFieldsValue();
        let value = searchTerm
            ? searchTerm.toUpperCase().replace(/\s/g, "")
            : "";
        setSearchTerm(value);

        getPaymentReport(value, false);
    };

    return (
        <>
            <div className="page-content">
                <Breadcrumb separator=">" style={{ marginTop: "60px" }}>
                    <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item href="/reporting">
                        Reporting
                    </Breadcrumb.Item>
                    {/* <Breadcrumb.Item href="/reporting/paymentreport">{`Used Car Dealer (UCD) Payment Report`}</Breadcrumb.Item> */}
                    <Breadcrumb.Item href="/reporting/paymentreport">{`e-Statement`}</Breadcrumb.Item>
                </Breadcrumb>
                <PageHeader
                    className="site-page-header"
                    title={"e-Statement"}
                />
                <Row
                    className="filter-row"
                    style={{ marginBottom: showTotalResult ? 0 : 20 }}
                >
                    <Col span={18}>
                        <Form
                            layout="vertical"
                            className="form-container"
                            form={filterForm}
                            initialValues={{
                                filterBy: "paymentDate",
                            }}
                            onValuesChange={handleFilter}
                        >
                            <Form.Item
                                label="Filter by"
                                className="dropdown-wrapper"
                                name={"filterBy"}
                            >
                                <Select onChange={onChangeSearchBy}>
                                    <Select.Option value="paymentDate">
                                        Payment Date
                                    </Select.Option>
                                    <Select.Option value="transactiontDate">
                                        Transaction Date
                                    </Select.Option>
                                    <Select.Option value={"vehicleNumber"}>
                                        Vehicle Number
                                    </Select.Option>
                                    <Select.Option
                                        value={"sellerNricOrCompany"}
                                    >
                                        Seller IC Number / Company Registration
                                        Number
                                    </Select.Option>
                                    <Select.Option value={"buyerNricOrCompany"}>
                                        Buyer IC Number / Company Registration
                                        Number
                                    </Select.Option>
                                </Select>
                            </Form.Item>

                            {showSearch && (
                                <>
                                    <Form.Item
                                        className="form-item"
                                        name={"searchTerm"}
                                    >
                                        <Tooltip
                                            title={SEARCH_QUERY_TOOLTIP}
                                            placement="bottom"
                                        >
                                            <Input.Search
                                                className="select-search-wrapper search-input-container"
                                                style={{ maxWidth: "100%", marginTop: "30px" }}
                                                size="large"
                                                placeholder={"Search"}
                                                enterButton
                                                onSearch={handleSearch}
                                                value={searchTerm}
                                                onChange={(e) => {
                                                    setSearchTerm(
                                                        e.target.value
                                                    );
                                                    filterForm.setFieldsValue({
                                                        searchTerm:
                                                            e.target.value,
                                                    });
                                                }}
                                            />
                                        </Tooltip>
                                    </Form.Item>
                                </>
                            )}

                            {showPaymentDateSearch && (
                                <>
                                    <Form.Item
                                        label="From"
                                        className="date-wrapper"
                                        name={"from"}
                                    >
                                        <DatePicker
                                            format={"DD-MMM-YYYY"}
                                            onChange={(from) =>
                                                setFromCreatedDate(from)
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="To"
                                        className="date-wrapper"
                                        name={"to"}
                                    >
                                        <DatePicker
                                            format={"DD-MMM-YYYY"}
                                            onChange={(to) =>
                                                setToCreatedDate(to)
                                            }
                                        />
                                    </Form.Item>
                                </>
                            )}

                            {showTransactionDateSearch && (
                                <>
                                    <Form.Item
                                        label="Date"
                                        className="date-wrapper"
                                        name={"transactionDate"}
                                    >
                                        <DatePicker
                                            format={"DD-MMM-YYYY"}
                                            onChange={(date) =>
                                                setTransactionDate(date)
                                            }
                                        />
                                    </Form.Item>
                                </>
                            )}
                        </Form>
                    </Col>
                    <Col span={6} layout="vertical">
                        <Form
                            layout="vertical"
                            className="form-container"
                            form={typeForm}
                        >
                            <Form.Item
                                label="Transaction Type"
                                name="transactionType"
                                className="dropdown-wrapper"
                                style={{ width: "100%", marginTop: "12px", paddingTop: "0px"}}
                            >
                                <Select
                                    onChange={(type) => setSelectedType(type)}
                                    placeholder="Transaction Type"
                                    // allowClear
                                    defaultValue={"ALL"}
                                >
                                    {Object.keys(TRANSACTION_TYPE_LABEL).map(
                                        (key) => (
                                            <option
                                                key={key}
                                                value={
                                                    TRANSACTION_TYPE_LABEL[key]
                                                }
                                            >
                                                {TRANSACTION_TYPE_LABEL[key]}
                                            </option>
                                        )
                                    )}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                {/* {showTotalResult ? ( */}
                <Row className="filter-result-indicate">
                    <p>
                        Showing{" "}
                        <span>
                            {selectedType === "ALL"
                                ? dataSource.length
                                : dataSource.filter((data) =>
                                      data?.serviceType?.includes(selectedType)
                                  ).length || 0}
                        </span>{" "}
                        results
                        {filterForm.getFieldValue("to") ? (
                            <>
                                {" "}
                                for{" "}
                                <span>
                                    {dayjs(
                                        filterForm.getFieldValue("from")
                                    ).format("DD MMM YYYY")}
                                </span>{" "}
                                to{" "}
                                <span>
                                    {dayjs(
                                        filterForm.getFieldValue("to")
                                    ).format("DD MMM YYYY")}
                                </span>
                            </>
                        ) : (
                            <></>
                        )}
                        {filterForm.getFieldValue("transactionDate") && (
                            <>
                                {" "}
                                for{" "}
                                <span>
                                    {dayjs(
                                        filterForm.getFieldValue(
                                            "transactionDate"
                                        )
                                    ).format("DD MMM YYYY")}
                                </span>{" "}
                            </>
                        )}
                    </p>
                    <Button
                        className="delete-button buttons"
                        shape="round"
                        style={{
                            border: "1px solid #ffffff",
                            color: "#ffffff",
                        }}
                        onClick={handleFilterReset}
                    >
                        Reset
                    </Button>
                </Row>
                {/* ) : (
					<></>
				)} */}
                <Table
                    key={isLoading} // just to rerender the table so start from page 1
                    className="report-table"
                    size="small"
                    loading={isLoading}
                    columns={columns}
                    dataSource={
                        selectedType === "ALL"
                            ? dataSource
                            : dataSource.filter((data) =>
                                  data?.serviceType?.includes(selectedType)
                              )
                    }
                    scroll={{
                        x: columns.length * 150,
                    }}
                    rowSelection={{
                        type: "checkbox",
                        onSelect: (record, selected, selectedRows, nativeEvent) => {
                            setSelectedKeys(selectedKeys => {
                                if (selected) {
                                    return [...selectedKeys, record.key];
                                } else {
                                    return selectedKeys.filter(key => key !== record.key);
                                }
                            });
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setSelectedKeys(selected ? (
                                selectedType === "ALL"
                                ? dataSource
                                : dataSource.filter((data) => data?.serviceType?.includes(selectedType))
                            ).map(record => record.key) : []);
                        },
                        selectedRowKeys: selectedKeys,
                    }}
                    pagination={{
                        showSizeChanger: false,
                    }}
                    footer={() => {
                        return selectedKeys.length > 0 && paymentReportFooter();
                    }}
                    onChange={(pagination, filters, sorter) => {                        
                        const { column, order } = sorter;
                        if (sorter && column && order && column?.key) {
                            
                            const sortedData = dataSource.sort((a, b) => {
                                if (order === "ascend") {
                                    return a[column?.key] > b[column?.key] ? 1 : -1;
                                } else if (order === "descend") {
                                    return a[column?.key] < b[column?.key] ? 1 : -1;
                                }
                            });

                            setDataSource(sortedData);
                        }
                    }}
                />
                <Button
                    className="back-button buttons"
                    shape="round"
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    <Image src={arrow_back} preview={false} />
                    BACK
                </Button>
            </div>
            <style>
                {`
                .dropdown-wrapper label {
                    color: white !important;
                }
                .date-wrapper label {
                    color: white !important;
                }
                .site-page-header {
                    padding-left: 0px;
                    margin-bottom: 15px;
                }
                .date-wrapper { 
                    width: 20vw;
                    margin-right: 20px;
					margin-bottom: 0px;
                }
                .ant-select-selector { 
                    border: 1px solid #2B61C4 !important;
                    box-shadow: 0px 2px 4px #00000033 !important;
                    background: #FFFFFF 0% 0% no-repeat padding-box !important;
                    border-radius: 4px !important;
                    height: 45px !important;
                    display: flex;
                    align-items: center;
                }
                .ant-select-arrow {
                    color: #2B61C4;
                }
				.ant-form {
					margin-bottom: 0px;
				}
                .footer-button {
                    border: 1px solid #2B61C4;
                    color: #2B61C4;
                    margin-right: 20px;
                    margin-left: 0px;
                }
                .report-table { 
                    margin-bottom: 20px;
                }
            `}
            </style>
        </>
    );
}
