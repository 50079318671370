// @ts-nocheck
import {
    Space,
    Steps,
    message,
    Button,
} from 'antd'
import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import AuthorizeDealer from './authorizeDealer'
import AuthorizeSeller from './authorizeSeller'
import JPJSubmission from './jpjSubmission'
import Payment from './payment'
import {
    STATES,
    STATES_FULLNAME
} from '../../constants'
import { SessionContext } from '../../App'
import moment from 'moment'
import { Dealer, Seller, STEPS, Vehicle, HistoryState, Params, Modes } from './types'
import VehicleInformation from './vehicleInformation'
import { getAuthorizedNric, getCachedStates, removeCachedStates, setAuthorizedNric, getSagemUrlForKey, removeSagemUrlForKey,
    getCompanyId, 
} from '../../services/local'
import OwnershipType from './ownershipType'
import PrintSlip from './printslip'
import {
    getInTransferByTransferId,
    getAccountDetails,
    isAccountExist
} from '../../services/api'
import {
    GetInTransferDetailRequest
} from '../../services/api/types'
import {
    getState
} from '../../util'
import {skipAuth, decodeForSagem, decryptUrlVerify} from '../../components/Utils'
import red_thumbprint from '../../assets/illus-thumb-fail.svg'

export default function CreateInTransactionPage() {

    const { user } = useContext(SessionContext)
    const history = useHistory()
    const location = useLocation()
    const [from, setFrom] = useState('Dealer')
    const [to, setTo] = useState('Buyer')
    const params = useParams<Params>()
    const { state: locationState = {} } = useLocation<HistoryState>()
    const [dealer, setDealer] = useState<Dealer>({
        "address1": "",
        "address2": "",
        "address3": "",
        "district": "",
        "state": "",
        "postcode": "",
        "dob": "",
        "correspondentEmail": "",
        "gender": "",
        "correspondentMobileNo": "",
        "correspondentAddress": "",
        "name": "",
        "nric": "",
        "phoneno": "",
        "username": ""
    })
    const [vehicle, setVehicle] = useState<Vehicle>(
        {
            "carMake": "",
            "carModel": "",
            "chassisNo": "",
            "engineNo": "",
            "manufactureYear": "",
            "ownerIdCat": "",
            "vehicleRegistrationNo": "",
            "vehicleType": "",
            "isWhiteListVehicle": false,    // 28.12.2021 daniel.kwok hardcoded defaults
            "lkmoption": 1,                 // 28.12.2021 daniel.kwok hardcoded defaults
        }
    )
    const [seller, setSeller] = useState<Seller>({
        "address1": "",
        "address2": "",
        "address3": "",
        "correspondentAddress1": "",
        "correspondentAddress2": "",
        "correspondentAddress3": "",
        "correspondentPostcode": "",
        "correspondentDistrict": "",
        "correspondentEmail": "",
        "correspondentMobileNo": null,
        "dateOfBirth": "",
        "district": "",
        "city": "",
        "email": "",
        "gender": "",
        "identificationNo": "",
        "name": "",
        "phoneNo": "",
        "postcode": "",
        "repIc": "",
        "repIc2": "",
        "repName": "",
        "state": "",
        "citizenship": null,
    })
    const [approverNric, setApproverNric] = useState('')
    const [pageTitle, setTitle] = useState('Owner to Dealer')
    const comingFromReport = history.location.state?.isReport ?? false
    const tabType = history.location.state?.tabType ?? "1"

    // To navigate between components (a.k.a form steps), we use history.push by
    // pushing to the current path, along with the steps and desired substeps inside the history state.
    // Below is an example to navigate to Authorize Seller (Owner Authentication) step, 
    // and pointing to the Summary substep:
    /* 
    history.push(history.location.pathname, {
        ...locationState,
        step: STEPS.AUTHORIZE_SELLER.label,
        subStep: STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY
    })
    */
    // The ...locationState (history.location.state) was used to carry data from one step to the next
    const COMPONENTS = [
        {
            title: 'Owner Authentication',
            label: STEPS.AUTHORIZE_SELLER.label,
            component: <AuthorizeSeller
                seller={locationState?.seller || seller}
                onChange={_seller => {
                    setSeller(_seller)
                }}
            />
        },
        {
            title: 'Vehicle Information',
            label: STEPS.VEHICLE_INFORMATION.label,
            url: '/transfer/in/vehicleinfo/',
            component: <VehicleInformation
                dealer={locationState?.dealer || dealer}
                vehicle={locationState?.vehicle || vehicle}
                seller={locationState?.seller || seller}
                onChange={_vehicle => {
                    setVehicle(_vehicle)
                }}
                tabType={tabType}
            />
        },
        {
            title: 'JPJ Submission',
            label: STEPS.JPJ_SUBMISSION.label,
            component: <JPJSubmission
                // The data for this dealer props will be either from locationState or dealer variable.
                // If thumbscan is using SAGEM, every time we do history.push, index.js will reload, 
                // and all variable from useState will be reset to initial state. 
                // Hence we need to take previous step data from locationState.
                // But if using DERMALOG, no need to get dealer from locationState, because dealer data was 
                // already set into dealer variable.
                dealer={locationState?.dealer || dealer}
                vehicle={locationState?.vehicle || vehicle}
                seller={locationState?.seller || seller}
                isReport={comingFromReport}
                tabType={tabType}
            />
        },
        /**
         * This step is a little special.
         * Payment page is a separate page, managed by location url instead of location state
         * This assumes that all transactions that's in the payment stage is already created
         */
        {
            title: 'Payment',
            label: STEPS.PAYMENT.label,
            url: '/transfer/in/payment/',
            component: <Payment
                approverNRIC={approverNric}
                vehicle={vehicle}
                seller={seller}
                dealer={dealer}
                titleM2M={setTitle}
                onApproverNRICChange={approverNric => {
                    setApproverNric(approverNric)
                }}
                isReport={comingFromReport}
                tabType={tabType}
            />
        },
        /**
         * This step is a little special.
         * Printslip page is a separate page, managed by location url instead of location state
         * This assumes that all transactions that's in the payment stage is already created
         */
        {
            title: 'Print slip',
            label: STEPS.PRINT_SLIP.label,
            url: '/transfer/in/printslip/',
            component: <PrintSlip
                approverNric={approverNric}
                vehicle={vehicle}
                seller={seller}
                dealer={dealer}
                isReport={comingFromReport}
                isStatusFail={history.location.state?.isStatusFail ?? false}
            />
        }
    ]

    useEffect(() => {

        if (locationState?.fromAdminPage === true) {//for adminpage view

            if (params.mode == Modes.vehicleinfo) {

                if (locationState?.transferId) {

                    let transferId = locationState?.transferId

                    const getInTransferDetailRequest: GetInTransferDetailRequest = {
                        transferId, moduleType : "IN"
                    }

                    getInTransferByTransferId(getInTransferDetailRequest)
                        .then(res => {

                            if (res.id === undefined) throw res.message

                            let vt = {
                                "carMake": res?.make,
                                "carModel": res?.model,
                                "chassisNo": res?.chassisNo,
                                "engineNo": res?.engineNo,
                                "manufactureYear": res?.manufactureYear,
                                "ownerIdCat": res.ownerCatagory,
                                "vehicleRegistrationNo": res?.carRegistrationNo,
                                "vehicleType": res?.vehicleTypeId,
                                "isWhiteListVehicle": false,
                                "lkmoption": 1,
                            }

                            history.push(history.location.pathname, {
                                ...locationState,
                                vehicle: vt,
                                dealer: res.buyerInfo as Dealer,
                                step: STEPS.VEHICLE_INFORMATION.label,
                                subStep: STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO
                            });

                        })
                        .catch(err => err && message.error(err))

                }

            } else {

                history.push(history.location.pathname, {
                    ...locationState,
                    dealer: user as Dealer,
                    step: STEPS.PRINT_SLIP.label,
                });

            }
        }
        // Checks if transaction already exist or not
        else if (params.transferId || locationState?.transferId) {
            switch (params.mode) {
                case Modes.create:
                    history.push(history.location.pathname, {
                        ...locationState,
                        dealer: user as Dealer,
                        step: STEPS.JPJ_SUBMISSION.label,
                        subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED
                    });
                    break;
                case Modes.payment:
                    history.push(history.location.pathname, {
                        ...locationState,
                        dealer: user as Dealer,
                        step: STEPS.PAYMENT.label,
                    });
                    break;
                case Modes.printslip:
                    history.push(history.location.pathname, {
                        ...locationState,
                        dealer: user as Dealer,
                        step: STEPS.PRINT_SLIP.label,
                    });
                    break;
                case Modes.vehicleinfo:

                    if (params.transferId) {

                        let transferId = params.transferId

                        const getInTransferDetailRequest: GetInTransferDetailRequest = {
                            transferId, moduleType : "IN"
                        }

                        getInTransferByTransferId(getInTransferDetailRequest)
                            .then(res => {

                                if (res.id === undefined) throw res.message

                                let vt = {
                                    "carMake": res?.make,
                                    "carModel": res?.model,
                                    "chassisNo": res?.chassisNo,
                                    "engineNo": res?.engineNo,
                                    "manufactureYear": res?.manufactureYear,
                                    "ownerIdCat": res.ownerCatagory,
                                    "vehicleRegistrationNo": res?.carRegistrationNo,
                                    "vehicleType": res?.vehicleTypeId,
                                    "isWhiteListVehicle": false,
                                    "lkmoption": 1,
                                }

                                history.push(history.location.pathname, {
                                    ...locationState,
                                    vehicle: vt,
                                    dealer: res.buyerInfo as Dealer,
                                    step: STEPS.VEHICLE_INFORMATION.label,
                                    subStep: STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO
                                });

                            })
                            .catch(err => err && message.error(err))

                    }

                    break;
                case Modes.edit:
                default:
                    break;
            }
        } else {
            const authorizedNric = getAuthorizedNric();

            /**
             * Check if user is already authorized for this session.
             * If yes, redirect to next step
             * Else, need to re-authorize
             */
            if (authorizedNric === user?.nric) {
                switch (params.mode) {
                    case Modes.create:
                        history.push(history.location.pathname, {
                            ...locationState,
                            dealer: user as Dealer,
                            step: locationState?.step,
                            subStep: locationState?.subStep,
                        });
                        break;
                    case Modes.payment:
                        history.push(history.location.pathname, {
                            ...locationState,
                            dealer: user as Dealer,
                            step: STEPS.PAYMENT.label,
                        });
                        break;
                    case Modes.printslip:
                        history.push(history.location.pathname, {
                            ...locationState,
                            dealer: user as Dealer,
                            step: STEPS.PRINT_SLIP.label,
                        });
                        break;
                    case Modes.vehicleinfo:
                        history.push(history.location.pathname, {
                            ...locationState,
                            dealer: user as Dealer,
                            step: STEPS.VEHICLE_INFORMATION.label,
                            subStep: STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO
                        });
                        break;
                    case Modes.edit:
                    default:
                        break;
                }
            } else {
                history.push(history.location.pathname, {
                    ...locationState,
                    step: STEPS.AUTHORIZE_DEALER.label,
                    subStep: STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC,
                });
            }
        }
    }, [])

    /**
     * 23/2/2022 daniel.kwok
     * Receiving and transforming data from Sagem
     */
    useEffect(async () => {
        const urlParams = new URLSearchParams(location.search);

        // If there's a 'forKey' in the url, means it redirected from sagem static site
        if (urlParams.get('forKey') != null) {

            if (getSagemUrlForKey() !== urlParams.get('forKey')) {

                removeSagemUrlForKey()

                history.push(history.location.pathname, {
                    ...history.location.state,
                    step: STEPS.OWNERSHIP_TYPE.label,
                    subStep: STEPS.OWNERSHIP_TYPE.subSteps.OWNERSHIP_TYPE
                })

                return

            }

            let values;
            let decodedValues;
            const decodedForKey = decodeURIComponent(urlParams.get('forKey') || '{}')
            const cached = JSON.parse(getCachedStates())
            const isSkipAuth = skipAuth(false, null, null, urlParams, user?.nric)
            if (isSkipAuth) {
                values = isSkipAuth
            }
            else {
                if (urlParams.get('tk') != null && urlParams.get('dt') != null) {
                    decodedValues = await decodeForSagem(
                        decodeURIComponent(urlParams.get('tk')), 
                        decodeURIComponent(urlParams.get('dt')),
                        urlParams.get('forKey'),
                        history,
                        STEPS,
                        "IN")
                    if (decodedValues === undefined) {
                        message.error("Please try again.")
                        removeSagemUrlForKey()
    
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.OWNERSHIP_TYPE.label,
                            subStep: STEPS.OWNERSHIP_TYPE.subSteps.OWNERSHIP_TYPE
                        })
                        return
                    }
                }
                else {
                    message.error("Please try again.")
                    removeSagemUrlForKey()

                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.OWNERSHIP_TYPE.label,
                        subStep: STEPS.OWNERSHIP_TYPE.subSteps.OWNERSHIP_TYPE
                    })
                    return
                }
                removeCachedStates()

                // const decodedValues = decodeURIComponent(urlParams.get('values') || '{}')
                values = JSON.parse(decodedValues)

                const decryptResponse = await decryptUrlVerify(values,
                    urlParams.get('tk'),
                    decodedForKey,
                    getCompanyId(),
                    "SAGEM",
                    user.nric.replace(/[\s*]/g, ""), null, false);
                if(decryptResponse === false) {
                    removeSagemUrlForKey()
                    
                    if (user.nric.replace(/\D/g, '') !== values['Ic No.'].replace(/\D/g, '')) {
                        history.push(history.location.pathname, {
                            step: STEPS.AUTHORIZE_DEALER.label,
                            subStep: STEPS.AUTHORIZE_DEALER.subSteps.AUTHORIZE_FAILED
                        })
                        return
                    }

                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.OWNERSHIP_TYPE.label,
                        subStep: STEPS.OWNERSHIP_TYPE.subSteps.OWNERSHIP_TYPE
                    })
                    return
                }
            }

            if (values['ThumbSucess'] === 'true') {

                let errorMessages = [];

                if (!values['Name']) {
                    errorMessages.push("Failed to read the Name from MyKad. The Name read as '" + values['Name'] + "'. Please contact the eTukar Admin for more information.");
                }

                // if (!values['City']) {
                //     errorMessages.push("Failed to read the District from MyKad. The District read as '" + values['City'] + "'. Please contact the eTukar Admin for more information.");
                // }

                if (!values['Ic No.'] || !/^[+]?\d+([.]\d+)?[*]?$/.test(values['Ic No.'])) {
                    errorMessages.push("Failed to read the IC No from MyKad. The IC No. read as '" + values['Ic No.'] + "'. Please contact the eTukar Admin for more information.");
                }

                if (errorMessages.length > 0) {
                    for (let errorMessage of errorMessages) {
                        message.error(errorMessage);
                    }
                    history.push(history.location.pathname, {
                        ...locationState,
                        step: STEPS.OWNERSHIP_TYPE.label,
                        subStep: STEPS.OWNERSHIP_TYPE.subSteps.OWNERSHIP_TYPE
                    })
                    return;
                }

                let stateKey, state, formatted, dob, formattedState
                switch (decodedForKey) {
                    case 'dealer':
                        if (user.nric.replace(/\D/g, '') !== values['Ic No.'].replace(/\D/g, '')) {
                            history.push(history.location.pathname, {
                                step: STEPS.AUTHORIZE_DEALER.label,
                                subStep: STEPS.AUTHORIZE_DEALER.subSteps.AUTHORIZE_FAILED
                            })
                            return
                        }

                        formattedState = values['State']?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                        state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                        if (!state) state = getState(values['Ic No.'])

                        if (moment(values['Date of Birth'], 'DD/M/YYYY HH').isValid()) {

                            dob = moment(values['Date of Birth'], 'DD/M/YYYY HH').format('YYYY-MM-DD')

                        } else {

                            let ic = values['Ic No.']

                            let Year = ic.substring(0, 2)
                            let Month = ic.substring(2, 4)
                            let Day = ic.substring(4, 6)

                            let cutoff = (new Date()).getFullYear() - 2000

                            let fulldob = Day + '/' + Month + '/' + (Year > cutoff ? '19' : '20') + Year

                            if (moment(fulldob, 'DD/M/YYYY HH').isValid()) {
                                dob = moment(fulldob, 'DD/M/YYYY HH').format('YYYY-MM-DD')
                            } else {
                                dob = ''
                            }
                        }

                        if (dob == 'Invalid date') dob = ''

                        formatted = {
                            address1: values['Address 1'].replace(/\0.*$/, ""),
                            address2: values['Address 2'].replace(/\0.*$/, ""),
                            address3: values['Address 3'].replace(/\0.*$/, ""),
                            district: values['City'].replace(/[^a-zA-Z\s]/g, ''),
                            state: state?.code,
                            postcode: values['PostCode'],
                            dob: dob,
                            gender: values['Gender'] === 'Male' ? "L" : "P",
                            correspondentEmail: '',
                            correspondentMobileNo: '',
                            "correspondentAddress": "",
                            name: values['Name'].replace(/\*/g, ''),
                            nric: values['Ic No.'].replace(/[\s*]/g, ""),
                            phoneno: '',
                            username: '',
                            photo: values['Photo'],
                        } as Dealer

                        setDealer(formatted)
                        setAuthorizedNric(values['Ic No.'])

                        history.push(history.location.pathname, {
                            ...cached,
                            dealer: formatted,
                            step: STEPS.OWNERSHIP_TYPE.label,
                            subStep: STEPS.OWNERSHIP_TYPE.subSteps.OWNERSHIP_TYPE,
                        })

                        break
                    case 'seller':

                        formattedState = values['State']?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                        state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                        if (!state) state = getState(values['Ic No.'])

                        if (moment(values['Date of Birth'], 'DD/M/YYYY HH').isValid()) {

                            dob = moment(values['Date of Birth'], 'DD/M/YYYY HH').format('YYYY-MM-DD')

                        } else {

                            let ic = values['Ic No.']

                            let Year = ic.substring(0, 2)
                            let Month = ic.substring(2, 4)
                            let Day = ic.substring(4, 6)

                            let cutoff = (new Date()).getFullYear() - 2000

                            let fulldob = Day + '/' + Month + '/' + (Year > cutoff ? '19' : '20') + Year

                            if (moment(fulldob, 'DD/M/YYYY HH').isValid()) {
                                dob = moment(fulldob, 'DD/M/YYYY HH').format('YYYY-MM-DD')
                            } else {
                                dob = ''
                            }
                        }
                        if (dob == 'Invalid date') dob = ''

                        let mobileNo
                        let _email

                        isAccountExist(values['Ic No.'])
                            .then(res => {

                                if (!res.status) {
                                    return {
                                        status: false
                                    }
                                } else {
                                    return getAccountDetails(values['Ic No.'])
                                }

                            })
                            .then(res => {

                                if (res.status == false) {
                                    mobileNo = ''
                                    _email = ''
                                } else {

                                    const { email, mobileno } = res

                                    mobileNo = mobileno
                                    _email = email

                                }

                            })
                            .catch(err => {
                                err && message.error(err)
                            }).finally(() => {

                                formatted = {
                                    address1: values['Address 1'].replace(/\0.*$/, ""),
                                    address2: values['Address 2'].replace(/\0.*$/, ""),
                                    address3: values['Address 3'].replace(/\0.*$/, ""),
                                    correspondentAddress1: "",
                                    correspondentAddress2: "",
                                    correspondentAddress3: "",
                                    correspondentPostcode: "",
                                    correspondentState: "",
                                    correspondentEmail: '',
                                    correspondentMobileNo: '',
                                    dateOfBirth: dob,
                                    // district: values['City'].replace(/[^a-zA-Z0-9\s\/-]/g, ''),
                                    district: "",
                                    city: values['City'].replace(/[^a-zA-Z0-9\s\/-]/g, ''),
                                    email: _email,
                                    mobileNo: mobileNo,
                                    gender: values['Gender'] === 'Male' ? "L" : "P",
                                    identificationNo: values['Ic No.'].replace(/[\s*]/g, ""),
                                    name: values['Name'].replace(/\*/g, ''),
                                    phoneNo: '',
                                    postcode: values['PostCode'],
                                    "repIc": "",
                                    "repIc2": "",
                                    "repName": "",
                                    state: state?.code,
                                    photo: values['Photo'],
                                    citizenship: values['Warganegara']
                                } as Seller

                                setSeller(formatted)
                                history.push(history.location.pathname, {
                                    ...cached,
                                    seller: formatted
                                })

                            })

                        break
                    default:
                        break
                }

            } else if (values['ThumbSucess'] !== 'true' && decodedForKey != '{}') {

                message.error('Thumbprint is not sucess')

                if (decodedForKey === 'dealer') {

                    history.push(history.location.pathname, {
                        step: STEPS.AUTHORIZE_DEALER.label,
                        subStep: STEPS.AUTHORIZE_DEALER.subSteps.THUMBPRINT_FAILED
                    })
                    return
                } else {

                    history.push(history.location.pathname, {
                        step: STEPS.AUTHORIZE_SELLER.label,
                        subStep: STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_FAILED
                    })
                    return
                }

            }

        }
    }, [])


    const component = COMPONENTS.find(step => {
        switch (params.mode) {
            case Modes.create:
                return step.label === (locationState.step)
            case Modes.payment:
                return step.label === STEPS.PAYMENT.label
            case Modes.printslip:
                return step.label === STEPS.PRINT_SLIP.label
            case Modes.vehicleinfo:
                return step.label === STEPS.VEHICLE_INFORMATION.label
            default:
                return false
        }
    })

    const index = component && COMPONENTS.findIndex(step => step.label === component.label) || 0

    return (
        <div
            style={{
                margin: '40px 80px'
            }}
        >
            <div style={{ textAlign: 'center' }}>
                <Space direction='horizontal'>
                    <h2 className='transaction-title-in'>In</h2>
                    <h2 style={{ color: '#FFFFFF' }}>-</h2>
                    <h2 className='transaction-type-in'>Owner</h2>
                    <h2 className='transaction-additional-in'>to</h2>
                    <h2 className='transaction-type-in'>Dealer</h2>
                </Space>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: 'transparent'
                }}
            >
                {
                    (
                        locationState?.fromAdminPage != true
                        &&
                        locationState?.step !== STEPS.AUTHORIZE_DEALER.label
                        &&
                        locationState?.step !== STEPS.OWNERSHIP_TYPE.label
                    ) ? (
                        <div style={{
                            marginBottom: 40, marginTop: 20
                        }}>
                            <Steps
                                labelPlacement='vertical'
                                style={{ width: '60vw' }}
                                size="default"
                                current={index}
                            >
                                {
                                    COMPONENTS.map(step => {
                                        return <Steps.Step
                                            key={step.title} title={<span style={{ color: 'white', width: '100%' }}>{step.title}</span>}
                                        />
                                    })
                                }
                            </Steps>
                        </div>
                    ) : null
                }

                {
                    locationState?.step === STEPS.AUTHORIZE_DEALER.label ? (
                        <AuthorizeDealer
                            onAuthorizedSuccess={() => {
                                // history.push(history.location.pathname, {
                                //     ...locationState,
                                //     step: STEPS.AUTHORIZE_SELLER.label,
                                //     subStep: STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC
                                // })
                                history.push(history.location.pathname, {
                                    ...locationState,
                                    step: STEPS.OWNERSHIP_TYPE.label,
                                    subStep: STEPS.OWNERSHIP_TYPE.subSteps.OWNERSHIP_TYPE
                                })
                            }} />
                    ) : locationState?.step === STEPS.OWNERSHIP_TYPE.label ? (
                        //vehicle ownerIdCat being update
                        <OwnershipType
                            onChange={vehicle => {
                                setVehicle(vehicle)
                            }}
                            vehicle={locationState?.vehicle || vehicle}
                        />
                    ) : (
                        component?.component
                    )
                }

            </div>
        </div>
    )
}