import {
    Breadcrumb,
    Button,
    DatePicker,
    Form,
    Image,
    message,
    Row,
    Select,
    Table,
    Input,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { useForm } from "antd/lib/form/Form";
import { useContext, useEffect, useState } from "react";
import export_xls from "../../../assets/export_xls_filled.svg";
import ReactExport from "react-export-excel";
import dayjs from "dayjs";
import { SessionContext } from "../../../App";
import { getVehicleMovementReport } from "../../../services/api";
import "./index.less";
import { HQ_BRANCH } from "../../../constants";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function VehicleMovementReportPage() {
    const [searchForm] = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [initialDataSource, setInitialDataSource] = useState([]);
    const [page, setPage] = useState(1);

    const { company, branchs } = useContext(SessionContext);

    const dateFrom = dayjs(dateFrom).subtract(60, "days");
    const dateTo = dayjs();
    const [currentFormDate, setCurrentFormDate] = useState(dateFrom);
    const [timer, setTimer] = useState(null);
    const [searchByUsername, setSearchByUsernameType] = useState("all");

    const columns = [
        {
            title: "No",
            width: 60,
            render: (text, record, index) => (page - 1) * 10 + index + 1,
        },
        {
            title: "Vehicle Number",
            dataIndex: "vehicleNumber",
            width: 150,
            sorter: (a, b) => a.vehicleNumber.localeCompare(b.vehicleNumber),
        },
        {
            title: "Creation Date & Time",
            dataIndex: "createdDate",
            width: 150,
            render: (createdDate) => {
                let formattedDate = new Date(createdDate);
                return dayjs(formattedDate).format("DD-MMM-YYYY hh:mm:ss");
            },
            sorter: (a, b) => {
                let formattedA = dayjs(new Date(a.createdDate));
                let formattedB = dayjs(new Date(b.createdDate));

                return formattedA.diff(formattedB);
            },
        },
        {
            title: "Approved Date & Time",
            dataIndex: "approvedDate",
            width: 150,
            render: (approvedDate) => {
                if (approvedDate != "-") {
                    let formattedDate = new Date(approvedDate);
                    return dayjs(formattedDate).format("DD-MMM-YYYY hh:mm:ss");
                } else return approvedDate;
            },
            sorter: (a, b) => {
                let formattedA = dayjs(new Date(a.approvedDate));
                let formattedB = dayjs(new Date(b.approvedDate));

                return formattedA.diff(formattedB);
            },
        },
        // {
        //     title: "Vehicle Model",
        //     dataIndex: "vehicleModel",
        //     width: 150,
        //     render: (vehicleModel) => vehicleModel ?? "-",
        // },
        {
            title: "Vehicle Type (Motor or Car)",
            dataIndex: "vehicleType",
            width: 100,
            render: (vehicleType) => vehicleType ?? "-",
        },
        {
            title: "Transaction Type",
            dataIndex: "txnType",
            wdith: 100,
            render: (txnType) => {
                return txnType.toLowerCase().includes("in") ? (
                    <p style={{ color: "#079B1B", margin: 0 }}>{txnType}</p>
                ) : (
                    <p style={{ color: "#E53B34", margin: 0 }}>{txnType}</p>
                );
            },
        },
        {
            title: "Seller Name",
            dataIndex: "sellerName",
            width: 200,
        },
        {
            title: "Buyer Name",
            dataIndex: "buyerName",
            width: 150,
        },
        // {
        //     title: "Tx Status",
        //     dataIndex: "txnStatus",
        //     width: 100,
        //     render: (txnStatus) => {
        //         return txnStatus
        //             .toString()
        //             .toLowerCase()
        //             .includes("approved") ? (
        //             <p style={{ color: "#079B1B", margin: 0 }}>{txnStatus}</p>
        //         ) : (
        //             <p style={{ color: "#2B61C4", margin: 0 }}>{txnStatus}</p>
        //         );
        //     },
        // },
        {
            title: "Created User Name",
            dataIndex: "thumbInUser",
            width: 200,
            ellipsis: true,
        },

        {
            title: "Approved User Name",
            dataIndex: "approvedUser",
            width: 170,
            render: (approvedUser) => approvedUser ?? "-",
        },
        {
            title: "Location",
            dataIndex: "location",
            render: (location, { branchId }) => {
                if (location != "-") return location;
                if (branchId) {
                    const allBranches = branchs
                        ? [...branchs, HQ_BRANCH]
                        : [HQ_BRANCH];

                    const branch = allBranches.find((b) => b.id == branchId);

                    return branch ? branch?.branchName : "-";
                }

                return "-";
            },
        },
    ];

    const handleSearch = async () => {
        setIsLoading(true);
        let { searchBy, from, to, usernameType, search } =
            searchForm.getFieldsValue();

        if (!dayjs(currentFormDate).isSame(dayjs(from))) {
            if (dayjs(from).add(60, "days").isAfter(dayjs())) {
                // the date is more than today
                to = dayjs();
            } else {
                // the date is not more than today
                to = dayjs(from).add(60, "days");
            }
            setCurrentFormDate(from);
        }
        searchForm.setFieldsValue({ to });
        setSearchByUsernameType(usernameType);

        if (from && to) {
            getVehicleMovementReport(
                company.coRegNo,
                from.startOf('day').format("YYYY-MM-DD HH:mm:ss"),
                to.endOf('day').format("YYYY-MM-DD HH:mm:ss"),
                search ? search.trim() : "",
                search ? usernameType : ""
            )
                .then((response) => {
                    let data = response;

                    if (response.message) {
                        message.error(response.message);
                        data = [];
                    }

                    // Check if data is an array before mapping over it
                    if (Array.isArray(data)) {
                        let dataWithKey = data.map((item, index) => ({
                            key: index + 1,
                            ...item,
                        }));

                        if (searchBy !== "all") {
                            const lowerSearchBy = searchBy.toLowerCase();
                            dataWithKey = dataWithKey.filter((item) => {
                                if (
                                    lowerSearchBy === "apt" &&
                                    item.aptStatus === "Success"
                                ) {
                                    return true;
                                }
                                return item.txnType
                                    .toLowerCase()
                                    .includes(lowerSearchBy);
                            });
                        }
                        setPage(1);
                        setDataSource(dataWithKey);
                    } else {
                        console.log("Data is not a valid array");
                    }

                    setIsLoading(false);
                })
                .catch((error) => message.error(error));
        } else {
            const dataSource =
                searchBy === "all"
                    ? initialDataSource
                    : initialDataSource.filter((item) =>
                          item.txnType
                              .toLowerCase()
                              .includes(searchBy.toLowerCase())
                      );
            setDataSource(dataSource);
            setIsLoading(false);
        }
    };

    const exportXLSButton = () => {
        return (
            <div className="button-wrapper">
                <ExcelFile
                    element={
                        <Button
                            shape="round"
                            className="add-new-user-button"
                            type="primary"
                            style={{ marginBottom: 10 }}
                            disabled={!isLoading && dataSource.length === 0}
                            loading={isLoading}
                        >
                            <Image
                                src={export_xls}
                                preview={false}
                                width={30}
                                height={30}
                                style={{ paddingRight: 10 }}
                            />
                            Export report
                        </Button>
                    }
                    filename="VehicleMovementReport"
                >
                    <ExcelSheet
                        data={dataSource}
                        name="Vehicle Movement Report"
                    >
                        <ExcelColumn
                            label="Vehicle Number"
                            value="vehicleNumber"
                        />
                        {/* <ExcelColumn
                            label="Vehicle Model"
                            value="vehicleModel"
                        /> */}
                        <ExcelColumn
                            label="Creation Date & Time"
                            value={(col) => {
                                let formattedDate = new Date(col.createdDate);
                                return dayjs(formattedDate).format(
                                    "DD-MMM-YYYY hh:mm:ss"
                                );
                            }}
                        />
                        <ExcelColumn
                            label="Approved Date & Time"
                            value={(col) => {
                                if (col.approvedDate != "-") {
                                    let formattedDate = new Date(
                                        col.approvedDate
                                    );
                                    return dayjs(formattedDate).format(
                                        "DD-MMM-YYYY hh:mm:ss"
                                    );
                                } else return col.approvedDate;
                            }}
                        />
                        <ExcelColumn
                            label="Vehicle Type (Motor or Car)"
                            value="vehicleType"
                        />
                        <ExcelColumn label="Transaction Type" value="txnType" />
                        <ExcelColumn label="Seller Name" value="sellerName" />
                        <ExcelColumn label="Buyer Name" value="buyerName" />
                        <ExcelColumn
                            label="Created User Name"
                            value="thumbInUser"
                        />
                        <ExcelColumn
                            label="Approved User"
                            value="approvedUser"
                        />
                        {/* <ExcelColumn label="Tx Status" value="txnStatus" /> */}
                        <ExcelColumn
                            label="Location"
                            value={(col) => {
                                {
                                    if (col?.location != "-")
                                        return col?.location;
                                    if (col?.branchId) {
                                        const allBranches = branchs
                                            ? [...branchs, HQ_BRANCH]
                                            : [HQ_BRANCH];

                                        const branch = allBranches.find(
                                            (b) => b.id == col?.branchId
                                        );

                                        return branch
                                            ? branch?.branchName
                                            : "-";
                                    }

                                    return "-";
                                }
                            }}
                        />
                    </ExcelSheet>
                </ExcelFile>
            </div>
        );
    };

    useEffect(() => {
        // fetch data and setDataSource
        if (Object.keys(company).length > 0) {
            setIsLoading(true);

            getVehicleMovementReport(
                company.coRegNo,
                dateFrom.startOf('day').format("YYYY-MM-DD HH:mm:ss"),
                dateTo.endOf('day').format("YYYY-MM-DD HH:mm:ss")
            )
                .then((res) => {
                    let data = res;

                    if (res.message) {
                        message.error(res.message);
                        data = [];
                    }

                    const dataWithKey = data.map((item, index) => {
                        return {
                            key: index + 1,
                            ...item,
                        };
                    });
                    setDataSource(dataWithKey);
                    setInitialDataSource(dataWithKey);
                    setIsLoading(false);
                })
                .catch((err) => err && message.error(err));
        }
    }, [company]);

    const debounceSearch = () => {
        // Clear the previous timer if there's any
        if (timer) {
            clearTimeout(timer);
        }

        // Set a new timer to call the handleSearch function after 500ms
        const newTimer = setTimeout(() => {
            handleSearch();
        }, 700);

        // Update the timer state to clear it later
        setTimer(newTimer);
    };

    return (
        <>
            <div className="page-content">
                <Breadcrumb separator=">" style={{ marginTop: "60px" }}>
                    <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item href="/reporting">
                        Reporting
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/reporting/vehiclemovementreport">
                        Vehicle movement report
                    </Breadcrumb.Item>
                </Breadcrumb>
                <PageHeader
                    className="site-page-header"
                    title={"Vehicle Movement Report"}
                />
                <Row
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        marginBottom: 10,
                    }}
                >
                    <Form
                        layout="vertical"
                        className="form-container"
                        style={{ borderRadius: 4, width: "65vw" }}
                        initialValues={{
                            searchBy: "all",
                            from: dateFrom,
                            to: dateTo,
                        }}
                        form={searchForm}
                        onValuesChange={debounceSearch}
                    >
                        <Form.Item
                            className="dropdown-wrapper"
                            label="Transaction type"
                            name={"searchBy"}
                            style={{ width: "200px" }}
                        >
                            <Select
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option value={"all"}>ALL</Select.Option>
                                <Select.Option value={"in"}>IN</Select.Option>
                                <Select.Option value={"out"}>OUT</Select.Option>
                                {/* <Select.Option value={"i2i"}>i2i</Select.Option> ETK-904 - remove I2I txn type */}
                                <Select.Option value={"apt"}>APT</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="From"
                            className="date-wrapper"
                            name={"from"}
                        >
                            <DatePicker
                                format={"DD-MMM-YYYY"}
                                disabledDate={(date) => {
                                    return dayjs(date).isAfter(dayjs(dateTo));
                                }}
                                // onChange={(date) => {
                                // 	if (
                                // 		dayjs(searchForm.getFieldValue("to")) > dayjs(date).add(6, "days") ||
                                // 		dayjs(searchForm.getFieldValue("to")) < dayjs(date)
                                // 	) {
                                // 		searchForm.setFieldsValue({
                                // 			to: dayjs(date).add(6, "days"),
                                // 		});
                                // 	}
                                // }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="To"
                            className="date-wrapper"
                            name={"to"}
                        >
                            <DatePicker
                                format={"DD-MMM-YYYY"}
                                disabledDate={(date) => {
                                    return (
                                        !dayjs(dateTo)
                                            .subtract(60, "day")
                                            .isBefore(date) ||
                                        dayjs(date).isAfter(dayjs()) ||
                                        dayjs(date).isAfter(
                                            dayjs(currentFormDate).add(
                                                60,
                                                "day"
                                            )
                                        )
                                    );
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Username Type"
                            className="date-wrapper"
                            name={"usernameType"}
                        >
                            <Select
                                defaultValue="all"
                                options={[
                                    {
                                        label: "ALL",
                                        value: "all",
                                    },
                                    {
                                        label: "Created User Name",
                                        value: "createdusername",
                                    },
                                    {
                                        label: "Approved User Name",
                                        value: "approvedusername",
                                    },
                                ]}
                            />
                        </Form.Item>
                        {searchByUsername && searchByUsername != "all" && (
                            <Form.Item
                                label="Search"
                                className="date-wrapper"
                                name={"search"}
                                style={{ paddingTop: "12px" }}
                            >
                                <Input
                                    placeholder="Search by user name"
                                    allowClear
                                    size="small"
                                    defaultValue=""
                                />
                            </Form.Item>
                        )}
                    </Form>
                    {exportXLSButton()}
                </Row>
                <Table
                    key={dataSource.length}
                    className="report-table"
                    dataSource={dataSource}
                    columns={columns}
                    loading={isLoading}
                    scroll={{
                        x: columns.length * 130,
                    }}
                    onChange={(pagination) => {
                        setPage(pagination.current);
                    }}
                    size="small"
                    pagination={{
                        showSizeChanger: false,
                        showQuickJumper: false,
                    }}
                />
                <Row style={{ display: "flex", justifyContent: "end" }}>
                    {exportXLSButton()}
                </Row>
            </div>
            <style>
                {`
                .dropdown-wrapper label {
                    color: white !important;
                }
                .date-wrapper label {
                    color: white !important;
                }
                .site-page-header { 
                    padding-left: 0px;
                    padding-bottom: 15px;
                }
                .search-header { 
                    font: italic normal normal 16px/22px Open Sans;
                    color: white;
                    margin-bottom: 7px;
                }
                .add-new-user-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 127px !important;	
                }
				.add-new-user-button > span { 
					font-size: 16px;
				}
                .export-report { 
					background: #e7e7e7 !important;
                    padding-left: 30px;
                    padding-right: 30px;
                    box-shadow: none;
                }
                .date-wrapper { 
                    width: 20vw;
                    margin-right: 20px;
					margin-bottom: 0px;
                }
                .ant-select-selector { 
                    border: 1px solid #2B61C4 !important;
                    box-shadow: 0px 2px 4px #00000033 !important;
                    background: #FFFFFF 0% 0% no-repeat padding-box !important;
                    border-radius: 4px !important;
                    height: 48px !important;
                    display: flex;
                    align-items: center;
                }
                .ant-select-arrow {
                    color: #2B61C4;
                }
            `}
            </style>
        </>
    );
}
