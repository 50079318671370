import React from "react";
import {
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
  Tooltip,
  Button,
  Col,
  Divider,
  Modal,
  Checkbox,
  Card,
  Pagination,
} from "antd";
import { SEARCH_EMPTY_TOOLTIP } from "../../../../constants";
const { Search } = Input;

export const TermAndConditionFormItem = (TermAndConditionCheckbox) => {
  return (
    <Form.Item
      name="tnc"
      valuePropName="checked"
      rules={[
        {
          validator: (_, value) =>
            value ? Promise.resolve() : Promise.reject(),
          message: (
            <span>
              To proceed, you need to agree with the terms & conditions
            </span>
          ),
        },
      ]}
    >
      {TermAndConditionCheckbox()}
    </Form.Item>
  );
};

export const VehicleSearchForm = (
  searchForm,
  searchTerm,
  setSearchTerm,
  handleSearch,
  setIsEmptySearch,
  isEmptySearch
) => {
  return (
    <>
      <Form
        layout="inline"
        className="select-search-wrapper"
        style={{ borderRadius: 4 }}
        form={searchForm}
      >
        <Form.Item name={"searchTerm"}>
          <Tooltip
            overlayInnerStyle={{ color: "black" }}
            color="white"
            placement="bottom"
            open={isEmptySearch}
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: SEARCH_EMPTY_TOOLTIP(searchTerm),
                }}
              />
            }
          >
            <Search
              style={{ maxWidth: "100%" }}
              size="large"
              placeholder={"Search anything"}
              enterButton
              value={searchTerm}
              onSearch={() => handleSearch(false)}
              onChange={(e) => {
                setIsEmptySearch(false);
                setSearchTerm(e.target.value);
                searchForm.setFieldsValue({
                  // searchTerm: e.target.value.toUpperCase(),
                  searchTerm: e.target.value,
                });
                if (e.target.value === "") {
                  handleSearch(true);
                }
              }}
            />
          </Tooltip>
        </Form.Item>
      </Form>
      <style>
        {`
          .ant-input-group-addon {
            padding-bottom: 0px !important; 
          }
        `}
      </style>
    </>
  );
};
