// @ts-nocheck
import { LoadingOutlined } from '@ant-design/icons'
import { Card, message, Button, Image } from 'antd'
import propTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { SessionContext } from '../../App'
import green_thumbprint from '../../assets/illus-thumb-success.svg'
import red_thumbprint from '../../assets/illus-thumb-fail.svg'
import thumbprint from '../../assets/thumbprint.jpg'
import DeviceReader from '../../components/DeviceReader'
import { setAuthorizedNric } from '../../services/local'
import './index.less'
import {
    STEPS, HistoryState
} from './types'
import arrow_back from '../../assets/arrow_back.svg'
import {
    getCompanyId,
} from '../../services/local'
import { decryptUrlVerify } from '../../components/Utils'

function AuthorizeDealer(props) {
    const { user, } = useContext(SessionContext)

    const history = useHistory()
    const { state: locationState = {} } = useLocation<HistoryState>()

    const [step, setStep] = useState(STEPS.AUTHORIZE_DEALER.label)
    const [subStep, setSubStep] = useState(STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC)

    const COMPONENT_STEPS = {
        [STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Authorised Person Identity
                    </h1>
                </div>
            )
        },
        [STEPS.AUTHORIZE_DEALER.subSteps.INSERTING_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Authorised Person Identity
                    </h1>
                    <p>retrieving data...</p>
                    <LoadingOutlined />
                </div>
            )
        },
        [STEPS.AUTHORIZE_DEALER.subSteps.THUMBPRINT_BEFORE]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Authorised Person Identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='thumbprint' src={thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p>Place thumb on device</p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_DEALER.subSteps.THUBMPRINTING]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Authorised Person Identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <LoadingOutlined
                        style={{ marginBottom: 20 }}
                    />
                    <p>Place thumb on device</p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_DEALER.subSteps.THUMBPRINT_SUCCESS]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Authorised Person Identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='green thumbprint' src={green_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'green' }}>
                        Verified!
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_DEALER.subSteps.THUMBPRINT_FAILED]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Authorised Person Identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='red thumbprint' src={red_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'red' }}>
                        Failed. Try again?
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_DEALER.subSteps.AUTHORIZE_FAILED]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Authorised Person Identity
                    </h1>
                    <p style={{ fontStyle: 'italic' }}>Thumbprint Verification</p>
                    <img alt='red thumbprint' src={red_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'red', fontStyle: 'italic' }}>
                        Incorrect MyKad. Please use {user.name}'s MyKad.
                    </p>
                    <Button
                        className='rectangle-button to-the-right'
                        type="primary"
                        onClick={() => {
                            setSubStep(STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC)
                            history.push(history.location.pathname, {
                                ...locationState,
                                step: STEPS.AUTHORIZE_DEALER.label,
                                subStep: STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC
                            })
                        }}
                    >
                        Try again?
                    </Button>
                </div >
            )
        },
        [STEPS.AUTHORIZE_DEALER.subSteps.AUTHORIZE_TOKEN_FAILED]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Authorised Person Identity
                    </h1>
                    <p style={{ fontStyle: 'italic' }}>Thumbprint Verification</p>
                    <img alt='red thumbprint' src={red_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'red', fontStyle: 'italic' }}>
                    Failed to authorize session with token. Please try again.
                    </p>
                    <Button
                        className='rectangle-button to-the-right'
                        type="primary"
                        onClick={() => {
                            setSubStep(STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC)
                            history.push(history.location.pathname, {
                                ...locationState,
                                step: STEPS.AUTHORIZE_DEALER.label,
                                subStep: STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC
                            })
                        }}
                    >
                        Try again?
                    </Button>
                </div >
            )
        },
    }

    const currentStep = COMPONENT_STEPS[subStep]

    useEffect(() => {
        if (locationState?.subStep) {
            setSubStep(locationState?.subStep)
        }
    }, [locationState?.subStep])

    return (
        <>
            <Card
                style={{
                    height: '50%',
                    width: '100%',
                    maxWidth: 1200
                }}
            >
                {
                    currentStep && currentStep.component
                }
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >

                    {
                        (
                            subStep !== STEPS.AUTHORIZE_DEALER.subSteps.THUMBPRINT_SUCCESS
                            &&
                            subStep !== STEPS.AUTHORIZE_DEALER.subSteps.AUTHORIZE_FAILED
                        ) ? (

                            <DeviceReader
                                // Props for SAGEM
                                forKey='dealer'
                                nextStep={STEPS.VEHICLE_INFORMATION.label}
                                nextSubStep={STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO}

                                // Step & substep logic for DERMALOG
                                onIdentityCardSuccess={() => {
                                    setStep(STEPS.AUTHORIZE_DEALER.label)
                                    setSubStep(STEPS.AUTHORIZE_DEALER.subSteps.INSERTING_IC)
                                }}
                                onWaitingThumbprint={() => {
                                    setStep(STEPS.AUTHORIZE_DEALER.label)
                                    setSubStep(STEPS.AUTHORIZE_DEALER.subSteps.THUMBPRINT_BEFORE)
                                }}
                                onThumbprintDetected={() => {
                                    setStep(STEPS.AUTHORIZE_DEALER.label)
                                    setSubStep(STEPS.AUTHORIZE_DEALER.subSteps.THUBMPRINTING)
                                }}
                                onThumbprintFailed={() => {
                                    setStep(STEPS.AUTHORIZE_DEALER.label)
                                    setSubStep(STEPS.AUTHORIZE_DEALER.subSteps.THUMBPRINT_FAILED)
                                }}
                                onThumbprintSuccess={() => {
                                    setStep(STEPS.AUTHORIZE_DEALER.label)
                                    setSubStep(STEPS.AUTHORIZE_DEALER.subSteps.THUMBPRINT_SUCCESS)
                                }}
                                onTryAgain={() => {
                                    setStep(STEPS.AUTHORIZE_DEALER.label)
                                    setSubStep(STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC)
                                }}
                                onFinish={async data => {

                                    let errorMessages = [];

                                    if (!data.name) {
                                        errorMessages.push("Failed to read the Name from MyKad. The Name read as '" + data.name + "'. Please contact the eTukar Admin for more information.");
                                    }

                                    if (!data.district) {
                                        errorMessages.push("Failed to read the District from MyKad. The District read as '" + data.district + "'. Please contact the eTukar Admin for more information.");
                                    }

                                    if (!data.identificationNo || !/^[+]?\d+([.]\d+)?[*]?$/.test(data.identificationNo)) {
                                        errorMessages.push("Failed to read the IC No from MyKad. The IC No. read as '" + data.identificationNo + "'. Please contact the eTukar Admin for more information.");
                                    }

                                    if (errorMessages.length > 0) {
                                        for (let errorMessage of errorMessages) {
                                            message.error(errorMessage);
                                        }
                                        setSubStep(STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC)
                                        history.push(history.location.pathname, {
                                            ...locationState,
                                            step: STEPS.AUTHORIZE_DEALER.label,
                                            subStep: STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC
                                        })
                                        return;
                                    }

                                    const nric = data.identificationNo.replace(/\D/g, '')
                                    const isAuthorized = nric === user.nric.replace(/\D/g, '')

                                    const decryptedResponse = await decryptUrlVerify(data,
                                        data.encryptString,
                                        data.forkey,
                                        getCompanyId(),
                                        "DERMALOG",
                                        user.nric.replace(/[\s*]/g, ""), null, false)
                                    if(decryptedResponse === false) {
                                        if (!isAuthorized) {
                                            setStep(STEPS.AUTHORIZE_DEALER.label)
                                            setSubStep(STEPS.AUTHORIZE_DEALER.subSteps.AUTHORIZE_FAILED)
                                        }
                                        else {
                                            setStep(STEPS.AUTHORIZE_DEALER.label)
                                            setSubStep(STEPS.AUTHORIZE_DEALER.subSteps.AUTHORIZE_TOKEN_FAILED)
                                        }
                                        return;
                                    }
                                    
                                    if (isAuthorized) {
                                        setAuthorizedNric(user.nric)
                                        message.success('Authorized! Redirecting in a couple seconds...')
                                        setTimeout(() => {
                                            props.onAuthorizedSuccess()
                                        }, 2 * 1000)
                                    } else {
                                        setStep(STEPS.AUTHORIZE_DEALER.label)
                                        setSubStep(STEPS.AUTHORIZE_DEALER.subSteps.AUTHORIZE_FAILED)
                                    }
                                }}
                                onDataChanged={data => { }}
                                onError={data => {
                                    setStep(STEPS.AUTHORIZE_DEALER.label)
                                    setSubStep(STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC)
                                }}
                            />
                        ) : null
                    }
                </div>
            </Card>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 40,
                }}
            >
                <Button
                    style={{
                        border: "1px solid #2B61C4",
                        color: "#2B61C4",
                        padding: "15px",
                        height: "fit-content",
                        minWidth: "160px",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        fontSize: "16px",
                        fontWeight: "bold",
                    }}
                    shape="round"
                    onClick={() => {
                        window.location.pathname = '/'
                    }}
                >
                    <Image src={arrow_back} preview={false} />
                    BACK
                </Button>
            </div>
        </>
    )
}

AuthorizeDealer.propTypes = {
    onAuthorizedSuccess: propTypes.func,
}

export default AuthorizeDealer
