import { Layout, Col, Row, Space, Tooltip } from "antd";
import React, { useState, useEffect, useMemo, useContext } from "react";
import background from "../../assets/bg-texture.png";
// import logo from '../../assets/logo-myeg.svg';
import logo from "../../assets/logo/TWOCAR-Logo-White.png";
import { getIsMobileLayout } from "../../util";
import UnauthenticatedRoutes from "../UnauthenticatedRoutes";
import PrivacyPolicy from "../../assets/files/PrivacyPolicy.pdf";
import { links } from "../../config";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";

import { ReactComponent as LanguageIcon } from "../../assets/icon/icon-language.svg";
// import { ReactComponent as LoginIcon } from "../../assets/icon/icon-login.svg";

import { SessionContext } from "../../App";

const { Header, Content, Footer } = Layout;

export default function UnauthenticatedFrame() {
    const { language, setLanguage } = useContext(SessionContext);

    const isMobileLayout = getIsMobileLayout();
    const [currentTime, setCurrentTime] = useState(
        moment().format("DD MMM YYYY hh:mm A")
    );

    const history = useHistory();
    const location = useLocation();

    const changeLanguage = () => {
        language == "en" ? setLanguage("bm") : setLanguage("en");
    };

    const redirectToLogin = () => {
        history.push("/login");
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const isMobile = useMemo(() => {
        return windowWidth <= 768;
    }, [windowWidth]);

    return (
        <Layout>
            <div
                style={{
                    background: `linear-gradient(to right, #4A9D9B, #442372),url(${background})`,
                    backgroundBlendMode: "overlay",
                    backgroundSize: isMobileLayout ? "" : `cover`,
                    WebkitClipPath:
                        "polygon(0px 0px, 100% 0px, 100% 44%, 0px 70%)",
                    clipPath: "polygon(0px 0px, 100% 0px, 100% 44%, 0px 70%)",
                    minHeight: "89vh",
                }}
            ></div>
            <div
                style={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 32,
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "98vh",
                }}
            >
                <Header
                    style={{
                        backgroundColor: "transparent",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        overflow: "hidden",
                    }}
                >
                    <img src={logo} height={50} />
                    {/* <h1 style={{ font: "normal normal 600 24px/29px Raleway", color: '#FFFFFF', marginLeft: '15px', marginBlock: 'auto' }}>Twocar</h1> */}

                    <div>
                        {location.pathname === "/reginterest" && (
                            <div
                                style={{
                                    display: "flex",
                                    gap: isMobile ? 12 : 24,
                                    fontWeight: 600,
                                    fontSize: "14.22px",
                                    color: "#FFFFFF",
                                    flexShrink: 1,
                                }}
                            >
                                <div
                                    onClick={() => changeLanguage()}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 4,
                                        cursor: "pointer",
                                        whiteSpace: "nowrap",
                                        cursor: "pointer",
                                    }}
                                >
                                    <LanguageIcon />
                                    {language == "en" ? "BAHASA" : "ENGLISH"}
                                </div>

                                {/* Remove Login as per Jason (12/11/2024) */}
                                {/* <div
                                    onClick={() => redirectToLogin()}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 4,
                                        whiteSpace: "nowrap",
                                        cursor: "pointer",
                                    }}
                                >
                                    <LoginIcon />
                                    {language == "bm" ? "LOG MASUK" : "LOGIN"}
                                </div> */}
                            </div>
                        )}
                    </div>
                </Header>

                <Content
                    style={{
                        margin: "24px 16px",
                        padding: 24,
                        minHeight: 280,
                        backgroundColor: "transparent",
                        flexGrow: 1,
                    }}
                >
                    <UnauthenticatedRoutes language={language} />
                </Content>

                <Footer
                    style={{
                        position: "relative",
                        // backgroundColor: 'transparent',
                        background: "rgb(241, 241, 241)",
                        marginTop: "auto",
                    }}
                >
                    {" "}
                    <div>
                        <Row
                            justify="space-between"
                            style={{ borderTop: "1px dashed #CCCCCC" }}
                        >
                            <Col style={{ paddingTop: "16px" }}>
                                <Space size="middle" align="center">
                                    <p
                                        style={{
                                            font: "normal normal 600 14px/18px Open Sans",
                                            color: "#333333",
                                        }}
                                    >
                                        CALL OUR HELPLINE
                                        <a
                                            style={{
                                                font: "normal normal 600 14px/19px Open Sans",
                                                color: "#2B61C4",
                                                paddingLeft: "12px",
                                            }}
                                            href="tel:0376648181"
                                        >
                                            03-7664 8181
                                        </a>{" "}
                                        or email us at{" "}
                                        <a>
                                            <u>twocar@myeg.com.my</u>
                                        </a>
                                    </p>

                                    <p
                                        style={{
                                            font: "normal normal normal 14px/24px Open Sans",
                                            color: "#333333",
                                        }}
                                    >
                                        Available from{" "}
                                        <span
                                            style={{
                                                font: "normal normal 600 14px/18px Open Sans",
                                                color: "#333333",
                                            }}
                                        >
                                            9 AM - 5 PM
                                        </span>{" "}
                                        Monday to Friday except national public
                                        holidays.
                                    </p>
                                </Space>
                                <div>
                                    <p
                                        style={{
                                            font: "normal normal normal 14px/24px Open Sans",
                                            color: "#999999",
                                        }}
                                    >
                                        © MY E.G. SERVICES BERHAD [200001003034
                                        (505639-K)].
                                        <a
                                            target="_blank"
                                            href={PrivacyPolicy}
                                            style={{
                                                marginLeft: 10,
                                                color: "#0645AD",
                                            }}
                                        >
                                            Privacy Policy
                                        </a>
                                        <a
                                            target="_blank"
                                            href={links.termsAndCondition}
                                            style={{
                                                marginLeft: 10,
                                                color: "#0645AD",
                                            }}
                                        >
                                            Terms & Conditions
                                        </a>
                                        <Tooltip
                                            title="Please login or continue to support"
                                            color={"geekblue"}
                                            placement="right"
                                        >
                                            <span
                                                style={{
                                                    marginLeft: 10,
                                                    color: "#0645AD",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    history.push("/contact-us")
                                                }
                                            >
                                                Report An Issue
                                            </span>
                                        </Tooltip>
                                    </p>
                                </div>
                            </Col>
                            <Col style={{ paddingTop: "16px" }}>
                                {/* <p style={{
                                        font: 'normal normal normal 14px/24px Open Sans', color: '#CCCCCC', opacity: '1'
                                    }}>{currentTime}</p> */}
                            </Col>
                        </Row>
                    </div>
                </Footer>
            </div>
        </Layout>
    );
}
